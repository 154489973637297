import React from 'react';
import AsyncImage from '../async-image/async-image';
import './radio-button-content.scss';

export type ContentType = 'text' | 'imageLeft' | 'imageRight' | 'abbreviation';
export type FontSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

/* eslint-disable-next-line */
export interface RadioButtonContentProps {
  label?: string;
  type: ContentType;
  bold?: boolean;
  imageUrl?: string;
  abbreviation?: string;
  color?: string;
  fontSize: FontSize;
}

export const RadioButtonContent = ({
  label,
  type,
  bold,
  imageUrl,
  abbreviation,
  color,
  fontSize,
  children,
}: React.PropsWithChildren<RadioButtonContentProps>) => {
  return (
    <div
      className={`way-radio-button-content way-radio-button-content--${fontSize}`}
    >
      {type === 'imageLeft' && imageUrl ? (
        <AsyncImage src={imageUrl} alt={label} />
      ) : null}
      {type === 'imageLeft' && color ? (
        <span style={{ backgroundColor: color }}></span>
      ) : null}
      <label style={bold ? { fontWeight: 700 } : null}>
        {type === 'abbreviation' && abbreviation ? (
          <span>{abbreviation} = </span>
        ) : null}
        {label || children}
      </label>
      {type === 'imageRight' && imageUrl ? (
        <AsyncImage src={imageUrl} alt={label} isTooltipDisabled />
      ) : null}
    </div>
  );
};

export default RadioButtonContent;
