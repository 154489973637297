import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { InstructionItem } from '../backoffice/get';

export const INSTRUCTION_OPTIONS_QUERY = gql`
  query($propertyToUnique: String!) {
    result: getInstructionsSelectOptions(
      propertyToUnique: $propertyToUnique
      offset: 400
    ) {
      options: data {
        label
        value
      }
    }
  }
`;

export type PropertyToUniqueTypeInstruction = keyof Omit<
  InstructionItem,
  '__typename' | 'deleted_at' | 'updated_at'
>;

export interface GenericInstructionOptionsResult {
  result: {
    options: Array<{
      label: string;
      value: string;
    }>;
  };
}

export const useGenericInstructionOptions = (
  propertyToUnique: PropertyToUniqueTypeInstruction
) => {
  return useQuery<GenericInstructionOptionsResult>(INSTRUCTION_OPTIONS_QUERY, {
    variables: { propertyToUnique },
  });
};
