import { gql, useMutation } from '@apollo/client';
import {
  GetConfigurationInterface,
  GetMotorConfigurationReferenceInterface,
  GetAdapterConfigurationReferenceInterface,
  GetOtherAccessoryConfigurationReferenceInterface,
  GetControlConfigurationReferenceInterface,
  GetBracketConfigurationReferenceInterface,
} from './get-single-configuration.hook';

export const CREATE_CONFIGURATION_MUTATION = gql`
  mutation(
    $configuration: ConfigurationInput!
    $motorReferencesList: [MotorsReferenceInput!]
    $adapterReferencesList: [AdapterReferenceInput!]
    $otherAccessoryReferencesList: [OtherAccessoryReferenceInput!]
    $controlReferencesList: [ControlReferenceInput!]
    $bracketReferencesList: [BracketReferenceInput!]
  ) {
    createNewConfiguration(
      motorReferencesList: $motorReferencesList
      configurationData: $configuration
      adapterReferencesList: $adapterReferencesList
      otherAccessoryReferencesList: $otherAccessoryReferencesList
      controlReferencesList: $controlReferencesList
      bracketReferencesList: $bracketReferencesList
    ) {
      configuration_id
    }
  }
`;

export interface SetConfigurationInterface
  extends Omit<
    GetConfigurationInterface,
    | 'configuration_id'
    | 'report_url_file'
    | 'motorReferencesList'
    | 'adapterReferencesList'
    | 'otherAccessoryReferencesList'
    | 'controlReferencesList'
    | 'bracketReferencesList'
  > {
  configuration_id?: string;
  motorReferencesList: Array<SetMotorConfigurationReferenceInterface>;
  adapterReferencesList: Array<SetAdapterConfigurationReferenceInterface>;
  otherAccessoryReferencesList: Array<SetOtherAccessoryConfigurationReferenceInterface>;
  controlReferencesList: Array<SetControlConfigurationReferenceInterface>;
  bracketReferencesList: Array<SetBracketConfigurationReferenceInterface>;
}

export type SetBracketConfigurationReferenceInterface = Omit<
  GetBracketConfigurationReferenceInterface,
  | 'bracket_reference_id'
  | 'is_draft'
  | 'created_at'
  | 'updated_at'
  | 'range_for_configuration'
>;

export type SetControlConfigurationReferenceInterface = Omit<
  GetControlConfigurationReferenceInterface,
  | 'control_reference_id'
  | 'is_draft'
  | 'created_at'
  | 'updated_at'
  | 'range_for_configuration'
>;

export type SetOtherAccessoryConfigurationReferenceInterface = Omit<
  GetOtherAccessoryConfigurationReferenceInterface,
  | 'other_accessory_reference_id'
  | 'is_draft'
  | 'created_at'
  | 'updated_at'
  | 'range_for_configuration'
>;

export type SetAdapterConfigurationReferenceInterface = Omit<
  GetAdapterConfigurationReferenceInterface,
  | 'adapter_reference_id'
  | 'is_draft'
  | 'created_at'
  | 'updated_at'
  | 'range_for_configuration'
>;

export interface ObjectTypeRefToMotorInput {
  object_id: string;
  package_position?: string;
  package_item_quantity?: string;
}

export interface ControlRefToMotorInput
  extends SetControlConfigurationReferenceInterface {
  object_id: string;
  package_position?: string;
  package_item_quantity?: string;
}

export interface SetMotorConfigurationReferenceInterface
  extends Omit<
    GetMotorConfigurationReferenceInterface,
    | 'adapters'
    | 'other_accessories'
    | 'brackets'
    | 'controls'
    | 'motor_reference_id'
    | 'range_for_configuration'
    | 'is_draft'
    | 'created_at'
    | 'updated_at'
  > {
  motor_reference_id?: string;
  adapters: Array<ObjectTypeRefToMotorInput>;
  other_accessories: Array<ObjectTypeRefToMotorInput>;
  brackets: Array<ObjectTypeRefToMotorInput>;
  controls: Array<ObjectTypeRefToMotorInput>;
}

export interface CreateConfigurationReturnInterface {
  createNewConfiguration: {
    configuration_id: string;
  };
}

export const useCreateConfiguration = () => {
  const [
    createConfiguration,
    result,
  ] = useMutation<CreateConfigurationReturnInterface>(
    CREATE_CONFIGURATION_MUTATION
  );

  return {
    ...result,
    createNewConfiguration: ({
      motorReferencesList,
      adapterReferencesList,
      otherAccessoryReferencesList,
      controlReferencesList,
      bracketReferencesList,
      ...configuration
    }: SetConfigurationInterface) => {
      return createConfiguration({
        variables: {
          configuration,
          motorReferencesList,
          adapterReferencesList,
          otherAccessoryReferencesList,
          controlReferencesList,
          bracketReferencesList,
        },
      });
    },
  };
};
