import { useQuery, gql } from '@apollo/client';

export const COUNTRIES = gql`
  query {
    countries {
      name
      country_id
      code
    }
  }
`;
export interface CountriesOptions {
  countries: Array<{
    name: string;
    country_id: string;
    code: string;
  }>;
}

export const useCountries = () => {
  const status = useQuery<CountriesOptions>(COUNTRIES);

  return status;
};
