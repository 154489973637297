import React from 'react';

export interface IconCopyProps extends React.SVGProps<SVGSVGElement> {
  green?: boolean;
  size?: 'big' | 'small' | 'medium';
}

function calcolateStyles(size: IconCopyProps['size']) {
  if (size === 'small') {
    return {
      height: 16,
      width: 16,
    };
  }
  if (size === 'medium') {
    return {
      height: 24,
      width: 24,
    };
  }
  if (size === 'big') {
    return {
      height: 27,
      width: 27,
    };
  }
}

export const IconCopy = ({ green, size, ...props }: IconCopyProps) => {
  const style = calcolateStyles(size);
  return (
    <svg
      {...props}
      style={style}
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.796875V29.8291H30V0.796875H0ZM1.53832 2.32475H28.4614V20.3389L23.6178 15.5282C23.3254 15.2407 22.7915 15.2522 22.5119 15.5519L17.6683 20.6968L10.1559 13.2355C9.99359 13.0767 9.7575 12.9954 9.5308 
        13.0207C9.37117 13.0375 9.21744 13.1055 9.09805 13.2118L1.5383 20.064L1.53832 2.32475ZM17.3076 5.38085C15.1924 5.38085 13.4614 7.10007 13.4614 9.20088C13.4614 11.3017 15.1924 13.0209 17.3076 13.0209C19.4227 
        13.0209 21.1537 11.3017 21.1537 9.20088C21.1537 7.10007 19.4227 5.38085 17.3076 5.38085ZM17.3076 6.90872C18.5911 6.90872 19.6154 7.92581 19.6154 9.20088C19.6154 10.4757 18.5913 11.493 17.3076 11.493C16.024 
        11.493 14.9997 10.476 14.9997 9.20088C14.9997 7.92608 16.0238 6.90872 17.3076 6.90872ZM9.59121 14.8353L17.151 22.3437C17.4434 22.6312 17.9773 22.6198 18.2569 22.32L23.1005 17.1748L28.4611 22.4991V28.3008H1.53805V22.1409L9.59121 14.8353Z"
        fill={green ? '#72C355' : '#FFFFFF'}
      />
    </svg>
  );
};
