import { useQuery, gql } from '@apollo/client';
import { SelectType } from '@eypconfig/shared/types';

export const MOTOR_BRAND_CLASSIFICATION_QUERY = gql`
  query($technology: String, $size_motor: String) {
    getMotorsSelectOptions(
      propertyToUnique: "brand_classification"
      technology: $technology
      size_motor: $size_motor
    ) {
      data {
        label
        value
      }
    }
  }
`;

export interface MotorsBrandClassificationOptions {
  getMotorsSelectOptions: {
    data: Array<SelectType>;
  };
}

export const useBrandClassificationMotor = (
  technology?: string,
  size_motor?: string
) => {
  const status = useQuery<MotorsBrandClassificationOptions>(
    MOTOR_BRAND_CLASSIFICATION_QUERY,
    {
      variables: {
        technology: technology,
        size_motor: size_motor,
      },
    }
  );

  return status;
};
