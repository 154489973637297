import { gql, useMutation } from '@apollo/client';
import { ControlReferenceDto } from '@eypconfig/shared/import-validations';

export const CREATE_CONTROL_REFERENCE_MUTATION = gql`
  mutation ($controlsReference: CreateControlsReferenceSingleInput!) {
    createControlReference(controlsReference: $controlsReference) {
      reference_id: control_reference_id
    }
  }
`;

export function useCreateControlReference() {
  const [createControlReference, result] = useMutation(
    CREATE_CONTROL_REFERENCE_MUTATION
  );
  return {
    createControlReference: (controlsReference: ControlReferenceDto) =>
      createControlReference({ variables: { controlsReference } }),
    ...result,
  };
}
