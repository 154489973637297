import React, { useEffect, useRef, useState } from 'react';
import { motion, Variants } from 'framer-motion';

import PlaceholderImage from './placeholder.png';
import './async-image.scss';
import PopupContainer from '../popup-container/popup-container';

/* eslint-disable-next-line */
export interface AsyncImageProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  isTooltipDisabled?: boolean;
}

const variants: Variants = {
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
  },
};

const width = 300;
const height = 300;

// if lens element already exists, remove it removes lens
export function removeLens() {
  const lens = document.querySelector<HTMLElement>('.img-zoom-lens');
  if (lens) {
    lens.parentElement.removeChild(lens);
    lens.setAttribute('class', 'img-zoom-lens');
  }
}

export function imageZoom(props: AsyncImageProps, img: HTMLImageElement) {
  // calculate zoomed image position
  const position = img.getBoundingClientRect();

  let xResult = position?.right + 40;
  const yResult = position?.top - 70;

  if (xResult < 0) {
    xResult = 10;
  }

  // zoomed image
  const result = document.getElementById('zoomedImg');

  // if lens element already exists takes that, otherwide creates a new one
  let lens = document.querySelector<HTMLElement>('.img-zoom-lens');
  if (!lens) {
    lens = document.createElement('div');
    lens.setAttribute('class', 'img-zoom-lens');
  }

  // zoomed image styles
  result.style.height = height + 'px';
  result.style.width = width + 'px';
  result.style.left = xResult + 'px';
  result.style.top = yResult + 'px';

  // save image width and height in constants
  const imgWidth = img.width;
  const imgHeight = img.height;

  // add lens before image
  img.parentElement.insertBefore(lens, img);
  const cx = width / lens.offsetWidth;
  const cy = height / lens.offsetHeight;

  // add bakground image and size
  result.style.backgroundImage = "url('" + props.src + "')";
  result.style.backgroundSize = imgWidth * cx + 'px ' + imgHeight * cy + 'px';

  img.addEventListener('mousemove', moveLens);

  function moveLens(e) {
    e.preventDefault();
    const pos = getCursorPos(e);

    let x = pos.x - lens.offsetWidth / 2;
    let y = pos.y - lens.offsetHeight / 2;

    if (x > imgWidth - lens.offsetWidth / 2) {
      x = imgWidth - lens.offsetWidth / 2;
    }

    if (x < 0) {
      x = 0;
    }

    if (y > imgHeight - lens.offsetHeight / 2) {
      y = imgHeight - lens.offsetHeight / 2;
    }

    if (y < 0) {
      y = 0;
    }

    lens.style.left = x + 'px';
    lens.style.top = y + 'px';

    result.style.backgroundPosition = '-' + x * cx + 'px -' + y * cy + 'px';
  }

  // get cursor position
  function getCursorPos(e) {
    e = e || window.event;
    const a = img.getBoundingClientRect();
    let x = e.pageX - a.left;
    let y = e.pageY - a.top;
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x: x, y: y };
  }

  return true;
}

export function AsyncImage(props: AsyncImageProps) {
  const [visible, setVisible] = useState(false);
  const image = useRef<HTMLImageElement>();

  const handleVisible = (e) => {
    if (!props.isTooltipDisabled && props.src) {
      setVisible((prop) => !prop);
    }
  };

  useEffect(() => {
    if (visible && image.current) {
      imageZoom(props, image.current);
    }
    if (!visible) {
      removeLens();
    }
  }, [props, visible]);

  return (
    <div>
      {!props.isTooltipDisabled && props.src ? (
        <>
          <PopupContainer
            popContainerId="async-image-popup"
            openControllerComponent={(openContProps) => (
              <motion.img
                className={props.className}
                style={{
                  objectFit: 'contain',
                  cursor: 'pointer',
                  ...props.style,
                }}
                height={props.height}
                width={props.width}
                alt={props.alt}
                src={props.src || PlaceholderImage}
                variants={variants}
                initial="hide"
                animate="show"
                exit="hide"
                {...openContProps}
              />
            )}
          >
            {({ close }) => {
              return (
                <div className="img-zoom-container">
                  <motion.img
                    className={'way-editable-image-input__medium_image'}
                    style={{ objectFit: 'contain', ...props.style }}
                    alt={props.alt}
                    src={props.src}
                    ref={image}
                    onMouseEnter={handleVisible}
                    onMouseLeave={handleVisible}
                  />
                  {visible && props.src && (
                    <div id="zoomedImg" className="img-zoom-result"></div>
                  )}
                </div>
              );
            }}
          </PopupContainer>
          <div id="async-image-popup" />
        </>
      ) : (
        <motion.img
          className={props.className}
          style={{ objectFit: 'contain', ...props.style }}
          height={props.height}
          width={props.width}
          alt={props.alt}
          src={props.src || PlaceholderImage}
          variants={variants}
          initial="hide"
          animate="show"
          exit="hide"
        />
      )}
    </div>
  );
}

export default AsyncImage;
