import { gql, useMutation } from '@apollo/client';

export const DELETE_LABEL_SINGLE_QUERY = gql`
  mutation($label_id: String!) {
    deleteLabel(label_id: $label_id) {
      label_id
    }
  }
`;

export const useDeleteLabel = (objectTypeId: string) => {
  const [doDelete, result] = useMutation(DELETE_LABEL_SINGLE_QUERY, {
    variables: {
      label_id: objectTypeId,
    },
  });

  return {
    doDelete: () => doDelete().then((result) => !result.errors),
    loading: result.loading,
    error: result.error?.message,
  };
};
