import React from 'react';

export interface IconPeopleProps extends React.SVGProps<SVGSVGElement> {
  green?: boolean;
}

export const IconPeople = ({ green, ...props }: IconPeopleProps) => {
  return (
    <svg
      width="30"
      {...props}
      viewBox="0 0 71 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 29.4479C0 23.7188 3.73071 18.6771 9.21018 16.8438C6.99507 15.0104 5.71264 12.2604 5.71264 9.51042C5.71264 4.23958 10.0263 0 15.2726 0C20.6355 0 24.9491 4.23958 24.9491 9.51042C24.9491 12.2604 23.6667 15.0104 21.4516 16.8438C23.4335 17.4167 25.1823 18.5625 26.6979 20.0521C28.33 16.7292 31.711 14.5521 35.4417 14.5521C39.289 14.5521 42.6699 16.7292 44.3021 20.0521C45.7011 18.5625 47.5665 17.4167 49.5484 16.8438C49.3153 16.6146 49.0821 16.3854 48.8489 16.1562C47.1002 14.4375 46.0509 12.0312 46.0509 9.51042C46.0509 4.23958 50.3645 0 55.6108 0C60.9737 0 65.2874 4.23958 65.2874 9.51042C65.2874 12.375 64.0049 15.0104 61.7898 16.8438C67.1527 18.6771 71 23.7188 71 29.4479H67.9688C67.9688 23.7188 63.1888 19.0208 57.3596 19.0208H53.8621C50.3645 19.0208 47.1002 20.7396 45.1182 23.6042V23.9479C45.1182 26.9271 43.8358 29.4479 41.6207 31.2812C46.9836 33.1146 50.8309 38.2708 50.8309 44H47.7997C47.7997 38.1562 43.0197 33.4583 37.1905 33.4583H33.6929C27.8637 33.4583 23.0837 38.1562 23.0837 44H20.1691C20.1691 38.2708 23.8998 33.1146 29.3793 31.2812C27.1642 29.5625 25.8818 26.8125 25.8818 23.9479V23.6042C23.8998 20.7396 20.6355 19.0208 17.0213 19.0208H13.6404C7.69458 19.0208 2.91461 23.7188 2.91461 29.4479H0ZM35.4417 17.4167C31.8276 17.4167 28.7964 20.3958 28.7964 23.9479C28.7964 27.6146 31.8276 30.5938 35.4417 30.5938C39.1724 30.5938 42.2036 27.6146 42.2036 23.9479C42.2036 20.3958 39.1724 17.4167 35.4417 17.4167ZM55.6108 2.86458C51.9967 2.86458 48.9655 5.84375 48.9655 9.51042C48.9655 11.2292 49.665 12.9479 50.9475 14.0938C52.1133 15.3542 53.7455 16.0417 55.6108 16.0417C59.3415 16.0417 62.3727 13.0625 62.3727 9.51042C62.3727 5.84375 59.3415 2.86458 55.6108 2.86458ZM15.2726 2.86458C11.6585 2.86458 8.62726 5.84375 8.62726 9.51042C8.62726 13.0625 11.6585 16.0417 15.2726 16.0417C19.0033 16.0417 22.0345 13.0625 22.0345 9.51042C22.0345 5.84375 19.0033 2.86458 15.2726 2.86458Z"
        fill={green ? '#72C355' : '#FFFFFF'}
      />
    </svg>
  );
};
