import { gql, useLazyQuery } from '@apollo/client';

export const PLASTIC_CAP_COLOR_SINGLE_QUERY = gql`
  query($plastic_cap_id: String!) {
    result: getPlasticCapsColor(plastic_cap_id: $plastic_cap_id) {
      objectTypeId: plastic_cap_id
      plastic_cap_id
      range_for_configuration
      motor_size
      brand_classification
      type_code
      motor_head
      cap_color_code
      cap_color_lable
      cap_no_logo_image
      cap_logo_image
      created_at
      updated_at
    }
  }
`;

export interface PlasticCapColorItem {
  objectTypeId: string;
  plastic_cap_id: string;
  range_for_configuration: boolean;
  motor_size: Array<string>;
  brand_classification: Array<string>;
  type_code: Array<string>;
  motor_head: Array<string>;
  cap_color_code?: string;
  cap_color_lable?: string;
  cap_no_logo_image?: string;
  cap_logo_image?: string;
  __typename: string;
  created_at: Date;
  updated_at: Date;
}

export interface GetPlasticCapColorResult {
  result: PlasticCapColorItem;
}

export const useGetPlasticCapColor = (plastic_cap_id: string) => {
  const res = useLazyQuery<GetPlasticCapColorResult>(
    PLASTIC_CAP_COLOR_SINGLE_QUERY,
    {
      variables: { plastic_cap_id },
    }
  );
  return res;
};
