import { useIsLoggedIn } from '@f-technology-srl/react-authorization';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import loadable from '@loadable/component';
import { UserRoles } from '@eypconfig/client/data-access';
import { AnimatePresence } from 'framer-motion';
import { useIsUserRole } from '@eypconfig/client/user-roles-utils';
import { PasswordlessLoginPage } from '../pages/login/passwordless-login.page';
import { FullScreenLoading } from '@eypconfig/client/core-ui';

const LoginPageAsync = loadable(() => import('../pages/login/login.page'), {
  fallback: <FullScreenLoading />,
});

const LoginPageFtech = loadable(
  () => import('../pages/login/login-ftech.page'),
  {
    fallback: <FullScreenLoading />,
  }
);

const HomePageAsync = loadable(
  () => import('../pages/homepage/homepage.page'),
  {
    fallback: <FullScreenLoading />,
  }
);

const CatalogAsync = loadable(() => import('../pages/catalog/catalog.page'), {
  fallback: <FullScreenLoading />,
});

const ConfiguratorAsync = loadable(
  () => import('../pages/configurator/configurator.page'),
  {
    fallback: <FullScreenLoading />,
  }
);

const ConfigurationSigleAsync = loadable(
  () => import('../pages/configuration/configuration.page'),
  {
    fallback: <FullScreenLoading />,
  }
);

const UserManagementListAsync = loadable(
  () => import('../pages/user-management-list/user-management-list.page'),
  {
    fallback: <FullScreenLoading />,
  }
);

const UserManagementEditOrNewAsync = loadable(
  () => import('../pages/new-edit-user/new-edit-user.page'),
  {
    fallback: <FullScreenLoading />,
  }
);

const ResetPasswordPageAsync = loadable(
  () => import('../pages/reset-password/reset-password.page'),
  {
    fallback: <FullScreenLoading />,
  }
);

const CopyImageAsync = loadable(
  () => import('../pages/copy-image-link/copy-image-link.page'),
  {
    fallback: <FullScreenLoading />,
  }
);

const ImportExportCsvAsync = loadable(
  () => import('../pages/import-export-csv/import-export-csv.page'),
  {
    fallback: <FullScreenLoading />,
  }
);

const ExportCsvAsync = loadable(
  () => import('../pages/export-csv/export-csv.page'),
  {
    fallback: <FullScreenLoading />,
  }
);

const ImportReferencesAsync = loadable(
  () => import('../pages/import-references/import-references.page'),
  {
    fallback: <FullScreenLoading />,
  }
);

const UpdateExistingReferenceAsync = loadable(
  () =>
    import('../pages/update-existing-reference/update-existing-refence.page'),
  {
    fallback: <FullScreenLoading />,
  }
);

const BackofficeRoutesAsync = loadable(() => import('./backoffice'), {
  fallback: <FullScreenLoading />,
});

export function AdminRoutesOutlet() {
  const { isRole, loading } = useIsUserRole(UserRoles.admin);

  if (!isRole && !loading) {
    return (
      <Redirect
        to={{
          pathname: '/home',
        }}
      />
    );
  }

  return (
    <>
      <Route
        path="/admin/user-management"
        render={() => <UserManagementListAsync />}
      />
      <Route
        path="/admin/user"
        render={() => <UserManagementEditOrNewAsync />}
      />
      <Route
        path="/admin/data-management"
        render={() => <BackofficeRoutesAsync />}
      />
      <Route path="/admin/copy-image-link" render={() => <CopyImageAsync />} />
      <Route
        path="/admin/import-export-csv"
        render={() => <ImportExportCsvAsync />}
      />
      <Route path="/admin/export-csv" render={() => <ExportCsvAsync />} />
      <Route path="/admin/import" render={() => <ImportReferencesAsync />} />
      <Route
        path="/admin/update-existing-reference"
        render={() => <UpdateExistingReferenceAsync />}
      />
    </>
  );
}

export function RouterOutlet() {
  const isAuthenticated = useIsLoggedIn();

  return (
    <AnimatePresence>
      <Router>
        <Switch>
          <Route
            path="/login"
            exact
            render={({ location }) =>
              isAuthenticated ? (
                <Redirect
                  to={{
                    pathname: '/home',
                    state: { from: location },
                  }}
                />
              ) : (
                <LoginPageAsync />
              )
            }
          />
          <Route
            path="/passwordless-login"
            exact
            render={() => <PasswordlessLoginPage />}
          />
          <Route
            path="/reset/:token_id"
            exact
            render={() => <ResetPasswordPageAsync />}
          />
          <Route
            path="/ftech-admin"
            render={({ location }) =>
              isAuthenticated ? (
                <Redirect
                  to={{
                    pathname: '/home',
                    state: { from: location },
                  }}
                />
              ) : (
                <LoginPageFtech />
              )
            }
            exact
          />
          <Route
            path="/home"
            render={() =>
              isAuthenticated ? <HomePageAsync /> : <LoginPageAsync />
            }
            exact
          />
          <Route
            path="/catalog"
            render={() =>
              isAuthenticated ? <CatalogAsync /> : <LoginPageAsync />
            }
          />
          <Route
            path="/configurator"
            render={() =>
              isAuthenticated ? <ConfiguratorAsync /> : <LoginPageAsync />
            }
          />
          <Route
            path="/configuration/:configuration_id"
            render={() =>
              isAuthenticated ? <ConfigurationSigleAsync /> : <LoginPageAsync />
            }
          />
          {isAuthenticated && (
            <Route path="/admin" render={() => <AdminRoutesOutlet />} />
          )}
          <Redirect to="/home" />
        </Switch>
      </Router>
    </AnimatePresence>
  );
}

export default RouterOutlet;
