import React from 'react';

export interface IconImportExportProps extends React.SVGProps<SVGSVGElement> {
  green?: boolean;
  size?: 'big' | 'small' | 'medium';
}

function calcolateStyles(size: IconImportExportProps['size']) {
  if (size === 'small') {
    return {
      height: 16,
      width: 16,
    };
  }
  if (size === 'medium') {
    return {
      height: 24,
      width: 24,
    };
  }
  if (size === 'big') {
    return {
      height: 27,
      width: 27,
    };
  }
}

export const IconImportExport = ({
  green,
  size,
  ...props
}: IconImportExportProps) => {
  const style = calcolateStyles(size);
  return (
    <svg
      {...props}
      style={style}
      width="30"
      height="33"
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#l78kthglja)">
        <path
          d="M25.24 18.825v-7.786l-8.406-8.58H4.3v16.366M4.299 27.976v3.16h20.94v-3.16"
          strokeWidth="2.194"
          strokeMiterlimit="10"
          stroke={green ? '#72C355' : '#FFFFFF'}
        />
        <path
          d="M16.834 2.458v8.406h8.406M1.936 18.825h25.667v9.15H1.936v-9.15z"
          strokeWidth="2.194"
          strokeMiterlimit="10"
          stroke={green ? '#72C355' : '#FFFFFF'}
        />
        <path
          d="M8.962 23.645a2.067 2.067 0 0 1 2.168-2.116 1.86 1.86 0 0 1 1.7.995l-.742.376a1.107 1.107 0 0 0-.958-.591 1.337 1.337 0 0 0 0 2.67 1.094 1.094 0 0 0 .958-.582l.743.361a1.876 1.876 0 0 1-1.701.998 2.067 2.067 0 0 1-2.168-2.11zM13.077 25.102l.473-.66c.338.352.806.551 1.294.551.476 0 .708-.22.708-.453 0-.72-2.343-.224-2.343-1.755 0-.677.574-1.239 1.543-1.239a2.254 2.254 0 0 1 1.586.574l-.494.628a1.753 1.753 0 0 0-1.175-.445c-.373 0-.574.163-.574.407 0 .648 2.335.215 2.335 1.72 0 .747-.53 1.306-1.63 1.306a2.31 2.31 0 0 1-1.723-.634zM18.154 25.676l-1.575-4.07h.986l1.13 3.13 1.122-3.13h.99l-1.57 4.07h-1.083z"
          fill={green ? '#72C355' : '#FFFFFF'}
        />
      </g>
      <defs>
        <clipPath id="l78kthglja">
          <path
            transform="translate(0 .797)"
            d="M0 0h29.538v32H0z"
            fill={green ? '#72C355' : '#FFFFFF'}
          />
        </clipPath>
      </defs>
    </svg>
  );
};
