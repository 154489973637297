import { gql, useLazyQuery } from '@apollo/client';

export const INSTRUCTION_SINGLE_QUERY = gql`
  query($instruction_id: String!) {
    result: getInstruction(instruction_id: $instruction_id) {
      instruction_id
      objectTypeId: instruction_id
      brand_classification
      brand
      product_classification
      technology
      motor_size
      type_code
      instruction_image
      default_languages
      created_at
      updated_at
    }
  }
`;

export interface InstructionItem {
  instruction_id: string;
  objectTypeId: string;
  brand_classification: string;
  brand: string;
  product_classification: string;
  technology: string;
  motor_size: Array<string>;
  type_code: Array<string>;
  instruction_image?: string;
  default_languages: Array<string>;
  updated_at: Date;
  created_at: Date;
  __typename: string;
}

export interface GetInstructionResult {
  result: InstructionItem;
}

export const useGetInstruction = (instruction_id: string) => {
  const res = useLazyQuery<GetInstructionResult>(INSTRUCTION_SINGLE_QUERY, {
    variables: { instruction_id },
  });
  return res;
};
