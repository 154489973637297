import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import './radio-button.scss';

const circleVariants = {
  hidden: {
    backgroundColor: '#fff',
    transition: {
      duration: 0.2,
      delay: 0.2,
    },
    transitionEnd: {
      borderColor: '#727272',
    },
  },
  visible: {
    backgroundColor: '#72c456',
    borderColor: '#72c456',
    transition: {
      duration: 0.2,
    },
  },
};

const svgVariants = {
  hidden: {
    pathLength: 0,
  },
  visible: {
    pathLength: 1,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
};

export const whiteVRadio = (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="11"
    fill="none"
    viewBox="0 0 18 14"
    variants={svgVariants}
    initial="hidden"
    animate="visible"
    exit="hidden"
  >
    <motion.path
      stroke="#fff"
      d="M7.702 12.803l9.157-10.824c.205-.243.184-.614-.047-.83-.231-.216-.585-.194-.79.048L7.217 11.603 1.91 7.142c-.242-.203-.594-.162-.787.092-.193.253-.154.624.087.827l5.723 4.81c.103.087.227.129.35.129.155 0 .308-.067.419-.197"
      variants={svgVariants}
    />
  </motion.svg>
);

export type RadioButtonSize = 'small' | 'big';

/* eslint-disable-next-line */
export interface RadioButtonProps<T = any> {
  onClick: (value: T) => void;
  selected: boolean;
  value: T;
  size: RadioButtonSize;
  disabled?: boolean;
  'data-cy'?: string;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  onClick,
  selected,
  value,
  children,
  size,
  disabled,
  'data-cy': dataCy,
}) => {
  const handleClick = () => {
    if (disabled) {
      return;
    }
    if (selected) {
      onClick(value);
    } else {
      onClick(null);
    }
  };

  return (
    <div
      className={`way-radio-button way-radio-button--${size} ${
        disabled && !selected ? 'way-radio-button--disabled' : ''
      } ${disabled ? 'way-radio-button--disabled-input' : ''}`}
      onKeyUp={(event) => (event.key === ' ' ? handleClick() : null)}
      onClick={handleClick}
      data-cy={dataCy}
      tabIndex={0}
    >
      <motion.div
        initial={selected ? 'visible' : 'hidden'}
        animate={selected ? 'visible' : 'hidden'}
        variants={circleVariants}
        className="way-radio-button__circle"
      >
        <AnimatePresence initial={false}>
          {selected ? <motion.i>{whiteVRadio}</motion.i> : null}
        </AnimatePresence>
        <input type="radio" value={`${value}`} defaultChecked={selected} />
      </motion.div>
      {children}
    </div>
  );
};

export default RadioButton;
