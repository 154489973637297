import { gql, useQuery } from '@apollo/client';

export const GET_CUSTOMERS_QUERY = gql`
  query {
    getCustomers(offset: 400) {
      data {
        customer_id
        name
      }
    }
  }
`;

export interface GetCustomersResultInterface {
  getCustomers: {
    data: Array<{
      customer_id: string;
      name: string;
    }>;
  };
}

export const useGetAllCustomers = () => {
  const result = useQuery<GetCustomersResultInterface>(GET_CUSTOMERS_QUERY);
  return result;
};
