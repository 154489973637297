import React, { useState } from 'react';

import './icons-button.scss';

/* eslint-disable-next-line */
export interface IconsButtonProps {
  onClick: () => void;
  hoverPopup?: boolean;
  hoverText?: string;
}

export const IconsButton: React.FC<
  IconsButtonProps &
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
> = ({ onClick, children, hoverPopup, hoverText, ...props }) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <>
      <i
        className={`way-icons-button ${
          hoverPopup ? 'way-icons-button-with-hover' : ''
        }`}
        onClick={() => onClick()}
        onKeyUp={(event) => (event.key === 'Enter' ? onClick() : null)}
        onMouseEnter={() => (hoverPopup ? setIsShown(true) : null)}
        onMouseLeave={() => (hoverPopup ? setIsShown(false) : null)}
        tabIndex={0}
        role="button"
        aria-pressed="true"
        {...props}
      >
        {children}
      </i>
      {isShown && (
        <div className="way-icons-button-with-hover-content">{hoverText}</div>
      )}
    </>
  );
};

IconsButton.defaultProps = {
  hoverPopup: false,
  hoverText: '',
};

export default IconsButton;
