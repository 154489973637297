import Text from '../text/text';

import './top-text-header.scss';

export interface TopTextHeaderProps {
  text?: string;
  accentComp?: JSX.Element;
  icon?: JSX.Element;
}

export function TopTextHeader(props: TopTextHeaderProps) {
  return (
    <div className="way-top-header__container">
      <div className="way-top-header__icon">{props.icon}</div>
      <Text type="info-bold" color="black">
        {props.text}
      </Text>
      {props.accentComp}
    </div>
  );
}
