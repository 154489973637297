import { useRef, useEffect } from 'react';

export function useEffectOnlyOnce(
  callback: () => void,
  when: (dependencies: Array<unknown>) => boolean = () => true,
  dependencies: Array<unknown> = []
) {
  const alreadyExecuted = useRef(false);

  useEffect(() => {
    if (!alreadyExecuted.current) {
      if (when(dependencies)) {
        callback();
        alreadyExecuted.current = true;
      }
    }
  }, dependencies);

  return function reset() {
    alreadyExecuted.current = false;
  };
}
