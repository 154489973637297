import React from 'react';
import { Text } from '../text/text';

import './title-detail-backoffice.scss';

/* eslint-disable-next-line */
export interface TitleDetailBackofficeProps {
  title: string;
  top?: string;
  margin_top?: number;
  padding_bottom?: number;
}

export function TitleDetailBackoffice(props: TitleDetailBackofficeProps) {
  return (
    <div
      className={`way-title-detail-backoffice ${props.top ? props.top : ''}`}
      style={{
        marginTop: `${props.margin_top ? props.margin_top + 'px' : 0}`,
        paddingBottom: `${
          props.padding_bottom ? props.padding_bottom + 'px' : 0
        }`,
      }}
    >
      <Text type="base-s-bold" color="black">
        {props.title}
      </Text>
    </div>
  );
}

export default TitleDetailBackoffice;
