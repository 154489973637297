import React from 'react';

import './spacer.module.scss';

/* eslint-disable-next-line */
export interface SpacerProps {
  margin_top?: number;
  margin_left?: number;
}

export function Spacer(props: React.PropsWithChildren<SpacerProps>) {
  return (
    <div
      style={{
        marginTop: `${props.margin_top ? props.margin_top + 'px' : 0}`,
        marginLeft: `${props.margin_left ? props.margin_left + 'px' : 0}`,
      }}
    >
      {props.children}
    </div>
  );
}

export default Spacer;
