import { gql, useQuery } from '@apollo/client';
import { PaginateResultInterface } from '@eypconfig/shared/types';

export const INSTRUCTION_SEARCH_BACKOFFICE = gql`
  query($product_classification: String, $page: Int, $offset: Int) {
    result: searchInstruction(
      product_classification: $product_classification
      page: $page
      offset: $offset
    ) {
      count
      page
      offset
      data {
        instruction_id
        objectTypeId: instruction_id
        product_classification
        type_code
        brand
        technology
        default_languages
      }
    }
  }
`;

export interface InstructionSearchItem {
  instruction_id: string;
  objectTypeId: string;
  product_classification: string;
  type_code: Array<string>;
  brand: string;
  technology: string;
  default_languages: Array<string>;
}

export interface SearchInstructionColorResult {
  result: PaginateResultInterface<InstructionSearchItem>;
}

export const useSearchInstruction = (search: {
  page?: string;
  offset?: string;
  textFilter?: string;
}) => {
  const result = useQuery<SearchInstructionColorResult>(
    INSTRUCTION_SEARCH_BACKOFFICE,
    {
      variables: {
        page: Number(search.page),
        offset: Number(search.offset),
        product_classification: search.textFilter,
      },
    }
  );
  return result;
};
