import React from 'react';
import './continue-control.scss';
import { Button, buttonType } from '../button/button';
import { Arrow } from '../icon/icon';
import { IconsButton } from '../icons-button/icons-button';
import { Icon3dBtn } from '../icons-button/icons/icon-btn-3d';

/* eslint-disable-next-line */
export interface ContinueControlProps {
  onBack: () => void;
  onContinue: () => void;
  onDelete?: () => void;
  trDVideoUri?: string;
  ontrDVide?: () => void;
  nextDisabled?: boolean;
  customNextText?: string;
  customPrevText?: string;
  customDelText?: string;
  customNextBtnType?: buttonType;
  customPrevBtnType?: buttonType;
  detail?: boolean;
}

export const ContinueControl: React.FC<ContinueControlProps> = ({
  onBack,
  onContinue,
  onDelete,
  trDVideoUri,
  nextDisabled,
  customNextText,
  customPrevText,
  customDelText,
  customNextBtnType,
  customPrevBtnType,
  detail,
  children,
}) => {
  return (
    <div
      className={`way-continue-control ${
        detail ? 'way-continue-control__detail' : ''
      }`}
    >
      <Button
        type={customPrevBtnType ? customPrevBtnType : buttonType.left}
        label={customPrevText ? customPrevText : 'PREVIOUS'}
        onClick={onBack}
        iconName={Arrow}
        data-cy="prev-btn"
      />
      {onDelete && (
        <div style={{ marginRight: 'auto', marginLeft: 10 }}>
          <Button
            type={buttonType.clean}
            bgColor="red"
            label={customDelText ? customDelText : 'DELETE'}
            onClick={onDelete}
            data-cy="del-btn"
          />
        </div>
      )}
      {trDVideoUri ? (
        <IconsButton onClick={() => window.open(trDVideoUri)}>
          <Icon3dBtn />
        </IconsButton>
      ) : null}
      {children}
      <Button
        disabled={nextDisabled}
        type={customNextBtnType ? customNextBtnType : buttonType.right}
        label={customNextText ? customNextText : 'NEXT'}
        onClick={onContinue}
        iconName={Arrow}
        data-cy="next-btn"
      />
    </div>
  );
};

export default ContinueControl;
