import { useQuery, gql } from '@apollo/client';
import { SelectType } from '@eypconfig/shared/types';

export const MOTOR_RANGE_FOR_CONFIGURATION_QUERY = gql`
  query($brand_classification: String) {
    getMotorsSelectOptions(
      propertyToUnique: "range_for_configuration"
      brand_classification: $brand_classification
    ) {
      data {
        label
        value
      }
    }
  }
`;

export interface MotorRangeForConfigurationOptions {
  getMotorsSelectOptions: {
    data: Array<SelectType>;
  };
}

export const useRangeForConfigurationMotor = (
  brand_classification?: string
) => {
  const status = useQuery<MotorRangeForConfigurationOptions>(
    MOTOR_RANGE_FOR_CONFIGURATION_QUERY,
    {
      variables: {
        brand_classification: brand_classification
          ?.split('_')
          ?.join(' ')
          ?.toUpperCase(),
      },
    }
  );

  return status;
};
