import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  OtherAccessoriesSearchFilter,
  PaginateResultInterface,
  PaginationArgs,
} from '@eypconfig/shared/types';

export const OTHER_ACCESSORIES_SEARCH_CATALOGUE_QUERY = gql`
  query(
    $page: Int
    $offset: Int
    $reference: [String!]
    $brand: [String!]
    $brand_classification: [String!]
    $customer: [String!]
    $somfy_match: [String!]
    $rolling_tube: [String!]
    $size_motor: [String!]
    $technology: [String!]
    $motor_head: [String!]
    $motor_type: [String!]
    $package_type: [String!]
    $range_for_configuration: Boolean
  ) {
    searchOtherAccessories(
      page: $page
      offset: $offset
      reference: $reference
      brand: $brand
      brand_classification: $brand_classification
      customer: $customer
      somfy_match: $somfy_match
      technology: $technology
      rolling_tube: $rolling_tube
      size_motor: $size_motor
      motor_type: $motor_type
      packaging_type: $package_type
      range_for_configuration: $range_for_configuration
      motor_head: $motor_head
    ) {
      count
      offset
      data {
        __typename
        other_accessory_reference_id
        reference
        designation
        other_accessory_image
        motor_type
        rolling_tube
        somfy_match
        applications
        package_type
        motor_size
        three_d_video
        catalogue
        product_technical_sheet
        ce_declaration
        vde_certification
        nf_certification
        compare_declaration
        photo_high_resolution
        photo_low_resolution
        dimensional_drawings
        mini_programming_guide
        video_installation
        motor_head
        nominal_torque
        made_in
        package_brand
        nr_of_other_acc
      }
    }
  }
`;

export const OTHER_ACCESSORIES_COUNT_QUERY = gql`
  query {
    searchOtherAccessories {
      count
    }
  }
`;

export interface OtherAccessorieCatalogueInterface {
  __typename: 'OtherAccessoryReference';
  reference: string;
  designation: string;
  other_accessory_reference_id: string;
  other_accessory_image?: string;
  motor_type?: Array<string>;
  rolling_tube?: string;
  somfy_match?: string;
  applications?: string;
  package_type?: string;
  motor_size?: Array<string>;
  three_d_video?: string;
  catalogue?: string;
  product_technical_sheet?: string;
  ce_declaration?: string;
  vde_certification?: string;
  nf_certification?: string;
  compare_declaration?: string;
  photo_high_resolution?: string;
  photo_low_resolution?: string;
  dimensional_drawings?: string;
  mini_programming_guide?: string;
  video_installation?: string;
  motor_head?: string;
  nominal_torque?: string;
  made_in?: string;
  package_brand?: string;
  nr_of_other_acc?: string;
}

export interface SearchOtherAccessoriesCatalogQueryResultInterface {
  searchOtherAccessories: PaginateResultInterface<OtherAccessorieCatalogueInterface>;
}

export const useSearchOtherAccessoriesCatalogue = (
  filter?: OtherAccessoriesSearchFilter & PaginationArgs
) => {
  const data = useQuery<SearchOtherAccessoriesCatalogQueryResultInterface | null>(
    OTHER_ACCESSORIES_SEARCH_CATALOGUE_QUERY,
    {
      variables: {
        ...filter,
        brand_classification: filter?.brand_classification?.map((brand) =>
          brand.split('_').join(' ').toUpperCase()
        ),
      },
    }
  );

  return data;
};

export const useLazySearchOtherAccessoriesCatalogue = (
  filter?: OtherAccessoriesSearchFilter & PaginationArgs
) => {
  const data = useLazyQuery<SearchOtherAccessoriesCatalogQueryResultInterface | null>(
    OTHER_ACCESSORIES_SEARCH_CATALOGUE_QUERY,
    {
      variables: {
        ...filter,
        brand_classification: filter?.brand_classification?.map((brand) =>
          brand.split('_').join(' ').toUpperCase()
        ),
      },
    }
  );

  return data;
};

export interface CountOtherAccessoriesCatalogQueryResultInterface {
  searchOtherAccessories: {
    count: number;
  };
}

export const useLazyCountOtherAccessories = () => {
  const data = useLazyQuery<CountOtherAccessoriesCatalogQueryResultInterface | null>(
    OTHER_ACCESSORIES_COUNT_QUERY
  );

  return data;
};
