import { useQuery, gql } from '@apollo/client';
import { SelectType } from '@eypconfig/shared/types';

export const MOTOR_TYPES_QUERY = gql`
  query(
    $brand_classification: String
    $technology: String
    $size_motor: String
    $range_for_configuration: Boolean
  ) {
    getMotorsSelectOptions(
      lableTemplate: "{{ type_motor }}"
      propertyToUnique: "type_motor_code"
      brand_classification: $brand_classification
      technology: $technology
      size_motor: $size_motor
      range_for_configuration: $range_for_configuration
    ) {
      data {
        label
        value
      }
    }
  }
`;

export interface MotorsTypesOptions {
  getMotorsSelectOptions: {
    data: Array<SelectType>;
  };
}

export const useTypesMotor = (
  brand_classification?: string,
  technology?: string,
  size_motor?: string,
  range_for_configuration?: boolean
) => {
  const status = useQuery<MotorsTypesOptions>(MOTOR_TYPES_QUERY, {
    variables: {
      brand_classification: brand_classification
        ?.split('_')
        ?.join(' ')
        ?.toUpperCase(),
      technology: technology,
      size_motor: size_motor,
      range_for_configuration,
    },
  });

  return status;
};
