import { gql, useMutation } from '@apollo/client';

export const UPDATE_FILE_CONTROLLER_MUTATION = gql`
  mutation(
    $signed_document_uri: String
    $date_sign: DateTime
    $reference: String
    $control_reference_id: String!
  ) {
    updateControlReference(
      controlReference: {
        control_reference_id: $control_reference_id
        date_sign: $date_sign
        signed_document_uri: $signed_document_uri
        reference: $reference
      }
    ) {
      signed_document_uri
      date_sign
    }
  }
`;

export interface UpdateFileControllerResultInterface {
  updateControlReference: {
    signed_document_uri?: string;
    date_sign?: Date;
  };
}

export const useUpdateFileController = (control_reference_id: string) => {
  const [
    updateFileController,
    result,
  ] = useMutation<UpdateFileControllerResultInterface>(
    UPDATE_FILE_CONTROLLER_MUTATION,
    {
      variables: {
        control_reference_id,
      },
    }
  );
  return {
    updateFileController: (signed_document_uri: string) =>
      updateFileController({
        variables: { signed_document_uri, date_sign: new Date() },
      }),
    ...result,
  };
};

export interface ControllerReferenceUpdateInterface {
  reference?: string;
}

export const useUpdateReferenceController = (control_reference_id: string) => {
  const [
    updateController,
    result,
  ] = useMutation<UpdateFileControllerResultInterface>(
    UPDATE_FILE_CONTROLLER_MUTATION,
    {
      variables: {
        control_reference_id,
      },
    }
  );
  return {
    updateController: (controllerData: ControllerReferenceUpdateInterface) =>
      updateController({
        variables: controllerData,
      }),
    ...result,
  };
};
