import { gql, useMutation } from '@apollo/client';

const DELETE_MOTOR_REFERENCE_MUTATION = gql`
  mutation($motor_reference_id: String!) {
    deleteMotorReference(
      motorDeleteFilter: { motor_reference_id: $motor_reference_id }
    ) {
      motor_reference_id
    }
  }
`;

export const useDeleteMotor = (motor_reference_id: string) => {
  const [deleteMotor, result] = useMutation(DELETE_MOTOR_REFERENCE_MUTATION);
  return {
    doDelete: () =>
      deleteMotor({
        variables: {
          motor_reference_id,
        },
      }).then((result) => !result.errors),
    ...result,
    error: result.error?.message,
  };
};
