import React from 'react';
import Text from '../text/text';
import './step.scss';
import { motion } from 'framer-motion';

/* eslint-disable-next-line */
export interface StepProps {
  done: boolean;
  active: boolean;
  number: number;
  text: string;
}

export function Step({ done, active, number, text }: StepProps) {
  const spring = {
    type: 'spring',
    stiffness: 500,
    damping: 30,
  };

  return (
    <div className="way-step" key={number}>
      <div
        className="way-step__circle"
        style={{
          borderColor: active || done ? '#8dd174' : '#2a2a2a',
          backgroundColor: done ? '#8dd174' : '#fff',
        }}
      >
        <Text type="base-bold" color={done || active ? 'white' : 'lightgrey'}>
          {number}
        </Text>
        {active && (
          <motion.span
            className="way-step__circle way-step__circle--shared"
            layoutId="circle-shared"
            initial={false}
            transition={spring}
          />
        )}
      </div>
      <Text
        type={done || active ? 'base-xs-bold' : 'base-xs'}
        color={done || active ? 'black' : 'midgrey'}
      >
        {text}
      </Text>
    </div>
  );
}

export default Step;
