import { gql, useMutation } from '@apollo/client';
import { AdapterReferenceDto } from '@eypconfig/shared/import-validations';

export const CREATE_ADAPTER_REFERENCE_MUTATION = gql`
  mutation ($adaptersReference: CreateAdaptersReferenceSingleInput!) {
    createAdapterReference(adaptersReference: $adaptersReference) {
      reference_id: adapter_reference_id
    }
  }
`;

export function useCreateAdapterReference() {
  const [createAdapterReference, result] = useMutation(
    CREATE_ADAPTER_REFERENCE_MUTATION
  );
  return {
    createAdapterReference: (adaptersReference: AdapterReferenceDto) =>
      createAdapterReference({ variables: { adaptersReference } }),
    ...result,
  };
}
