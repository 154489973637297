import { gql, useQuery } from '@apollo/client';
import { PaginateResultInterface } from '@eypconfig/shared/types';

export const TUBE_COLOR_SEARCH_BACKOFFICE = gql`
  query($ral_name: String, $page: Int, $offset: Int) {
    result: searchTubeColors(
      ral_name: $ral_name
      page: $page
      offset: $offset
    ) {
      count
      page
      offset
      data {
        tube_color_id
        objectTypeId: tube_color_id
        color_code
        motor_size
        motor_type
        ral_name
        tube_color
      }
    }
  }
`;

export interface TubeColorSearchItem {
  tube_color_id: string;
  objectTypeId: string;
  color_code?: string;
  motor_size?: string;
  ral_name?: string;
  tube_color?: string;
  motor_type?: Array<string>;
}

export interface SearchTubeColorResult {
  result: PaginateResultInterface<TubeColorSearchItem>;
}

export const useSearchTubeColor = (search: {
  page?: string;
  offset?: string;
  textFilter?: string;
}) => {
  const result = useQuery<SearchTubeColorResult>(TUBE_COLOR_SEARCH_BACKOFFICE, {
    variables: {
      page: Number(search.page),
      offset: Number(search.offset),
      ral_name: search.textFilter,
    },
  });
  return result;
};
