import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { GetAdapterResultInterface } from '../backoffice/get';

export const ADAPTERS_OPTIONS_QUERY = gql`
  query($propertyToUnique: String!) {
    result: getAdaptersSelectOptions(
      propertyToUnique: $propertyToUnique
      offset: 400
    ) {
      options: data {
        label
        value
      }
    }
  }
`;

export type PropertyToUniqueAdaptersType = keyof Omit<
  GetAdapterResultInterface['getAdapterReference'],
  '__typename' | 'deleted_at' | 'updated_at'
>;

export interface GenericAdapterOptionsResult {
  result: {
    options: Array<{
      label: string;
      value: string;
    }>;
  };
}

export const useGenericAdapterOptions = (
  propertyToUnique: PropertyToUniqueAdaptersType
) => {
  return useQuery<GenericAdapterOptionsResult>(ADAPTERS_OPTIONS_QUERY, {
    variables: { propertyToUnique },
  });
};
