import { UserRoles, useUserData } from '@eypconfig/client/data-access';

export const useIsUserRole = (role: UserRoles | Array<UserRoles>) => {
  const { data, loading } = useUserData();

  return {
    isRole: Array.isArray(role)
      ? role.includes(data?.authUserInfo?.internal_role)
      : data?.authUserInfo?.internal_role === role,
    loading,
  };
};
