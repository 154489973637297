import React, { useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
import { getGraphQlClient } from '@eypconfig/client/graphql-client';
import { store } from './redux/create-store';
import { Provider } from 'react-redux';
import { useRefreshToken } from '@f-technology-srl/react-authorization';
import { RouterOutlet } from './routes/router';

import './app.scss';

const ApolloAuthWrapper = () => {
  const refreshTokenAction = useRefreshToken();
  const graphqlClient = useMemo(
    () =>
      getGraphQlClient(
        '/graphql',
        refreshTokenAction,
        process.env.NODE_ENV === 'production'
      ),
    [refreshTokenAction]
  );

  return (
    <ApolloProvider client={graphqlClient}>
      <div className="way-global-app-container">
        <RouterOutlet />
      </div>
    </ApolloProvider>
  );
};

export function App() {
  return (
    <Provider store={store}>
      <ApolloAuthWrapper />
    </Provider>
  );
}

export default App;
