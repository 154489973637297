import React from 'react';

/* eslint-disable-next-line */
export const IconMenu = () => {
  return (
    <svg width="35" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#fff" d="M0 0h35v2.3H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3335 10.35h25.6667v2.3H9.3335v-2.3ZM9.3335 20.7h25.6667V23H9.3335v-2.3Z"
        fill="#fff"
      />
      <path
        d="m1.7567 37 2.82858 9.6585L7.41387 37h1.7567v11.8374H7.80838v-4.6098l.12654-4.6178-2.83603 9.2276H4.05678l-2.82114-9.1951.12655 4.5853v4.6098H0V37h1.7567ZM16.7184 43.3659h-3.8335v4.1951h4.4662v1.2764h-5.8283V37h5.7539v1.2846h-4.3918v3.8048h3.8335v1.2765ZM26.1793 48.8374h-1.3696l-4.3397-9.122v9.122h-1.3696V37H20.47l4.3545 9.1626V37h1.3548v11.8374ZM35 37v8.4146c-.0099 1.122-.3077 2-.8932 2.6342-.5856.6341-1.4019.9512-2.449.9512-1.0719 0-1.8907-.3089-2.4564-.9268-.5657-.6233-.8535-1.5095-.8635-2.6586V37h1.3548v8.3577c0 .7968.1538 1.393.4615 1.7886.3126.3903.8138.5854 1.5036.5854.6947 0 1.196-.1951 1.5036-.5854.3127-.3956.469-.9918.469-1.7886V37H35Z"
        fill="#fff"
      />
    </svg>
  );
};

export default IconMenu;
