import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { Workbox } from 'workbox-window';
import App from './app/app';

// SOME EXPLANATION IS NEEDED HERE:
// The "Loading chunk" error is given by webpack when the application updates
// but the client still have the old version becase the pase has not been refreshed.
// This will lead to not find the chunk on the server that the client expect to find.
// This code will handle the refresh of the page when this error is recived
window.addEventListener('error', (e) => {
  if (/Loading chunk [\d]+ failed/.test(e.message)) {
    // Avoid the event to bubble up
    e.preventDefault();
    window.location.reload();
  }
});

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://0e0419d7f52542d39602156fd9d73683@o602090.ingest.sentry.io/5744362',
    release: process.env.VERSION,
    environment: 'production',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1,
    autoSessionTracking: false,
  });

  window.addEventListener('load', () => {
    if ('serviceWorker' in navigator) {
      const wb = new Workbox('/service-worker.js');
      wb.addEventListener('installed', (event) => {
        if (event.isUpdate) {
          if (
            window.confirm(`New version is available!. Click OK to refresh`)
          ) {
            window.location.reload();
          }
        }
      });

      wb.register();
    }
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
