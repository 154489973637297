import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import './loading.scss';

export function LoadingPortal({
  loadingId,
  children,
}: React.PropsWithChildren<{ loadingId: string }>) {
  const loadingRef = useRef<HTMLElement>();

  useEffect(() => {
    const element = document.getElementById(loadingId);
    if (!element) {
      console.warn(
        `[PopupPortal]: Unable to find the popup container with id ${loadingId}`
      );
      return;
    }
    loadingRef.current = element;
  }, [loadingId]);

  return loadingRef.current
    ? ReactDOM.createPortal(children, loadingRef.current)
    : null;
}

/* eslint-disable-next-line */
export interface LoadingProps {
  open?: boolean;
}

export const LoadingController = React.memo(
  ({ open }: LoadingProps) => {
    return (
      <LoadingPortal loadingId={'way_loading'}>
        <AnimatePresence>
          {open ? (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: 8, transition: { delay: 0.3 } }}
              exit={{ height: 0 }}
              className="way-loading"
            >
              <div />
            </motion.div>
          ) : null}
        </AnimatePresence>
      </LoadingPortal>
    );
  },
  (prev, next) => prev.open === next.open
);

export function LoadingContainer() {
  return <div id="way_loading" />;
}

export default LoadingController;
