import { gql, useMutation } from '@apollo/client';
import { CustomerSearchItem } from '../../search';

export const CUSTOMER_UPDATE_QUERY = gql`
  mutation($customerItem: UpdateCustomerDto!) {
    updateCustomer(customerItem: $customerItem) {
      customer_id
    }
  }
`;

export interface UpdateCustomerResponse {
  updateCustomer: {
    customer_id: string;
  };
}

export type UpdateCustomerItem = Partial<
  Omit<CustomerSearchItem, 'customer_id'>
> &
  Pick<CustomerSearchItem, 'customer_id'>;

export const useUpdateCustomer = () => {
  const [mutate, { loading, error }] = useMutation<UpdateCustomerResponse>(
    CUSTOMER_UPDATE_QUERY
  );
  return {
    update: ({ objectTypeId, __typename, ...items }: UpdateCustomerItem) =>
      mutate({
        variables: {
          customerItem: {
            customer_id: objectTypeId,
            ...items,
          },
        },
      }).then((result) => !result.errors),
    loading,
    error: error?.message,
  };
};
