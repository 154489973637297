import React from 'react';

/* eslint-disable-next-line */
export const IconBack = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 17.5C35 7.835 27.1649 0 17.5 0C7.835 0 0 7.835 0 17.5C0 27.1649 7.835 35 17.5 35C27.1649 35 35 27.1649 35 17.5ZM19.33 12.665L20.01 12L26 18L20.01 24L19.33 23.34L24.185 18.475H10V17.525H24.185L19.33 12.665Z"
        fill="#87C470"
      />
    </svg>
  );
};

export default IconBack;
