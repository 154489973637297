import { gql, useMutation } from '@apollo/client';
import { OtherAccessoriesSearchFilter } from '@eypconfig/shared/types';

export const BULK_DELETE_OTHER_ACCESSORIES_MUTATION = gql`
  mutation ($filter: OtherAccessoriesSearchFilter!) {
    bulkDeleteOtherAccessories(filter: $filter) {
      count
    }
  }
`;

export interface BulkDeleteOtherAccessoriesResultInterface {
  bulkDeleteOtherAccessories: {
    count?: number;
  };
}

export const useBulkDeleteOtherAccessories = () => {
  const [bulkDeleteOtherAccessories, result] =
    useMutation<BulkDeleteOtherAccessoriesResultInterface>(
      BULK_DELETE_OTHER_ACCESSORIES_MUTATION
    );

  return {
    bulkDeleteOtherAccessories: (filter: OtherAccessoriesSearchFilter) =>
      bulkDeleteOtherAccessories({
        variables: {
          filter,
        },
      }).then(({ data }) => data?.bulkDeleteOtherAccessories?.count),
    ...result,
  };
};
