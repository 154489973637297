import React, { useMemo } from 'react';
import Accordion from '../accordion/accordion';

import './nested-accordion.module.scss';

export interface NestedRecursiveAccordionProps<DataType> {
  keyId?: keyof DataType;
  data: Array<DataType>;
  children: (data: DataType, index?: number) => JSX.Element;
  fullData: Array<DataType>;
}
export interface NestedRecursiveAccordionProps<DataType> {
  title: keyof DataType;
  nestedData: Array<NestedRecursiveAccordionProps<DataType>>;
  paddingLeft?: boolean;
}

function NestedRecursiveAccordion<DataType>({
  data,
  nestedData,
  children,
  fullData,
  keyId,
}: NestedRecursiveAccordionProps<DataType>) {
  if (nestedData) {
    return (
      <div>
        {nestedData.map((props) => (
          <Accordion
            key={String(props.title)}
            title={String(props.title)}
            data-cy={`nested-accordion-${String(props.title)}`}
            paddingLeft={props.paddingLeft ? true : false}
          >
            <NestedRecursiveAccordion {...props} />
          </Accordion>
        ))}
      </div>
    );
  }
  if (data && children) {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {data.map((datum) => {
          const trueIndex = fullData.findIndex((el) => {
            const compareEl = keyId ? String(el[keyId]) : JSON.stringify(el);
            const compareDatum = keyId
              ? String(datum[keyId])
              : JSON.stringify(datum);

            return compareEl === compareDatum;
          });
          return (
            <React.Fragment
              key={keyId ? String(datum[keyId]) : JSON.stringify(datum)}
            >
              {children(datum, trueIndex)}
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

/* eslint-disable-next-line */
export interface NestedAccordionProps<DataType> {
  keyId?: keyof DataType;
  nestedKeys: Array<keyof DataType>;
  data: Array<DataType>;
  children: (data: DataType, index?: number) => JSX.Element;
}

export function NestedAccordion<DataType>({
  nestedKeys,
  data,
  children,
  keyId,
}: NestedAccordionProps<DataType>) {
  const sortedData = useMemo(() => {
    const sortFor = (data: Array<DataType>, key: keyof DataType) => {
      const copy = [...data];
      return copy.sort((item1, item2) => {
        if (item1[key] < item2[key]) {
          return -1;
        }
        if (item1[key] > item2[key]) {
          return 1;
        }
        return 0;
      });
    };
    return nestedKeys.reduce((list, key) => sortFor(list, key), data);
  }, [data, nestedKeys]);

  const nestedStructure: NestedRecursiveAccordionProps<DataType> = useMemo(() => {
    const recursiveCreateNestedStructure = (
      filteredData: Array<DataType>,
      clusterUsingValue?: DataType[keyof DataType],
      indexCurrentKey?: number
    ) => {
      let index = 0;
      if (indexCurrentKey) {
        index = indexCurrentKey;
      }
      const currentKey: keyof DataType = nestedKeys[index];
      const isLast = index >= nestedKeys.length;
      if (isLast) {
        return {
          keyId,
          fullData: sortedData,
          title: clusterUsingValue,
          data: filteredData,
          children,
        };
      } else {
        const differentValues = filteredData.reduce((acc, datum) => {
          const value = String(datum[currentKey]);
          if (value && !acc.includes(value)) {
            return [...acc, value];
          }
          return acc;
        }, []);
        return {
          title: clusterUsingValue,
          nestedData: differentValues.map((value) =>
            recursiveCreateNestedStructure(
              filteredData.filter(
                (datum) => String(datum[currentKey]) === value
              ),
              value,
              index + 1
            )
          ),
          paddingLeft: true,
        };
      }
    };

    return recursiveCreateNestedStructure(sortedData);
  }, [children, sortedData, keyId, nestedKeys]);

  return <NestedRecursiveAccordion {...nestedStructure} />;
}

export default NestedAccordion;
