import React, { useMemo } from 'react';
import './table.scss';
import Text from '../text/text';

/* eslint-disable-next-line */
export interface TableProps<DataType> {
  dataSource?: Array<DataType>;
  colDescription?: Array<{
    headerName: string;
    component: (row: DataType) => JSX.Element;
  }>;
  stickyHeader?: boolean;
}

export const Table: React.FC<TableProps<unknown>> = ({
  dataSource,
  colDescription,
  stickyHeader,
}) => {
  const colSize = useMemo(
    () => (colDescription ? 100 / colDescription.length : 100),
    [colDescription]
  );
  const minRows = 4;
  const emptyRows = dataSource ? minRows - dataSource.length : minRows;

  const getMinRows = () => {
    let rows: Array<JSX.Element> = [];

    const EmptyRowCmp = (number) => (
      <div key={number} className="way-table__body__row">
        {colDescription
          ? colDescription.map((_, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: colSize,
                    maxWidth: '100%',
                    flex: `1 1 ${colSize}%`,
                  }}
                />
              );
            })
          : null}
      </div>
    );

    for (let i = 0; i < emptyRows; i++) {
      rows = [...rows, EmptyRowCmp(i)];
    }

    return rows;
  };

  return (
    <div
      className={`way-table ${stickyHeader ? 'way-table--sticky-header' : ''}`}
    >
      <div
        className="way-table__header"
        style={{
          minWidth: colDescription ? 250 * colDescription.length : 'none',
        }}
      >
        {colDescription
          ? colDescription.map(({ headerName }, index) => {
              return (
                <div
                  key={index}
                  className="way-table__header__item"
                  style={{
                    width: `${colSize}%`,
                    maxWidth: '100%',
                    flex: `1 1 calc(${colSize}% - 8px)`,
                    minWidth: 250,
                  }}
                >
                  <Text type="base-s-bold" color="black">
                    {headerName}
                  </Text>
                </div>
              );
            })
          : null}
      </div>
      <div
        className="way-table__body"
        style={{
          minWidth: colDescription ? 260 * colDescription.length : 'none',
        }}
      >
        {dataSource
          ? dataSource.map((data, index) => {
              return (
                <div key={index} className="way-table__body__row">
                  {colDescription
                    ? colDescription.map(({ component }, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              width: `${colSize}%`,
                              maxWidth: '100%',
                              flex: `1 1 ${colSize}%`,
                              minWidth: 260,
                            }}
                          >
                            {component(data)}
                          </div>
                        );
                      })
                    : null}
                </div>
              );
            })
          : null}
        {emptyRows > 0 ? getMinRows() : null}
      </div>
    </div>
  );
};

export default Table;
