import { useOnClickOutside } from '@f-technology-srl/client-utils';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { IconClose } from '../icons-button/icons';
import IconsButton from '../icons-button/icons-button';

import './popup-container.scss';

export function PopupPortal({
  popContainerId,
  children,
}: React.PropsWithChildren<{ popContainerId: string }>) {
  const popupContainerRef = useRef<HTMLElement>();

  useEffect(() => {
    const element = document.getElementById(popContainerId);
    if (!element) {
      console.warn(
        `[PopupPortal]: Unable to find the popup container with id ${popContainerId}`
      );
      return;
    }
    popupContainerRef.current = element;
  }, [popContainerId]);

  return popupContainerRef.current
    ? ReactDOM.createPortal(children, popupContainerRef.current)
    : null;
}

export interface ChildrenFunctionPopupParams {
  close: () => unknown;
  isOpen: boolean;
}

/* eslint-disable-next-line */
export interface PopupContainerProps {
  popContainerId: string;
  children: (options: ChildrenFunctionPopupParams) => JSX.Element;
  open?: boolean;
  openControllerComponent: ({ onClick }) => JSX.Element;
  onClose?: () => void;
}

const whiteBoxVariants: Variants = {
  show: {
    scale: 1,
    transition: {
      delay: 0.2,
    },
  },
  hide: {
    scale: 0,
  },
};

export function PopupContainer({
  popContainerId,
  children,
  openControllerComponent,
  onClose,
  open: openProps,
}: PopupContainerProps) {
  const [isOpen, setOpen] = useState(false);
  const refPopupContainer = useRef();

  const isOpenProps = typeof openProps === 'boolean';

  const close = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  useOnClickOutside(refPopupContainer, close);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close();
      }
    };
    document.addEventListener('keyup', handler);
    return () => {
      document.removeEventListener('keyup', handler);
    };
  });

  return (
    <>
      {openControllerComponent({
        onClick: () => {
          setOpen((open) => !open);
        },
      })}
      <PopupPortal popContainerId={popContainerId}>
        <AnimatePresence>
          {(isOpenProps && openProps) || isOpen ? (
            <motion.div>
              <div
                data-testid="way-popup-container"
                className="way-popup-container"
              >
                <motion.div
                  transition={{ duration: 0.2 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 0.6 }}
                  exit={{ opacity: 0 }}
                  className="way-popup-container__container"
                ></motion.div>
                <motion.div
                  ref={refPopupContainer}
                  variants={whiteBoxVariants}
                  initial="hide"
                  animate="show"
                  exit="hide"
                  className="way-popup-container__white-box"
                >
                  <div className="geen-line"></div>
                  <div className="close-btn" data-testid="close-popup-btn">
                    <IconsButton onClick={close}>
                      <IconClose />
                    </IconsButton>
                  </div>
                  <div className="way-popup-container__content">
                    {children({
                      close,
                      isOpen,
                    })}
                  </div>
                </motion.div>
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </PopupPortal>
    </>
  );
}

export default PopupContainer;
