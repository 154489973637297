import { gql, useMutation } from '@apollo/client';
import { PlasticCapColorItem } from '../../get';

export const CREATE_PLASTIC_CAP_COLOR_QUERY = gql`
  mutation($plasticCapItem: CreatePlasticCapColorItemDto!) {
    createPlasticCapsColor(plasticCapItem: $plasticCapItem) {
      plastic_cap_id
    }
  }
`;

export interface CreatePlasticCapColorResult {
  createPlasticCapsColor: {
    plastic_cap_id: string;
  };
}

export type PlasticCapColorCreateItem = Omit<
  PlasticCapColorItem,
  'plastic_cap_id' | '__typename' | 'created_at' | 'updated_at' | 'objectTypeId'
>;

export const useCreatePlasticCapColor = () => {
  const [create, { error, loading }] = useMutation<CreatePlasticCapColorResult>(
    CREATE_PLASTIC_CAP_COLOR_QUERY
  );
  return {
    create: (plasticCapItem: PlasticCapColorCreateItem) =>
      create({ variables: { plasticCapItem } }).then((result) => {
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
        return result.data?.createPlasticCapsColor.plastic_cap_id;
      }),
    error: error?.message,
    loading,
  };
};
