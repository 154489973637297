import { gql, useMutation } from '@apollo/client';
import { BracketsSearchFilter } from '@eypconfig/shared/types';

export const BULK_DELETE_BRACKETS_MUTATION = gql`
  mutation ($filter: BracketsSearchFilter!) {
    bulkDeleteBrackets(filter: $filter) {
      count
    }
  }
`;

export interface BulkDeleteBracketsResultInterface {
  bulkDeleteBrackets: {
    count?: number;
  };
}

export const useBulkDeleteBrackets = () => {
  const [bulkDeleteBrackets, result] =
    useMutation<BulkDeleteBracketsResultInterface>(
      BULK_DELETE_BRACKETS_MUTATION
    );

  return {
    bulkDeleteBrackets: (filter: BracketsSearchFilter) =>
      bulkDeleteBrackets({
        variables: {
          filter,
        },
      }).then(({ data }) => data?.bulkDeleteBrackets?.count),
    ...result,
  };
};
