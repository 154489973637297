import { gql, useQuery } from '@apollo/client';
import { PaginateResultInterface } from '@eypconfig/shared/types';

export const CUSTOMERS_SEARCH_BACKOFFICE = gql`
  query($name: String, $page: Int, $offset: Int) {
    result: getCustomers(name: $name, page: $page, offset: $offset) {
      count
      page
      offset
      data {
        name
        customer_id
        objectTypeId: customer_id
      }
    }
  }
`;

export interface CustomerSearchItem {
  customer_id: string;
  objectTypeId: string;
  name: string;
  __typename: string;
}

export interface SearchCustomersResult {
  result: PaginateResultInterface<CustomerSearchItem>;
}

export const useSearchCustomers = (search: {
  page?: string;
  offset?: string;
  textFilter?: string;
}) => {
  const result = useQuery<SearchCustomersResult>(CUSTOMERS_SEARCH_BACKOFFICE, {
    variables: {
      page: Number(search.page),
      offset: Number(search.offset),
      name: search.textFilter,
    },
  });
  return result;
};
