export function fromUnderscoreToSpace(str: string) {
  return str.split('_').join(' ').toUpperCase();
}

export function sanitizeFrom__typename<Data extends { __typename?: string }>(
  item?: Data
): Omit<Data, '__typename'> {
  let result;
  if (item) {
    const { __typename, ...rest } = item;
    result = rest;
  } else {
    result = item;
  }
  return result;
}

export function certificationsString(dataSource?: {
  certification_ce?: boolean;
  certification_cmim?: boolean;
  certification_nf?: boolean;
  certification_vde?: boolean;
  certification_saber?: boolean;
  certification_pai?: boolean;
}) {
  const certifications = [];

  if (dataSource?.certification_ce) {
    certifications.push('CE');
  }
  if (dataSource?.certification_cmim) {
    certifications.push('CMIM');
  }
  if (dataSource?.certification_nf) {
    certifications.push('NF');
  }
  if (dataSource?.certification_vde) {
    certifications.push('VDE');
  }
  if (dataSource?.certification_saber) {
    certifications.push('SABER');
  }
  if (dataSource?.certification_pai) {
    certifications.push('PAI');
  }

  return certifications.join(' - ');
}

export function sortPackageType(list?: Array<{ label: string }>) {
  // Put first the single package label
  const newSortableList = Array.from(list || []);
  return newSortableList?.sort((opt1, opt2) => {
    if (opt1?.label?.includes('Single')) {
      return -1;
    }
    if (opt2?.label?.includes('Single')) {
      return 1;
    }
    const regNumb = /\d+/;
    const opt1Number = Number(regNumb.exec(opt1?.label)?.[0]);
    const opt2Number = Number(regNumb.exec(opt2?.label)?.[0]);
    if (opt1Number < opt2Number) {
      return -1;
    }
    if (opt1Number > opt2Number) {
      return 1;
    }
    return 0;
  });
}

export const errorRef = (message: string, ref: string) => {
  if (
    message.indexOf('Key (reference)') > -1 &&
    message.indexOf('already exists') > -1
  ) {
    return 'Reference with code ' + ref + ' already exists';
  }
  return message;
};
