import { gql, useMutation } from '@apollo/client';
import { UserRoles } from './user-data.hook';

const CREATE_USER_QUERY = gql`
  mutation ($userInput: NewUserDto!) {
    createNewUser(userInput: $userInput) {
      user_id
    }
  }
`;

export interface NewUserPayloadInterface {
  email: string;
  username: string;
  name: string;
  surname: string;
  internal_role: UserRoles;
  customers: Array<string>;
  somfyGroup: Array<string>;
  brand_classification: Array<string>;
}

export interface NewUserMutationResult {
  createNewUser: {
    user_id: string;
  };
}

export const useCreateNewUser = () => {
  const [create, result] =
    useMutation<NewUserMutationResult>(CREATE_USER_QUERY);
  return {
    create: (userInput: NewUserPayloadInterface) =>
      create({ variables: { userInput } }),
    ...result,
  };
};
