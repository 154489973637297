import React, { useState, useEffect } from 'react';
import Button, { buttonType } from '../button/button';
import { Checkbox } from '../checkbox/checkbox';
import Icon, { Arrow, Create } from '../icon/icon';
import IconsButton from '../icons-button/icons-button';
import PopupContainer from '../popup-container/popup-container';
import Textarea from '../textarea/textarea';
import Text from '../text/text';

import './multiple-checkbox.scss';

export type CheckboxType = { label: string; value: string };

/* eslint-disable-next-line */
export interface MultipleCheckboxProps {
  checkboxDefaultOptions: Array<CheckboxType>;
  enableAddMore: boolean;
  onlyActive?: boolean;
  disabled?: boolean;
  selected: Array<string>;
  onOptionsChange: (value: Array<string>) => void;
}

export function MultipleCheckbox({
  checkboxDefaultOptions,
  enableAddMore,
  onlyActive,
  disabled,
  selected,
  onOptionsChange,
}) {
  const [options, setOptions] = useState(checkboxDefaultOptions);
  const [newCheckbox, setNewCheckbox] = useState('');

  useEffect(() => {
    if (options?.length < checkboxDefaultOptions?.length) {
      setOptions(checkboxDefaultOptions);
    }
  }, [checkboxDefaultOptions, options]);

  const addCheckbox = () => {
    setOptions((options) => [
      ...options,
      {
        label: newCheckbox.toUpperCase(),
        value: newCheckbox.toLowerCase(),
      },
    ]);
    onOptionsChange([...selected, newCheckbox.toLowerCase()]);
    setNewCheckbox('');
  };

  const handleClick = (itemValue: string) => () => {
    if (selected?.includes(itemValue)) {
      const selectedLanguage = (value) => value !== itemValue;
      onOptionsChange(selected?.filter(selectedLanguage));
    } else {
      onOptionsChange([...selected, itemValue]);
    }
  };

  return (
    <div className="way-multiple-checkbox">
      {options
        ? options.map((item, index) => {
            return (
              <Checkbox
                key={index}
                label={item.label}
                selected={selected?.includes(item.value)}
                value={item.value}
                name={item.label}
                onClick={handleClick(item.value)}
                size="sm"
                disabled={
                  disabled ? true : onlyActive && selected.includes(item.value)
                }
                hide={
                  onlyActive && !selected?.includes(item.value)
                    ? 'hide'
                    : 'none'
                }
              />
            );
          })
        : null}
      {enableAddMore ? (
        <PopupContainer
          open={false}
          openControllerComponent={(props) => (
            <IconsButton
              onClick={() => props.onClick()}
              data-cy="add-more-checkbox"
            >
              <Icon iconName={Create} />
            </IconsButton>
          )}
          popContainerId="way-add-checkbox"
        >
          {({ close }) => {
            return (
              <div
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                }}
              >
                <div style={{ paddingBottom: 30 }}>
                  <Text type="info-bold" color="black">
                    Add value
                  </Text>
                </div>
                <div style={{ paddingBottom: 10 }}>
                  <Textarea
                    placeholder={'Click here to start typing...'}
                    value={newCheckbox}
                    onChange={setNewCheckbox}
                    data-cy="textarea-popup"
                  />
                </div>
                <div style={{ maxWidth: 200, margin: 'auto' }}>
                  <Button
                    type={buttonType.right}
                    iconName={Arrow}
                    label="Save"
                    data-cy="popup-save-button"
                    onClick={() => {
                      addCheckbox();
                      close();
                    }}
                  />
                </div>
              </div>
            );
          }}
        </PopupContainer>
      ) : null}
      <div id="way-add-checkbox" />
    </div>
  );
}

export default MultipleCheckbox;
