import { IModule } from 'redux-dynamic-modules';
import { AuthConfigType } from '../types';
import {
  makeAuthorizationReducer,
  AuthenticateStoreType,
} from './authorization-reducer';
import { alreadyLoggedInAction } from './authorization.actions';
import { makeAuthorizationMiddlewares } from './authorization.middlewares';

export interface AuthorizationeStoreType {
  auth: AuthenticateStoreType;
}

export function getAuthorizationModule({
  baseUri,
  storeKeyPair,
  transport,
  type,
}: AuthConfigType): IModule<AuthorizationeStoreType> {
  const initialActions = [];
  const authorizationReducer = makeAuthorizationReducer({
    baseUri,
    transport,
    type,
  });
  const authorizationMiddlewares = makeAuthorizationMiddlewares(storeKeyPair);
  const isLoggedIn = localStorage.getItem('user-is-logged-in') === 'true';

  if (isLoggedIn) {
    initialActions.push(alreadyLoggedInAction());
  }

  return {
    id: 'authorization',
    reducerMap: {
      auth: authorizationReducer,
    },
    middlewares: [...authorizationMiddlewares],
    initialActions,
  };
}
