import { useQuery, gql } from '@apollo/client';

export const LANGUAGES_SELECTION_QUERY = gql`
  query(
    $brand_classification: String
    $product_classification: String
    $technology: String
    $motor_size: String
    $type_code: String
  ) {
    instruction(
      brand: $brand_classification
      product_classification: $product_classification
      technology: $technology
      motor_size: $motor_size
      type_code: $type_code
    ) {
      instruction_image
      default_languages
    }

    options {
      aviable_languages
    }
  }
`;

export interface LanguageOptions {
  instruction: {
    default_languages?: Array<string>;
  };
  options: {
    aviable_languages: Array<string>;
  };
}

export interface LanguageInterface {
  instruction: {
    instruction_image?: string;
  };
}

export const useLanguageOptions = (options: {
  brand_classification?: string;
  product_classification?: string;
  technology?: string;
  motor_size?: string;
  type_code?: string;
}) => {
  const status = useQuery<LanguageOptions>(LANGUAGES_SELECTION_QUERY, {
    variables: {
      brand_classification: options?.brand_classification
        ?.split('_')
        ?.join(' ')
        ?.toUpperCase(),
      product_classification: options?.product_classification,
      technology: options?.technology,
      motor_size: options?.motor_size,
      type_code: options?.type_code,
    },
  });

  return status;
};

export const useLanguageInterface = (options: {
  brand_classification?: string;
  product_classification?: string;
  technology?: string;
  motor_size?: string;
  type_code?: string;
}) => {
  const status = useQuery<LanguageInterface>(LANGUAGES_SELECTION_QUERY, {
    variables: {
      brand_classification: options?.brand_classification
        ?.split('_')
        ?.join(' ')
        ?.toUpperCase(),
      product_classification: options?.product_classification,
      technology: options?.technology,
      motor_size: options?.motor_size,
      type_code: options?.type_code,
    },
  });
  return status;
};
