import { QueryTuple } from '@apollo/client';
import { PaginationArgs } from '@eypconfig/shared/types';
import { useEffect, useMemo, useState } from 'react';

export const usePaginate = <Type, EntityOfList>(
  tupleQuery: QueryTuple<Type, Partial<PaginationArgs>>,
  offset: number,
  getList?: (result?: Type) => Array<EntityOfList>,
  dependency?: Array<unknown>
) => {
  const [data, setData] = useState<Array<EntityOfList>>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(0);
  const fetchQuery = useMemo(() => tupleQuery?.[0], [tupleQuery]);
  const metadataQuery = useMemo(() => tupleQuery?.[1], [tupleQuery]);

  useEffect(() => {
    setCurrentPage(0);
    setData([]);
    setHasMore(true);
  }, dependency || []);

  useEffect(() => {
    if (currentPage === 0) {
      fetchQuery({ variables: { page: 0, offset } });
    } else {
      metadataQuery
        .fetchMore({ variables: { page: currentPage, offset } })
        .then((resultQuery) => {
          if (resultQuery.data) {
            const list = getList(resultQuery.data);
            setData((current) => [...current, ...list]);
            if (list.length === 0 || list.length < offset) {
              setHasMore(false);
            }
          }
        });
    }
  }, [currentPage]);

  useEffect(() => {
    if (data.length === 0) {
      const list = getList(metadataQuery.data);
      if (list?.length > 0) {
        setData(list);
      }
    }
  }, [data, metadataQuery.data]);

  const loadMore = useMemo(
    () => () => {
      setCurrentPage((prev) => prev + 1);
    },
    []
  );

  const remove = (filterFunction: (entity: EntityOfList) => boolean) => {
    setData((prev) => prev.filter((item) => !filterFunction(item)));
  };

  const result = useMemo(
    () => ({
      data,
      loadMore,
      hasMore,
      remove,
    }),
    [data, loadMore, hasMore, remove]
  );

  return result;
};
