import { useQuery, gql } from '@apollo/client';
import { SelectType } from '@eypconfig/shared/types';

export const MOTOR_SIZES_QUERY = gql`
  query($brand_classification: String, $technology: String) {
    getMotorsSelectOptions(
      propertyToUnique: "size_motor"
      brand_classification: $brand_classification
      technology: $technology
    ) {
      data {
        label
        value
      }
    }
  }
`;

export interface MotorSizesOptions {
  getMotorsSelectOptions: {
    data: Array<SelectType>;
  };
}

export const useSizesMotor = (
  brand_classification?: string,
  technology?: string
) => {
  const status = useQuery<MotorSizesOptions>(MOTOR_SIZES_QUERY, {
    variables: {
      technology,
      brand_classification: brand_classification
        ?.split('_')
        ?.join(' ')
        ?.toUpperCase(),
    },
  });

  return status;
};
