import { gql, useMutation } from '@apollo/client';
import { LabelItem } from '../../get';

export const LABEL_UPDATE_QUERY = gql`
  mutation($labelItem: UpdateLabelItemDto!) {
    updateLabel(labelItem: $labelItem) {
      label_id
    }
  }
`;

export interface UpdateLabelResponse {
  updateLabel: {
    label_id: string;
  };
}

export type UpdateLabelItem = Partial<Omit<LabelItem, 'label_id'>> &
  Pick<LabelItem, 'label_id'>;

export const useUpdateLabel = () => {
  const [mutate, { loading, error }] = useMutation<UpdateLabelResponse>(
    LABEL_UPDATE_QUERY
  );
  return {
    update: ({
      objectTypeId,
      created_at,
      updated_at,
      ...items
    }: UpdateLabelItem) =>
      mutate({
        variables: {
          labelItem: {
            label_id: objectTypeId,
            ...items,
          },
        },
      }).then((result) => !result.errors),
    loading,
    error: error?.message,
  };
};
