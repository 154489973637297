import { useQuery, gql } from '@apollo/client';
import { SelectType } from '@eypconfig/shared/types';

export const MOTOR_CABLE_LENGTH_QUERY = gql`
  query($brand_classification: String!) {
    getMotorsSelectOptions(
      propertyToUnique: "cable_lenght"
      brand_classification: $brand_classification
    ) {
      data {
        label
        value
      }
    }
  }
`;

export interface MotorCableLengthOptions {
  getMotorsSelectOptions: {
    data: Array<SelectType>;
  };
}

export const useMotorCableLengthMotor = (brand_classification: string) => {
  const status = useQuery<MotorCableLengthOptions>(MOTOR_CABLE_LENGTH_QUERY, {
    variables: {
      brand_classification: brand_classification
        .split('_')
        .join(' ')
        .toUpperCase(),
    },
  });

  return status;
};
