import { gql, useMutation } from '@apollo/client';
import { GetOtherAccResultInterface } from '../get';

export const UPDATE_OTHER_ACC_MUTATION = gql`
  mutation($otherAccessoryReference: OtherAccessoryReferenceUpdateInput) {
    updateOtherAccessoryReference(
      otherAccessoryReference: $otherAccessoryReference
    ) {
      other_accessory_reference_id
    }
  }
`;

export interface UpdateOtherAccResultInterface {
  otherAccessoryReference: {
    other_accessory_reference_id: string;
  };
}

export type OtherAccUpdateInterface = Partial<
  Omit<
    GetOtherAccResultInterface['getOtherAccessoryReference'],
    'other_accessory_reference_id'
  >
> & { other_accessory_reference_id: string };

export const useUpdateOtherAcc = () => {
  const [updateOtherAcc, result] = useMutation<UpdateOtherAccResultInterface>(
    UPDATE_OTHER_ACC_MUTATION
  );
  return {
    updateOtherAcc: (otherAccessoryReference: OtherAccUpdateInterface) =>
      updateOtherAcc({
        variables: { otherAccessoryReference },
      }),
    ...result,
  };
};
