import { gql, useLazyQuery } from '@apollo/client';
import { PaginateResultInterface } from '@eypconfig/shared/types';
import { UserRoles } from './user-data.hook';

export const LIST_USERS_QUERY = gql`
  query(
    $page: Int
    $orderByName: OrderByOptionsEnum
    $orderBySurname: OrderByOptionsEnum
    $orderByGroupEntity: OrderByOptionsEnum
    $textSearch: String
  ) {
    getUsers(
      page: $page
      orderByName: $orderByName
      orderBySurname: $orderBySurname
      orderByGroupEntity: $orderByGroupEntity
      offset: 50
      username: $textSearch
      email: $textSearch
      name: $textSearch
      surname: $textSearch
    ) {
      data {
        email
        name
        user_id
        username
        surname
        internal_role
        somfyGroup {
          name
        }
      }
      offset
      page
      count
    }
  }
`;

export interface UserListItemQueryResult {
  email: string;
  name: string;
  user_id: string;
  username: string;
  surname: string;
  internal_role: UserRoles;
  somfyGroup: Array<{ name: string }>;
}

export interface UserListQueryResult {
  getUsers: PaginateResultInterface<UserListItemQueryResult>;
}

export interface OrderByUserManagementList {
  orderByName: 'ASC' | 'DESC';
  orderBySurname: 'ASC' | 'DESC';
  orderByGroupEntity: 'ASC' | 'DESC';
  textSearch?: string;
}

export const useListUsers = (
  orderOptions: Partial<OrderByUserManagementList>
) => {
  const res = useLazyQuery<UserListQueryResult>(LIST_USERS_QUERY, {
    variables: orderOptions,
  });
  return res;
};
