import {
  ApolloClient,
  from,
  fromPromise,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';

export type RefreshTokenFunction = () => Promise<boolean>;

const clientsMap = new Map<string, ApolloClient<NormalizedCacheObject>>();

let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

export const getGraphQlClient = (
  uri: string,
  refreshToken: RefreshTokenFunction,
  useSentry = false
) => {
  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          switch (err.extensions.exception?.status) {
            case 403:
            case 401:
              // eslint-disable-next-line no-case-declarations
              let forward$;

              if (!isRefreshing) {
                isRefreshing = true;
                forward$ = fromPromise(
                  refreshToken()
                    .then((result) => {
                      isRefreshing = false;
                      if (result) {
                        resolvePendingRequests();
                        return true;
                      } else {
                        console.error('Refresh token action result is false');
                        return false;
                      }
                    })
                    .catch((error) => {
                      console.error('Fail to refresh token:', error);
                      pendingRequests = [];
                      isRefreshing = false;
                      return false;
                    })
                ).filter((value) => Boolean(value));
              } else {
                // Will only emit once the Promise is resolved
                forward$ = fromPromise(
                  new Promise<void>((res) => {
                    pendingRequests.push(() => res());
                  })
                );
              }
              return forward$.flatMap(() => forward(operation));
            default:
              if (useSentry) {
                // Add scoped report details and send to Sentry
                Sentry.withScope((scope) => {
                  // Annotate whether failing operation was query/mutation/subscription
                  scope.setTag('kind', err.message);
                  // Log query and variables as extras
                  // (make sure to strip out sensitive data!)
                  scope.setExtra('query', err.path);
                  scope.setExtra('variables', operation.variables);
                  Sentry.captureMessage(
                    `[Query failed]: ${err.message}`,
                    Severity.Error
                  );
                });
              } else {
                console.warn(
                  `[GraphQL error]: Message: ${err.message}, Path: ${
                    err.path
                  }, Variables: ${JSON.stringify(operation.variables, null, 2)}`
                );
              }
          }
        }
      }
      if (networkError) {
        console.warn(
          `[Network error]: ${networkError} ${JSON.stringify(
            operation,
            null,
            4
          )}`
        );
      }
    }
  );

  const httpLink = new HttpLink({
    uri,
  });

  const cache = new InMemoryCache();

  if (!clientsMap.has(uri)) {
    const client = new ApolloClient({
      uri,
      cache,
      credentials: 'same-origin',
      link: from([errorLink, httpLink]),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
        },
      },
    });
    clientsMap.set(uri, client);
  }
  return clientsMap.get(uri);
};
