import { gql, useMutation } from '@apollo/client';

export const DELETE_CUSTOMER_SINGLE_QUERY = gql`
  mutation($customer_id: String!) {
    deleteCustomer(customer_id: $customer_id) {
      customer_id
    }
  }
`;

export const useDeleteCustomer = (objectTypeId: string) => {
  const [doDelete, result] = useMutation(DELETE_CUSTOMER_SINGLE_QUERY, {
    variables: {
      customer_id: objectTypeId,
    },
  });

  return {
    doDelete: () => doDelete().then((result) => !result.errors),
    loading: result.loading,
    error: result.error?.message,
  };
};
