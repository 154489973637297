import { gql, useQuery } from '@apollo/client';

export const IS_REFERENCE_RELATED_TO_MOTOR = gql`
  query($reference_id: String!) {
    result: isReferenceRelatedWithMotor(referenceId: $reference_id)
  }
`;

export interface IsReferenceRelatedToMotorResultInterface {
  result: boolean;
}

export const useIsReferenceRelatedToMotor = (reference_id?: string) => {
  const res = useQuery<IsReferenceRelatedToMotorResultInterface>(
    IS_REFERENCE_RELATED_TO_MOTOR,
    {
      variables: { reference_id },
      skip: !reference_id,
    }
  );
  return res;
};
