import { gql, useQuery } from '@apollo/client';
import { PaginateResultInterface } from '@eypconfig/shared/types';

export const ADAPTERS_SEARCH_BACKOFFICE_QUERY = gql`
  query($page: Int, $offset: Int, $referenceLike: String) {
    result: searchAdapters(
      page: $page
      offset: $offset
      referenceLike: $referenceLike
    ) {
      count
      offset
      data {
        __typename
        objectTypeId: adapter_reference_id
        reference
        designation
      }
    }
  }
`;

export interface AdapterBackofficeListFilter {
  page?: string;
  offset?: string;
  referenceFilter?: string;
}

export interface AdapterBackofficeInterface {
  __typename: 'AdapterReference';
  objectTypeId: string;
  reference: string;
  designation: string;
}

export interface SearchAdaptersBackofficeQueryResultInterface {
  result: PaginateResultInterface<AdapterBackofficeInterface>;
}

export const useSearchAdaptersBackoffice = (
  filter?: AdapterBackofficeListFilter
) => {
  const data = useQuery<SearchAdaptersBackofficeQueryResultInterface | null>(
    ADAPTERS_SEARCH_BACKOFFICE_QUERY,
    {
      variables: {
        referenceLike: filter.referenceFilter,
        page: Number(filter.page),
        offset: Number(filter.offset),
      },
    }
  );

  return data;
};
