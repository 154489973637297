import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

export const userSearch = <SchemaType>(
  keys: Array<keyof SchemaType>,
  def?: Partial<SchemaType>
): Partial<SchemaType> => {
  const history = useHistory();
  const result = useMemo(() => {
    return keys.reduce((acc, key) => {
      const val =
        history?.location?.search?.match(`${key}=([^&]*)`)?.[1] || def?.[key];
      return {
        ...acc,
        [key]: val,
      };
    }, {});
  }, [history.location.search]);
  return result;
};
