import { useQuery, gql } from '@apollo/client';

export enum UserRoles {
  admin = 'admin',
  editor = 'editor',
  user = 'user',
}

export const QUERY_USER_DATA = gql`
  query {
    authUserInfo {
      email
      name
      surname
      user_id
      internal_role
    }
  }
`;

export interface QueryUserDataInterface {
  authUserInfo: {
    email: string;
    name: string;
    surname: string;
    user_id: string;
    internal_role: UserRoles;
  };
}

export const useUserData = () => {
  const data = useQuery<QueryUserDataInterface | null>(QUERY_USER_DATA);

  return data;
};
