import React from 'react';

/* eslint-disable-next-line */
export const IconMenuClose = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="39.7749"
        height="2.65166"
        transform="matrix(0.707104 -0.707109 0.707104 0.707109 0 28.1251)"
        fill="white"
      />
      <rect
        width="39.7749"
        height="2.65166"
        transform="matrix(0.707104 0.707109 -0.707104 0.707109 1.875 0)"
        fill="white"
      />
    </svg>
  );
};

export default IconMenuClose;
