import { gql, useMutation } from '@apollo/client';
import { ControlsSearchFilter } from '@eypconfig/shared/types';
import { GetControlResultInterface } from '../backoffice/get';

export const BULK_UPDATE_CONTROLS_MUTATION = gql`
  mutation (
    $filter: ControlsSearchFilter!
    $controlReference: ControlReferenceBulkUpdateInput!
  ) {
    bulkUpdateControls(filter: $filter, controlReference: $controlReference) {
      count
    }
  }
`;

export type BulkUpdateControlsInterface = Partial<
  Omit<GetControlResultInterface['getControlReference'], 'control_reference_id'>
>;

export interface BulkUpdateControlsResultInterface {
  bulkUpdateControls: {
    count?: number;
  };
}

export const useBulkUpdateControls = () => {
  const [bulkUpdateControls, result] =
    useMutation<BulkUpdateControlsResultInterface>(
      BULK_UPDATE_CONTROLS_MUTATION
    );

  return {
    bulkUpdateControls: (
      filter: ControlsSearchFilter,
      controlReference: BulkUpdateControlsInterface
    ) =>
      bulkUpdateControls({
        variables: {
          filter,
          controlReference,
        },
      }).then(({ data }) => data?.bulkUpdateControls?.count),
    ...result,
  };
};
