import { useQuery, gql } from '@apollo/client';
import { UserRoles } from './user-data.hook';

export const QUERY_USER_DATA_BC = gql`
  query {
    getUserBrandClassificationByRole {
      internal_role
      brand_classification
    }
  }
`;

export interface QueryUserDataInterfaceBC {
  getUserBrandClassificationByRole: {
    internal_role: UserRoles;
    brand_classification?: Array<string> | null;
  };
}

export const useUserDataBrandClassification = () => {
  const data = useQuery<QueryUserDataInterfaceBC | null>(QUERY_USER_DATA_BC);

  return data;
};
