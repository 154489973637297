import { gql, useLazyQuery } from '@apollo/client';
import { PaginateResultInterface } from '@eypconfig/shared/types';

export const GET_ADMIN_CONFIGURATIONS_QUERY = gql`
  query {
    getConfigurations {
      data {
        __typename
        configuration_id
        customer_name
        group_entity
        brand_classification
        createdBy {
          name
          surname
        }
        references {
          __typename
          ... on MotorReference {
            motor_reference_id
            brand
            reference
            technology
            designation
            designation_0
            forecast_annual_volumes
            date_sign
            signed_document_uri
          }
          ... on BracketReference {
            bracket_reference_id
            brand
            reference
            designation
            package_quantity
            date_sign
            signed_document_uri
          }
          ... on ControlReference {
            control_reference_id
            brand
            reference
            technology
            designation
            date_sign
            signed_document_uri
            package_quantity
          }
          ... on AdapterReference {
            adapter_reference_id
            brand
            reference
            designation
            date_sign
            signed_document_uri
            packaging_quantity
          }
          ... on OtherAccessoryReference {
            other_accessory_reference_id
            brand
            reference
            designation
            date_sign
            signed_document_uri
            packaging_quantity
          }
        }
      }
    }
  }
`;

export const GET_YOUR_CONFIGURATIONS_QUERY = gql`
  query {
    getAuthUserConfiguration {
      data {
        __typename
        configuration_id
        customer_name
        group_entity
        brand_classification
        createdBy {
          name
          surname
        }
        references {
          __typename
          ... on MotorReference {
            motor_reference_id
            brand
            reference
            technology
            designation
            designation_0
            forecast_annual_volumes
            date_sign
            signed_document_uri
          }
          ... on BracketReference {
            bracket_reference_id
            brand
            reference
            designation
            package_quantity
            date_sign
            signed_document_uri
          }
          ... on ControlReference {
            control_reference_id
            brand
            reference
            technology
            designation
            date_sign
            signed_document_uri
            package_quantity
          }
          ... on AdapterReference {
            adapter_reference_id
            brand
            reference
            designation
            date_sign
            signed_document_uri
            packaging_quantity
          }
          ... on OtherAccessoryReference {
            other_accessory_reference_id
            brand
            reference
            designation
            date_sign
            signed_document_uri
            packaging_quantity
          }
        }
      }
    }
  }
`;

export interface ConfigurationMotorReference {
  __typename: 'MotorReference';
  motor_reference_id: string;
  reference?: string;
  brand?: string;
  technology: string;
  designation?: string;
  designation_0?: string;
  forecast_annual_volumes?: string;
  date_sign?: string;
  signed_document_uri?: string;
}

export interface ConfigurationBracketReference {
  __typename: 'BracketReference';
  bracket_reference_id: string;
  reference?: string;
  brand?: string;
  designation?: string;
  package_quantity?: string;
  date_sign?: string;
  signed_document_uri?: string;
}

export interface ConfigurationControlReference {
  __typename: 'ControlReference';
  control_reference_id: string;
  reference?: string;
  brand?: string;
  designation?: string;
  technology?: string;
  date_sign?: string;
  signed_document_uri?: string;
  package_quantity?: string;
}

export interface ConfigurationAdapterReference {
  __typename: 'AdapterReference';
  adapter_reference_id: string;
  reference?: string;
  brand?: string;
  designation?: string;
  date_sign?: string;
  signed_document_uri?: string;
  packaging_quantity?: string;
}

export interface ConfigurationOtherAccessoryReference {
  __typename: 'OtherAccessoryReference';
  other_accessory_reference_id: string;
  reference?: string;
  brand?: string;
  designation?: string;
  date_sign?: string;
  signed_document_uri?: string;
  packaging_quantity?: string;
}

export interface ConfigurationResultInterface {
  __typename: 'ConfigurationDto';
  configuration_id: string;
  customer_name?: string;
  group_entity?: string;
  brand_classification: string;
  createdBy: {
    name: string;
    surname: string;
  };
  references: Array<
    | ConfigurationMotorReference
    | ConfigurationBracketReference
    | ConfigurationControlReference
    | ConfigurationOtherAccessoryReference
    | ConfigurationAdapterReference
  >;
}

export interface GetConfigurationsInterface {
  getConfigurations?: PaginateResultInterface<ConfigurationResultInterface>;
  getAuthUserConfiguration?: PaginateResultInterface<ConfigurationResultInterface>;
}

export type DataConfigurationResult = Omit<
  ConfigurationResultInterface,
  'references' | '__typename'
> & { object_id: string } & (
    | ConfigurationMotorReference
    | ConfigurationBracketReference
    | ConfigurationControlReference
    | ConfigurationOtherAccessoryReference
    | ConfigurationAdapterReference
  );

function extractReferencesFromConfigurations(
  configurations: Array<ConfigurationResultInterface>
) {
  return configurations.reduce<Array<DataConfigurationResult>>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (acc, { references, __typename, ...config }) => [
      ...acc,
      ...references.map((ref) => {
        let object_id = null;
        if (ref.__typename === 'AdapterReference') {
          object_id = ref.adapter_reference_id;
        }
        if (ref.__typename === 'BracketReference') {
          object_id = ref.bracket_reference_id;
        }
        if (ref.__typename === 'ControlReference') {
          object_id = ref.control_reference_id;
        }
        if (ref.__typename === 'MotorReference') {
          object_id = ref.motor_reference_id;
        }
        if (ref.__typename === 'OtherAccessoryReference') {
          object_id = ref.other_accessory_reference_id;
        }
        return { ...ref, ...config, object_id };
      }),
    ],
    []
  );
}

export const useAllConfigurations = () => {
  const [fetchAllConfigurations, result] =
    useLazyQuery<GetConfigurationsInterface>(GET_ADMIN_CONFIGURATIONS_QUERY);
  return {
    fetchAllConfigurations,
    data: extractReferencesFromConfigurations(
      result.data?.getConfigurations.data || []
    ),
    loading: result.loading,
  };
};

export const useUserConfigurations = () => {
  const [fetchUserConfigurations, result] =
    useLazyQuery<GetConfigurationsInterface>(GET_YOUR_CONFIGURATIONS_QUERY);
  return {
    fetchUserConfigurations,
    data: extractReferencesFromConfigurations(
      result.data?.getAuthUserConfiguration.data || []
    ),
    loading: result.loading,
  };
};
