import { gql, useMutation } from '@apollo/client';
import { NominalVoltageItem } from '../../get';

export const CREATE_NOMINAL_VOLTAGE_QUERY = gql`
  mutation($nominalVoltageItem: CreateNominalVoltageDto!) {
    createNominalVoltage(nominalVoltageItem: $nominalVoltageItem) {
      nominal_voltage_id
    }
  }
`;

export interface CreateNominalVoltageResult {
  createNominalVoltage: {
    nominal_voltage_id: string;
  };
}

export type CreateNominalVoltageItem = Omit<
  NominalVoltageItem,
  | 'nominal_voltage_id'
  | '__typename'
  | 'created_at'
  | 'updated_at'
  | 'objectTypeId'
>;

export const useCreateNominalVoltage = () => {
  const [create, { loading, error }] = useMutation<CreateNominalVoltageResult>(
    CREATE_NOMINAL_VOLTAGE_QUERY
  );
  return {
    create: (nominalVoltageItem: CreateNominalVoltageItem) =>
      create({ variables: { nominalVoltageItem } }).then((result) => {
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
        return result.data?.createNominalVoltage.nominal_voltage_id;
      }),
    error: error?.message,
    loading,
  };
};
