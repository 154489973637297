import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  BracketsSearchFilter,
  PaginateResultInterface,
  PaginationArgs,
} from '@eypconfig/shared/types';

export const BRACKETS_SEARCH_CATALOGUE_QUERY = gql`
  query(
    $page: Int
    $reference: [String!]
    $brand: [String!]
    $brand_classification: [String!]
    $customer: [String!]
    $somfy_match: [String!]
    $technology: [String!]
    $motor_head: [String!]
    $nominal_torque: [String!]
    $motor_size: [String!]
    $package_type: [String!]
    $range_for_configuration: Boolean
  ) {
    searchBrackets(
      page: $page
      reference: $reference
      brand: $brand
      brand_classification: $brand_classification
      customer: $customer
      somfy_match: $somfy_match
      technology: $technology
      motor_size: $motor_size
      motor_head: $motor_head
      nominal_torque: $nominal_torque
      package_type: $package_type
      range_for_configuration: $range_for_configuration
    ) {
      count
      offset
      data {
        __typename
        bracket_reference_id
        reference
        designation
        bracket_image
        motor_size
        motor_type
        somfy_match
        applications
        motor_head
        package_type
        three_d_video
        catalogue
        product_technical_sheet
        ce_declaration
        vde_certification
        nf_certification
        compare_declaration
        photo_high_resolution
        photo_low_resolution
        dimensional_drawings
        mini_programming_guide
        video_installation
        nominal_torque
        made_in
        package_brand
        nr_of_brackets
      }
    }
  }
`;

export const BRACKETS_COUNT_QUERY = gql`
  query {
    searchBrackets {
      count
    }
  }
`;

export interface BracketCatalogueInterface {
  __typename: 'BracketReference';
  bracket_reference_id: string;
  reference: string;
  designation: string;
  bracket_image?: string;
  motor_size?: string;
  motor_type?: Array<string>;
  somfy_match?: string;
  applications?: string;
  motor_head?: string;
  package_type?: string;
  three_d_video?: string;
  catalogue?: string;
  product_technical_sheet?: string;
  ce_declaration?: string;
  vde_certification?: string;
  nf_certification?: string;
  compare_declaration?: string;
  photo_high_resolution?: string;
  photo_low_resolution?: string;
  dimensional_drawings?: string;
  mini_programming_guide?: string;
  video_installation?: string;
  nominal_torque?: string;
  made_in?: string;
  package_brand?: string;
  nr_of_brackets?: string;
}

export interface SearchBracketsCatalogQueryResultInterface {
  searchBrackets: PaginateResultInterface<BracketCatalogueInterface>;
}

export const useSearchBracketsCatalogue = (
  filter?: BracketsSearchFilter & PaginationArgs
) => {
  const data = useQuery<SearchBracketsCatalogQueryResultInterface | null>(
    BRACKETS_SEARCH_CATALOGUE_QUERY,
    {
      variables: filter,
    }
  );

  return data;
};

export const useLazySearchBracketsCatalogue = (
  filter?: BracketsSearchFilter & PaginationArgs
) => {
  const data = useLazyQuery<SearchBracketsCatalogQueryResultInterface | null>(
    BRACKETS_SEARCH_CATALOGUE_QUERY,
    {
      variables: filter,
    }
  );

  return data;
};

export interface CountBracketsCatalogQueryResultInterface {
  searchBrackets: {
    count: number;
  };
}

export const useLazyCountBrackets = () => {
  const data = useLazyQuery<CountBracketsCatalogQueryResultInterface | null>(
    BRACKETS_COUNT_QUERY
  );

  return data;
};
