import { gql, useLazyQuery } from '@apollo/client';
import { UserRoles } from './user-data.hook';

export const GET_QUERY_USER = gql`
  query ($user_id: String!) {
    userInfo(user_id: $user_id) {
      user_id
      email
      username
      name
      surname
      internal_role
      somfyGroup {
        somfy_group_id
        name
      }
      customers {
        customer_id
        name
      }
      brand_classification
    }
  }
`;

export interface GetUserEntityInterface {
  __typename: 'User';
  user_id: string;
  email: string;
  username: string;
  name: string;
  surname: string;
  internal_role: UserRoles;
  customers: Array<{
    customer_id: string;
    name: string;
  }>;
  somfyGroup: Array<{
    somfy_group_id: string;
    name: string;
  }>;
  brand_classification: Array<string>;
}

export interface QueryGetUserInterface {
  userInfo: GetUserEntityInterface;
}

export const useGetUser = () => {
  const data = useLazyQuery<QueryGetUserInterface | null>(GET_QUERY_USER);
  return data;
};
