import { gql, useQuery } from '@apollo/client';

export const SOMFY_GROUPS_LIST = gql`
  query($name: String, $page: Int, $offset: Int) {
    result: getSomfyGroups(name: $name, page: $page, offset: $offset) {
      options: data {
        label
        value: name
      }
    }
  }
`;

export interface SomfyGroupEntityResult {
  result: {
    options: Array<{
      label: string;
      value: string;
    }>;
  };
}

export const allSomfyGroups = () => {
  const query = useQuery<SomfyGroupEntityResult>(SOMFY_GROUPS_LIST);
  return query;
};
