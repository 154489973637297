import { gql, useQuery } from '@apollo/client';
import { PaginateResultInterface } from '@eypconfig/shared/types';

export const LABEL_PRODUCT_CLASSIFICATION_OPTIONS = gql`
  query {
    getLabelsSelectOptions(propertyToUnique: "product_classification") {
      data {
        label
        value
      }
    }
  }
`;

export interface LabelOptionResult {
  getLabelsSelectOptions: PaginateResultInterface<{
    label: string;
    value: string;
  }>;
}

export const useLabelProductClassificationOptions = () => {
  return useQuery<LabelOptionResult>(LABEL_PRODUCT_CLASSIFICATION_OPTIONS);
};

export const LABEL_TECHNOLOGY_OPTIONS = gql`
  query {
    getLabelsSelectOptions(propertyToUnique: "technology") {
      data {
        label
        value
      }
    }
  }
`;

export const useLabelTechnologyOptions = () => {
  return useQuery<LabelOptionResult>(LABEL_TECHNOLOGY_OPTIONS);
};

export const LABEL_TYPE_CODE_OPTIONS = gql`
  query {
    getLabelsSelectOptions(propertyToUnique: "type_code") {
      data {
        label
        value
      }
    }
  }
`;

export const useLabelTypeCodeOptions = () => {
  return useQuery<LabelOptionResult>(LABEL_TYPE_CODE_OPTIONS);
};
