import { gql, useMutation } from '@apollo/client';
import { CableConnectorItem, CableLengthItem, CableTypeItem } from '../../get';

// *** CABLE CONNECTION SECTION ***

export const CREATE_CABLE_CONNECTOR_QUERY = gql`
  mutation($cableConnectorItem: CreateCableConnectorDto!) {
    createCableConnector(cableConnectorItem: $cableConnectorItem) {
      cable_connector_id
    }
  }
`;

export type CreateCableConnectorItem = Omit<
  CableConnectorItem,
  | 'cable_connector_id'
  | 'objectTypeId'
  | 'created_at'
  | 'updated_at'
  | '__typename'
>;

export interface CreateCableConnectorResult {
  createCableConnector: {
    cable_connector_id: string;
  };
}

export const useCreateCableConnector = () => {
  const [create, { loading, error }] = useMutation<CreateCableConnectorResult>(
    CREATE_CABLE_CONNECTOR_QUERY
  );
  return {
    create: (cableConnectorItem: CreateCableConnectorItem) =>
      create({ variables: { cableConnectorItem } }).then((result) => {
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
        return result.data?.createCableConnector.cable_connector_id;
      }),
    loading,
    error: error?.message,
  };
};

// *** CABLE LENGTH SECTION ***

export const CREATE_CABLE_LENGTH_QUERY = gql`
  mutation($cableLengthItem: CreateCableLengthDto!) {
    createCableLength(cableLengthItem: $cableLengthItem) {
      cable_length_id
    }
  }
`;

export type CreateCableLengthItem = Omit<
  CableLengthItem,
  | 'cable_length_id'
  | 'objectTypeId'
  | 'created_at'
  | 'updated_at'
  | '__typename'
>;

export interface CreateCableLengthResult {
  createCableLength: {
    cable_length_id: string;
  };
}

export const useCreateCableLength = () => {
  const [create, { loading, error }] = useMutation<CreateCableLengthResult>(
    CREATE_CABLE_LENGTH_QUERY
  );
  return {
    create: (cableLengthItem: CreateCableLengthItem) =>
      create({ variables: { cableLengthItem } }).then((result) => {
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
        return result.data?.createCableLength.cable_length_id;
      }),
    loading,
    error: error?.message,
  };
};

// *** CABLE TYPE SECTION ***

export const CREATE_CABLE_TYPE_QUERY = gql`
  mutation($cableTypeItem: CreateCableTypeDto!) {
    createCableType(cableTypeItem: $cableTypeItem) {
      cable_type_id
    }
  }
`;

export type CreateCableTypeItem = Omit<
  CableTypeItem,
  'cable_type_id' | 'objectTypeId' | 'created_at' | 'updated_at' | '__typename'
>;

export interface CreateCableTypeResult {
  createCableType: {
    cable_type_id: string;
  };
}

export const useCreateCableType = () => {
  const [create, { loading, error }] = useMutation<CreateCableTypeResult>(
    CREATE_CABLE_TYPE_QUERY
  );
  return {
    create: (cableTypeItem: CreateCableTypeItem) =>
      create({ variables: { cableTypeItem } }).then((result) => {
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
        return result.data?.createCableType.cable_type_id;
      }),
    loading,
    error: error?.message,
  };
};
