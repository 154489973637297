import { PropsWithChildren } from 'react';
import { UserRoles } from '@eypconfig/client/data-access';
import { useIsUserRole } from '../hooks';

export interface VisibleOnlyForRoleProps {
  roles: UserRoles | Array<UserRoles>;
}

export function VisibleOnlyForRole(
  props: PropsWithChildren<VisibleOnlyForRoleProps>
) {
  const { isRole } = useIsUserRole(props.roles);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isRole ? <>{props.children}</> : null;
}
