import React, { useMemo } from 'react';

import './column-layout.scss';

export interface ColumnLayoutProps {
  nColumns: number;
  noLine?: boolean;
  columnSizes?: Array<number>;
  children:
    | React.ReactNode
    | Array<React.ReactNode>
    | JSX.Element
    | Array<JSX.Element>;
  isFormStep?: boolean;
}

export function ColumnLayout(props: ColumnLayoutProps) {
  const columns: Array<Array<React.ReactNode>> = useMemo(() => {
    if (Array.isArray(props.children)) {
      const result = [];
      const numberOfElementInColumn = props.children.length / props.nColumns;
      for (let index = 0; index < props.nColumns; index++) {
        const startIndex = index * numberOfElementInColumn;
        const endIndex = startIndex + numberOfElementInColumn;
        result.push(props.children.slice(startIndex, endIndex));
      }
      return result;
    } else {
      return [[props.children]];
    }
  }, [props.children, props.nColumns]);

  return (
    <div
      className={`way-column-layout ${
        props.isFormStep ? 'way-column-layout--form' : ''
      } `}
    >
      {columns.map((colContent, index) => (
        <div
          className={`way-column-layout__column-${props.nColumns} ${
            props.noLine
              ? 'way-column-layout__noLine'
              : 'way-column-layout__column-line'
          } ${
            props.columnSizes
              ? `way-column-layout__flex-size-${props.columnSizes[index]}`
              : ''
          }`}
          key={index}
        >
          {colContent}
        </div>
      ))}
    </div>
  );
}

export default ColumnLayout;
