import React from 'react';
import { motion, SVGMotionProps } from 'framer-motion';

export type IconArrowFullProps = SVGMotionProps<SVGSVGElement> & {
  orientation: 'left' | 'right' | 'top' | 'bottom';
  bgColor?: string;
};

function calculateRotateForOrientation(
  orientation: IconArrowFullProps['orientation']
) {
  if (orientation === 'right') {
    return 180;
  }
  if (orientation === 'top') {
    return 90;
  }
  if (orientation === 'bottom') {
    return -90;
  }

  return 0;
}

export const IconArrowFull: React.FC<IconArrowFullProps> = ({
  bgColor,
  orientation,
  style,
}: IconArrowFullProps) => {
  const circleCol = bgColor || '#72C456';
  return (
    <motion.svg
      animate={{
        rotate: calculateRotateForOrientation(orientation),
      }}
      transition={{ duration: 0.8, stiffness: 50 }}
      width="20"
      height="20"
      style={style}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill={circleCol} />
      <path
        d="M14 10.0001H6"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6L6 10L10 14"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </motion.svg>
  );
};
