import { gql, useQuery } from '@apollo/client';

export const GET_CONTROL_REFERENCE_QUERY = gql`
  query ($control_reference_id: String!) {
    getControlReference(control_reference_id: $control_reference_id) {
      control_reference_id
      reference
      brand_classification
      brand_classification_image
      somfy_group_entity
      range_for_configuration
      brand
      brand_image
      made_in
      technology
      control_type
      somfy_match
      customer
      short_designation
      designation
      designation_1
      product_image
      unit_reference
      complete_product_image
      technology_image
      cover_color
      cover_hex_color_code
      power_supply_or_battery
      number_of_channels
      radio_compatability
      memory_capacity
      insulation_class
      dimension_image
      complete_dimension_image
      electrical_safety
      health_test
      electromagnetic_compatibility_1
      electromagnetic_compatibility_2
      electromagnetic_compatibility_3
      electromagnetic_compatibility_4
      electromagnetic_compatibility_5
      electromagnetic_compatibility_6
      vertical_screen
      vertical_screen_image
      zebra_screen
      zebra_screen_image
      rolling_shutter
      rolling_shutter_image
      lateral_arms_awning
      lateral_arms_awning_image
      awning_with_arms
      awning_with_arms_image
      casette_awning
      casette_awning_image
      weigth
      barcode
      ean_code
      control_label_image
      multi_package_label_image
      brand_address
      address
      package_dimensions_image
      package_brand
      package_cardboard_print_finiture
      package_graphic_layout
      package_graphic_layout_image
      multi_package_type
      multi_package_brand
      multi_package_dimensions_image
      multi_package_cardboard_print_finiture
      multi_package_lenght
      multi_package_width
      multi_package_height
      multi_package_weight
      pallet_type
      pallet_empty_dimensions_image
      pallet_nr_pcs_layers
      pallet_nr_packages
      pallet_dimensions_all
      pallet_dimensions_image
      forecast_annual_volumes
      ref_for_customer
      new_customer
      pallet_lenght
      pallet_width
      pallet_height
      pallet_weight
      image_without_graphics_on_cover
      image_with_graphics_on_cover
      graphic_on_the_cover_image
      dimension_drawing_image
      transmission_frequence
      range
      remote_control_class
      protection_rate
      protection_degree
      working_temperature
      storage_temperature
      battery_type
      package_box
      package_type
      package_quantity
      package_length
      package_width
      package_height
      package_weight
      package_image
      package_label_image
      number_of_controls
      number_of_wall_support
      number_of_instruction_leaflets
      instructions_logo_address
      instructions_image
      instruction_languages
      graphics_on_cover
      three_d_video
      product_technical_sheet
      warning_and_instructions_leaflets
      ce_declaration
      vde_certification
      nf_certification
      compare_declaration
      photo_high_resolution
      photo_low_resolution
      dimensional_drawings
      mini_programming_guide
      video_installation
      product_identification_file
      catalogue
      certification_ce
      certification_vde
      certification_nf
      certification_cmim
      certification_pai
      certification_saber
      order
      signed_document_uri
      is_draft
      deleted_at
    }
  }
`;

export interface GetControlResultInterface {
  getControlReference: {
    __typename: string;
    control_reference_id: string;
    reference?: string;
    brand_classification?: Array<string>;
    brand_classification_image?: string;
    somfy_group_entity?: string;
    range_for_configuration: boolean;
    brand?: string;
    brand_image?: string;
    unit_reference?: string;
    complete_product_image?: string;
    technology_image?: string;
    cover_color?: string;
    cover_hex_color_code?: string;
    power_supply_or_battery?: string;
    number_of_channels?: string;
    radio_compatability?: string;
    memory_capacity?: string;
    insulation_class?: string;
    dimension_image?: string;
    complete_dimension_image?: string;
    electrical_safety?: string;
    health_test?: string;
    electromagnetic_compatibility_1?: string;
    electromagnetic_compatibility_2?: string;
    electromagnetic_compatibility_3?: string;
    electromagnetic_compatibility_4?: string;
    electromagnetic_compatibility_5?: string;
    electromagnetic_compatibility_6?: string;
    vertical_screen: boolean;
    vertical_screen_image?: string;
    zebra_screen: boolean;
    zebra_screen_image?: string;
    rolling_shutter: boolean;
    rolling_shutter_image?: string;
    lateral_arms_awning: boolean;
    lateral_arms_awning_image?: string;
    awning_with_arms: boolean;
    awning_with_arms_image?: string;
    casette_awning: boolean;
    casette_awning_image?: string;
    weigth?: string;
    barcode?: string;
    ean_code?: string;
    control_label_image?: string;
    multi_package_label_image?: string;
    brand_address?: string;
    address?: string;
    package_dimensions_image?: string;
    package_brand?: string;
    package_cardboard_print_finiture?: string;
    package_graphic_layout?: string;
    package_graphic_layout_image?: string;
    multi_package_type?: string;
    multi_package_brand?: string;
    multi_package_dimensions_image?: string;
    multi_package_cardboard_print_finiture?: string;
    multi_package_lenght?: string;
    multi_package_width?: string;
    multi_package_height?: string;
    multi_package_weight?: string;
    pallet_type?: string;
    pallet_empty_dimensions_all?: string;
    pallet_empty_dimensions_image?: string;
    pallet_nr_pcs_layers?: string;
    pallet_nr_packages?: string;
    pallet_dimensions_all?: string;
    pallet_dimensions_image?: string;
    forecast_annual_volumes?: string;
    ref_for_customer?: string;
    new_customer?: string;
    pallet_lenght?: string;
    pallet_width?: string;
    pallet_height?: string;
    pallet_weight?: string;
    made_in?: string;
    technology: string;
    control_type: string;
    somfy_match?: string;
    customer?: Array<string>;
    short_designation?: string;
    designation?: string;
    designation_1?: string;
    product_image?: string;
    image_without_graphics_on_cover?: string;
    image_with_graphics_on_cover?: string;
    graphic_on_the_cover_image?: string;
    dimension_drawing_image?: string;
    transmission_frequence?: string;
    range?: string;
    remote_control_class?: string;
    protection_rate?: string;
    protection_degree?: string;
    working_temperature?: string;
    storage_temperature?: string;
    battery_type?: string;
    package_box?: string;
    package_type?: string;
    package_quantity?: string;
    package_length?: string;
    package_width?: string;
    package_height?: string;
    package_weight?: string;
    package_image?: string;
    package_label_image?: string;
    number_of_controls?: string;
    number_of_wall_support?: string;
    number_of_instruction_leaflets?: string;
    instructions_logo_address?: string;
    instructions_image?: string;
    instruction_languages: Array<string>;
    graphics_on_cover: boolean;
    three_d_video?: string;
    product_technical_sheet?: string;
    warning_and_instructions_leaflets?: string;
    ce_declaration?: string;
    vde_certification?: string;
    nf_certification?: string;
    compare_declaration?: string;
    photo_high_resolution?: string;
    photo_low_resolution?: string;
    dimensional_drawings?: string;
    mini_programming_guide?: string;
    video_installation?: string;
    product_identification_file?: string;
    signed_document_uri?: string;
    catalogue?: string;
    certification_ce: boolean;
    certification_vde: boolean;
    certification_nf: boolean;
    certification_cmim: boolean;
    certification_pai: boolean;
    certification_saber: boolean;
    order: number;
    is_draft: boolean;
    deleted_at: Date;
  };
}

export const useGetControlReference = (control_reference_id: string) => {
  const result = useQuery<GetControlResultInterface>(
    GET_CONTROL_REFERENCE_QUERY,
    {
      variables: { control_reference_id },
    }
  );
  return result;
};
