import { useQuery, gql } from '@apollo/client';
import { SelectType } from '@eypconfig/shared/types';

export const MOTOR_TECHNOLOGIES_QUERY = gql`
  query ($brand_classification: String, $range_for_configuration: Boolean) {
    getMotorsSelectOptions(
      propertyToUnique: "technology"
      lableTemplate: "{{ technology }}---{{ technology_image }}"
      brand_classification: $brand_classification
      range_for_configuration: $range_for_configuration
    ) {
      data {
        label
        value
      }
    }
  }
`;

export interface MotorsTechnologiesOptions {
  getMotorsSelectOptions: {
    data: Array<SelectType>;
  };
}

export const useTechnologiesMotor = (
  brand_classification: string,
  range_for_configuration = false
) => {
  const status = useQuery<MotorsTechnologiesOptions>(MOTOR_TECHNOLOGIES_QUERY, {
    variables: {
      brand_classification: brand_classification
        .split('_')
        .join(' ')
        .toUpperCase(),
      range_for_configuration,
    },
  });

  return status;
};
