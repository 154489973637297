import { gql, useMutation } from '@apollo/client';
import { GetBracketResultInterface } from '../get';

export const UPDATE_FULL_BRACKET_MUTATION = gql`
  mutation($bracketReference: BracketReferenceUpdateInput) {
    updateBracketReference(bracketReference: $bracketReference) {
      bracket_reference_id
    }
  }
`;

export interface UpdateBracketResultInterface {
  updateBracketReference: {
    bracket_reference_id: string;
  };
}

export type BracketUpdateInterface = Partial<
  Omit<GetBracketResultInterface['getBracketReference'], 'bracket_reference_id'>
> & { bracket_reference_id: string };

export const useUpdateBracket = () => {
  const [updateBracket, result] = useMutation<UpdateBracketResultInterface>(
    UPDATE_FULL_BRACKET_MUTATION
  );
  return {
    updateBracket: (bracketReference: BracketUpdateInterface) =>
      updateBracket({
        variables: {
          bracketReference,
        },
      }),
    ...result,
  };
};
