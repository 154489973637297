import { gql, useQuery } from '@apollo/client';

export const GET_BRACKET_REFERENCE_QUERY = gql`
  query ($bracket_reference_id: String!) {
    getBracketReference(bracket_reference_id: $bracket_reference_id) {
      bracket_reference_id
      reference
      unit_reference
      brand_classification
      brand_classification_image
      range_for_configuration
      brand
      brand_image
      customer
      ref_customer
      new_customer
      designation
      bracket_image
      somfy_match
      motor_size
      motor_type
      motor_head
      motor_head_image
      package_quantity
      package_lenght
      package_height
      package_width
      package_weight
      unit_weight
      package_image
      package_label_image
      package_type
      vertical_screen_image
      vertical_screen
      zebra_screen
      rolling_shutter
      lateral_arms_awning
      awning_with_arms
      cassette_awning
      somfy_group_entity
      zebra_screen_image
      rolling_shutter_image
      lateral_arms_awning_image
      awning_with_arms_image
      cassette_awning_image
      package_cardboard
      nr_of_brackets
      multi_pack_type
      multi_pack_cardboard
      product_type
      made_in
      nominal_torque
      barcode
      ean
      package_brand
      package_dimensions
      multi_pack_brand
      multi_pack_dimensions
      multi_pack_lenght
      multi_pack_width
      multi_pack_height
      multi_pack_weight
      multi_pack_label_image
      pallet_type
      pallet_empty_dimensions
      pallet_empty_dimensions_image
      pallet_nr_pcs
      pallet_nr_packages
      pallet_dimensions
      pallet_dimensions_image
      pallet_lenght
      pallet_width
      pallet_height
      pallet_weight
      package_graphic_layout
      package_graphic_layout_image
      multi_pack_dimensions_image
      packaging_quantity
      three_d_video
      product_technical_sheet
      warnings_leaflet
      instruction_leaflet
      ce_declaration
      vde_certification
      nf_certification
      compare_declaration
      photo_high_resolution
      photo_low_resolution
      dimensional_drawings
      mini_programming_guide
      signed_document_uri
      video_installation
      catalogue
      applications
      forecast_annual_volumes
      order
      is_draft
      created_at
      updated_at
      deleted_at
    }
  }
`;

export interface GetBracketResultInterface {
  getBracketReference: {
    __typename: string;
    bracket_reference_id: string;
    reference?: string;
    unit_reference?: string;
    brand_classification: Array<string>;
    brand_classification_image?: string;
    range_for_configuration: boolean;
    brand?: string;
    brand_image?: string;
    ref_customer?: string;
    new_customer?: string;
    customer?: Array<string>;
    designation?: string;
    bracket_image?: string;
    somfy_match?: string;
    motor_size: string;
    motor_type: Array<string>;
    motor_head: string;
    motor_head_image?: string;
    nominal_torque?: string;
    package_quantity?: string;
    package_lenght?: string;
    package_height?: string;
    package_width?: string;
    package_weight?: string;
    unit_weight?: string;
    package_image?: string;
    package_label_image?: string;
    package_type?: string;
    applications?: string;
    packaging_quantity?: string;
    vertical_screen_image?: string;
    vertical_screen?: boolean;
    zebra_screen?: boolean;
    rolling_shutter?: boolean;
    lateral_arms_awning?: boolean;
    awning_with_arms?: boolean;
    cassette_awning?: boolean;
    somfy_group_entity?: string;
    zebra_screen_image?: string;
    rolling_shutter_image?: string;
    lateral_arms_awning_image?: string;
    awning_with_arms_image?: string;
    cassette_awning_image?: string;
    package_cardboard?: string;
    nr_of_brackets?: string;
    multi_pack_type?: string;
    multi_pack_cardboard?: string;
    product_type?: string;
    made_in?: string;
    barcode?: string;
    ean?: string;
    package_brand?: string;
    package_dimensions?: string;
    multi_pack_brand?: string;
    multi_pack_dimensions?: string;
    multi_pack_lenght?: string;
    multi_pack_width?: string;
    multi_pack_height?: string;
    multi_pack_weight?: string;
    multi_pack_label_image?: string;
    pallet_type?: string;
    pallet_empty_dimensions?: string;
    pallet_empty_dimensions_image?: string;
    pallet_nr_pcs?: string;
    pallet_nr_packages?: string;
    pallet_dimensions?: string;
    pallet_dimensions_image?: string;
    pallet_lenght?: string;
    pallet_width?: string;
    pallet_height?: string;
    pallet_weight?: string;
    package_graphic_layout?: string;
    package_graphic_layout_image?: string;
    multi_pack_dimensions_image?: string;
    three_d_video?: string;
    product_technical_sheet?: string;
    warnings_leaflet?: string;
    instruction_leaflet?: string;
    ce_declaration?: string;
    vde_certification?: string;
    nf_certification?: string;
    compare_declaration?: string;
    photo_high_resolution?: string;
    photo_low_resolution?: string;
    dimensional_drawings?: string;
    mini_programming_guide?: string;
    video_installation?: string;
    signed_document_uri?: string;
    catalogue?: string;
    forecast_annual_volumes?: string;
    order: number;
    is_draft: boolean;
    created_at: Date;
    updated_at: Date;
    deleted_at?: Date;
  };
}

export const useGetBracketReference = (bracket_reference_id: string) => {
  const result = useQuery<GetBracketResultInterface>(
    GET_BRACKET_REFERENCE_QUERY,
    {
      variables: { bracket_reference_id },
    }
  );
  return result;
};
