import { gql, useMutation } from '@apollo/client';
import { TubeColorItem } from '../../get/options/tube-color-reference.hook';

export const CREATE_TUBE_COLOR_QUERY = gql`
  mutation($tubeColorItem: CreateTubeColorItemDto!) {
    createTubeColor(tubeColorItem: $tubeColorItem) {
      tube_color_id
    }
  }
`;

export interface CreateTubeColorResult {
  createTubeColor: {
    tube_color_id: string;
  };
}

export type TubeColorCreateItem = Omit<
  TubeColorItem,
  'tube_color_id' | '__typename' | 'created_at' | 'updated_at' | 'objectTypeId'
>;

export const useCreateTubeColor = () => {
  const [create, { error, loading }] = useMutation<CreateTubeColorResult>(
    CREATE_TUBE_COLOR_QUERY
  );
  return {
    create: (tubeColorItem: TubeColorCreateItem) =>
      create({ variables: { tubeColorItem } }).then((result) => {
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
        return result.data?.createTubeColor.tube_color_id;
      }),
    error: error?.message,
    loading,
  };
};
