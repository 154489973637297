import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  loginAction,
  LogoutAction,
  RefreshTokenAction,
  successLoginAction,
} from '../redux/authorization.actions';
import { AuthorizationeStoreType } from '../redux/authorization-redux.module';
import { useMemo } from 'react';
import { ValidationErrorDto } from '../types';

export const useLoginLogout = () => {
  const dispatch = useDispatch();

  const loading = useSelector<AuthorizationeStoreType, boolean>(
    (store) => store.auth.loading
  );

  const errors = useSelector<AuthorizationeStoreType, ValidationErrorDto>(
    (store) => store.auth.errors
  );

  const login = useMemo(
    () => (username: string, password: string, rememberMe?: boolean) => {
      dispatch(loginAction(username, password, rememberMe));
    },
    [dispatch]
  );

  const logout = useMemo(
    () => () => {
      dispatch(LogoutAction());
    },
    [dispatch]
  );

  const passwordlessLogin = useMemo(
    () => () => {
      dispatch(successLoginAction({ rememberMe: true }));
    },
    [dispatch]
  );

  return {
    loading,
    login,
    passwordlessLogin,
    logout,
    errors,
  };
};

export const useIsLoggedIn = () => {
  const isLoggedIn = useSelector<AuthorizationeStoreType, boolean>(
    (store) => store.auth.isLoggedIn
  );

  return isLoggedIn;
};

export const useRefreshToken = () => {
  const store = useStore<AuthorizationeStoreType>();

  const refreshAction = useMemo(
    () => () => {
      return new Promise<boolean>((resolve) => {
        const unsub = store.subscribe(() => {
          const state = store.getState();
          // finish the request
          if (!state.auth.loading) {
            // if there are errors or the user is logged out
            if (state.auth.errors || !state.auth.isLoggedIn) {
              unsub();
              resolve(false);
            } else {
              unsub();
              resolve(true);
            }
          }
        });
        store.dispatch(RefreshTokenAction());
      });
    },
    [store]
  );

  return refreshAction;
};
