import { gql, useMutation } from '@apollo/client';

export const DELETE_USER_QUERY = gql`
  mutation($user_id: String!) {
    deleteUser(user_id: $user_id) {
      ok
      user_id
    }
  }
`;

export interface DeleteUserResultInterface {
  deleteUser: {
    ok: boolean;
    user_id: string;
  };
}

export const useDeleteUser = (user_id?: string) => {
  const [deleteUser, result] = useMutation<DeleteUserResultInterface>(
    DELETE_USER_QUERY,
    {
      variables: { user_id },
    }
  );
  return {
    deleteUser: () => deleteUser(),
    ...result,
  };
};
