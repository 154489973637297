import { gql, useMutation } from '@apollo/client';
import { LabelItem } from '../../get';

export const CREATE_LABEL_QUERY = gql`
  mutation($labelItem: CreateLabelItemDto!) {
    createLabel(labelItem: $labelItem) {
      label_id
    }
  }
`;

export interface CreateLabelResult {
  createLabel: {
    label_id: string;
  };
}

export type CreateLabelItem = Omit<
  LabelItem,
  'label_id' | '__typename' | 'created_at' | 'updated_at' | 'objectTypeId'
>;

export const useCreateLabels = () => {
  const [create, { loading, error }] = useMutation<CreateLabelResult>(
    CREATE_LABEL_QUERY
  );
  return {
    create: (labelItem: CreateLabelItem) =>
      create({ variables: { labelItem } }).then((result) => {
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
        return result.data?.createLabel.label_id;
      }),
    error: error?.message,
    loading,
  };
};
