import { gql, useQuery } from '@apollo/client';

export const GET_SOMFY_GROUP_QUERY = gql`
  query {
    getSomfyGroups(offset: 400) {
      data {
        somfy_group_id
        name
      }
    }
  }
`;

export interface GetSomfyGroupResultInterface {
  getSomfyGroups: {
    data: Array<{
      somfy_group_id: string;
      name: string;
    }>;
  };
}

export const useGetAllSomfyGroup = () => {
  const result = useQuery<GetSomfyGroupResultInterface>(GET_SOMFY_GROUP_QUERY);
  return result;
};
