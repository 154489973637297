import React from 'react';

/* eslint-disable-next-line */
export const IconArchiveSmallGreen = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      style={{ transform: 'scale(0.9)' }}
    >
      <image
        width="32"
        height="32"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAuCAMAAACCu8B8AAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAACFlBMVEUAAACGwm2GxHCHw3CZ zGaGxG6HxG+Hw2+JxHaGwW6GxG+Pv3CIu2aIw22HxXCJxGKMzHOIxGuFxWiHw26HxHCCwWyIymt5 vGuDwWyHw3CFyG+GxHCGw3GHw2mHx3CGxG+Hw3CGw3CHxG+IxXGHxHCHxHCAu26HxHCSyG2Av2qG w2+DxG+Hxm+IxG6HxHGHw3CGxG6GxHGAv0B2xGKHxG+OxnGIxXGHxHCHxm2L0XSHxHCHxHCIxXCH xHCHxHCHw3CGw2+HxG+KxW2HxXCIw3GHxG+IxW6HxG+Cw22HxG+Hw3CIxnGGxHCHxXCFxXCHxHCG xHCGxHCIxHCGw3CFwm+HxG+Hw2mGwnCHxHCKxXCHxHCIxXCHu2mAv2CIxHCGxHCKwnGHxHCIw2+L wWyGxXCIxG+Jx3GHxHCHxHCGv3NmzGaHxG+GwnCAuGOGxG+Hwm+JxXCHxHCIxXGIxHCHxXCHw3CH xG+HxHCGw2+IxXCGxG+HxHGGxG+GxG+NyW2GyXH//wCStm2Gw2+Iw26AgACEv22Iw22HxW2JsWKH xnKHxXCZzICHxnGGwm6IyXKGxXCIw26Gw26Hw2+MxWuHxHCHxXCHxnGGxHCHxG+Gv2yHxHCHxXCJ xG+Hv3CHwnCHw3CAv3CHxG+Nw2uIw3GHw2+EwXKHxG+Hw3COxnGIwm+JxGyGxHCIw3CJv3aHxHCN wXKHxHD///+h5ZQOAAAAsHRSTlMAKuKiBUr+1Q1KtRAPL/ANFCssZsEtKxMh7xfb3hEg7fTLxsn2 7x7ZDgzdJ1VWaOpfPQQN3QnN+jEL2PivoOb8N/EjwfCTjYovl+Jv+Z0wyvvt0M91ziJU2zD1zSII i/0/zM0h+dyM8/IoBcM7Et7Oe/39+vn7+v7Pq/f0/fMvPQEO3jwCOE1GDTF/Co6rL1QewpUfwtMk xNMoxNInIMbSIM8mxcgd18gbRxrgyRx9Hfq7F7AAAAABYktHRLE0Y55SAAAACXBIWXMAAAsTAAAL EwEAmpwYAAAAB3RJTUUH5QMXCTkq2t1KhAAAAaNJREFUOMvN0vs303EYwPGHsbkMGSKxJjbCQqLc c9vIcm3JdUhuFblfQ1butxpRMvdI4fkTMzO++34/s885OY73j5/zOud5znM+AIxsbJEbzw642fMJ UuBAkI58J2eheS6uAjc2u+Eu8uB7eokY3fQGn1sc6XubMNnPnyDFdyQBd80LDJLKiDI4hPV0L5Qr w8Ll9yURkXJmUQ+iH3JkTCxhx0ePCTIuPiExiVVyyhOiTE1jHy39esiMTIVCocyikMZLZFPIpzlK FeIzmj1z8zCfShYUYtFzKomoflFsksKXFmVJKZaVg0lWVGosyapqrHkFJnkM0YKsfY3Vx/BUxtRp pPUNjU2s3mjevmtGbBHDmXzfim18bO9g1YqdXd3YcwJPZS/2Scj1D3wYBDM5NEzu44hUZi61QM5w pf+Vnz6PGhobtyonJMYfNmlVTk3PGOLNXuKeVyvnaOX8F/xKJXULuPiNRuqWcPk7UEjxD1z5CRRy VY9rRmhFrutxYxMopHYLt3eAQqp+4e4enMvfQnL7fxD/HgBDXlAeA8KhxjJUH527f8tKRcu9NDco AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAzLTIzVDA5OjU3OjQxKzAwOjAwczUYMwAAACV0RVh0 ZGF0ZTptb2RpZnkAMjAyMS0wMy0yM1QwOTo1Nzo0MSswMDowMAJooI8AAAAASUVORK5CYII="
      />
    </svg>
  );
};

export default IconArchiveSmallGreen;
