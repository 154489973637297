import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { GetMotorsResultInterface } from '../backoffice/get';

export const GENERIC_MOTOR_OPTIONS_QUERY = gql`
  query($propertyToUnique: String!, $lableTemplate: String) {
    result: getMotorsSelectOptions(
      propertyToUnique: $propertyToUnique
      lableTemplate: $lableTemplate
      offset: 400
    ) {
      options: data {
        label
        value
      }
    }
  }
`;

export type PropertyToUniqueMotorType = keyof Omit<
  GetMotorsResultInterface['getMotorReference'],
  '__typename' | 'deleted_at' | 'updated_at'
>;

export interface GenericMotorOptionsResult {
  result: {
    options: Array<{
      label: string;
      value: string;
    }>;
  };
}

export const useGenericMotorOptions = (
  propertyToUnique: PropertyToUniqueMotorType,
  lableTemplate?: string
) => {
  return useQuery<GenericMotorOptionsResult>(GENERIC_MOTOR_OPTIONS_QUERY, {
    variables: { propertyToUnique, lableTemplate },
  });
};
