import { Action } from '@f-technology-srl/client-utils';
import { ValidationErrorDto } from '../types';

export enum AuthorizationActions {
  DO_LOGIN = '[auth] Start Login request',
  SET_LOGIN = '[auth] Login request was successful',
  ERROR_LOGIN = '[auth] Error during login request',

  DO_LOGOUT = '[auth] Start Logout request',
  SET_LOGOUT = '[auth] Logout request was successful',
  ERROR_LOGOUT = '[auth] Error during logout request',

  DO_REFRESH = '[auth] Start refresh auth token request',
  SET_REFRESH = '[auth] Refresh token request was successful',
  ERROR_REFRESH = '[auth] Error during refresh token request',

  ALREADY_LOGGED_IN = '[auth] Set already logged in',
}

// Login actions

export interface LoginArgumentsInterface {
  username: string;
  password: string;
  rememberMe?: boolean;
}

export type LoginActionType = Action<
  AuthorizationActions.DO_LOGIN,
  LoginArgumentsInterface
>;

export const loginAction = (
  username: string,
  password: string,
  rememberMe = true
): LoginActionType => ({
  type: AuthorizationActions.DO_LOGIN,
  payload: {
    username,
    password,
    rememberMe,
  },
});

export type LoginErrorActionType = Action<
  AuthorizationActions.ERROR_LOGIN,
  ValidationErrorDto
>;

export const errorLoginAction = (
  error: ValidationErrorDto
): LoginErrorActionType => ({
  type: AuthorizationActions.ERROR_LOGIN,
  payload: error,
});

export interface SuccessLoginArgumentsInterface {
  refresh_token?: string;
  authorization_token?: string;
  rememberMe: boolean;
}

export type SuccessLoginActionType = Action<
  AuthorizationActions.SET_LOGIN,
  SuccessLoginArgumentsInterface
>;

export const successLoginAction = (
  tokens?: SuccessLoginArgumentsInterface
): SuccessLoginActionType => ({
  type: AuthorizationActions.SET_LOGIN,
  payload: tokens,
});

// Logout actions

export type LogoutActionType = Action<AuthorizationActions.DO_LOGOUT>;

export const LogoutAction = (): LogoutActionType => ({
  type: AuthorizationActions.DO_LOGOUT,
});

export type LogoutErrorActionType = Action<
  AuthorizationActions.ERROR_LOGOUT,
  ValidationErrorDto
>;

export const errorLogoutAction = (
  error: ValidationErrorDto
): LogoutErrorActionType => ({
  type: AuthorizationActions.ERROR_LOGOUT,
  payload: error,
});

export type SuccessLogoutActionType = Action<AuthorizationActions.SET_LOGOUT>;

export const successLogoutAction = (): SuccessLogoutActionType => ({
  type: AuthorizationActions.SET_LOGOUT,
});

// Refresh token action

export interface RefreshTokenArgumentsInterface {
  refresh_token?: string;
}

export type RefreshTokenActionType = Action<
  AuthorizationActions.DO_REFRESH,
  RefreshTokenArgumentsInterface
>;

export const RefreshTokenAction = (
  refresh_token?: string
): RefreshTokenActionType => ({
  type: AuthorizationActions.DO_REFRESH,
  payload: {
    refresh_token,
  },
});

export type RefreshTokenErrorActionType = Action<
  AuthorizationActions.ERROR_REFRESH,
  ValidationErrorDto
>;

export const errorRefreshTokenAction = (
  error: ValidationErrorDto
): RefreshTokenErrorActionType => ({
  type: AuthorizationActions.ERROR_REFRESH,
  payload: error,
});

export type SuccessRefreshTokenActionType = Action<
  AuthorizationActions.SET_REFRESH,
  SuccessLoginArgumentsInterface
>;

export const successRefreshTokenAction = (
  tokens?: SuccessLoginArgumentsInterface
): SuccessRefreshTokenActionType => ({
  type: AuthorizationActions.SET_REFRESH,
  payload: tokens,
});

// already logged in action

export type AlreadyLoggedInActionType = Action<AuthorizationActions.ALREADY_LOGGED_IN>;

export const alreadyLoggedInAction = (): AlreadyLoggedInActionType => ({
  type: AuthorizationActions.ALREADY_LOGGED_IN,
});

export type AuthorizationActionsType =
  | LoginActionType
  | LoginErrorActionType
  | SuccessLoginActionType
  | LogoutActionType
  | LogoutErrorActionType
  | SuccessLogoutActionType
  | RefreshTokenActionType
  | RefreshTokenErrorActionType
  | SuccessRefreshTokenActionType
  | AlreadyLoggedInActionType;
