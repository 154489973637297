import { useQuery, gql } from '@apollo/client';

export const MOTOR_CHARACTERISTICS_QUERY = gql`
  query(
    $page: Int!
    $offset: Int!
    $technology: String!
    $motor_size: String!
    $type_motor: [String!]
  ) {
    searchMotors(
      page: $page
      offset: $offset
      technology: [$technology]
      size_motor: [$motor_size]
      type_motor: $type_motor
    ) {
      data {
        brand
        brand_image
        product_image
        motor_head
        nr_of_end_limits
        adjustment_type
        obstacle_detection
        freez_detection
        nominal_voltage
        system_of_protection
        insulation_class
        noise_level
        temperature_working_range
        certification_ce
        certification_vde
        certification_nf
        certification_cmim
        certification_pai
        certification_saber
        made_in
        activation
        parallel_connection
        radio_compatibility
        cable_connector_image
        cable_type
        cable_type_image
        cable_number_of_wires
        cable_wire_section
        cable_lenght
        cable_color_type
        cable_tipology
        vertical_screens
        vertical_screens_image
        zebra_screen
        zebra_screen_image
        rolling_shutters
        rolling_shutters_image
        leteral_arms_awnings
        leteral_arms_awnings_image
        awnings_with_arms
        awnings_with_arms_image
        cassette_awnings
        cassette_awnings_image
        designation_1
        package_label_image
        motor_label_image
      }
    }
  }
`;

export interface MotorCharacteristicsType {
  __typename: string;
  brand?: string;
  brand_image?: string;
  product_image?: string;
  motor_head?: string;
  nr_of_end_limits?: string;
  adjustment_type?: string;
  obstacle_detection?: boolean;
  freez_detection?: boolean;
  nominal_voltage?: string;
  system_of_protection?: string;
  insulation_class?: string;
  noise_level?: string;
  temperature_working_range?: string;
  certification_ce: boolean;
  certification_vde: boolean;
  certification_nf: boolean;
  certification_cmim: boolean;
  certification_pai: boolean;
  certification_saber: boolean;
  made_in: string;
  activation?: string;
  parallel_connection?: string;
  radio_compatibility?: string;
  cable_connector_image?: string;
  cable_type?: string;
  cable_type_image?: string;
  cable_number_of_wires?: number;
  cable_wire_section?: string;
  cable_lenght?: string;
  cable_color_type?: string;
  cable_tipology?: string;
  vertical_screens?: boolean;
  vertical_screens_image?: string;
  zebra_screen?: boolean;
  zebra_screen_image?: string;
  rolling_shutters?: boolean;
  rolling_shutters_image?: string;
  leteral_arms_awnings?: boolean;
  leteral_arms_awnings_image?: string;
  awnings_with_arms?: boolean;
  awnings_with_arms_image?: string;
  cassette_awnings?: boolean;
  cassette_awnings_image?: string;
  designation_1?: string;
  package_label_image?: string;
  motor_label_image?: string;
}

export interface MotorsCharacteristicsOptions {
  searchMotors: {
    data: Array<MotorCharacteristicsType>;
  };
}

export const useMotorCharacteristics = (
  page: number,
  offset: number,
  technology: string,
  motor_size: string,
  type_motor: string
) => {
  const status = useQuery<MotorsCharacteristicsOptions>(
    MOTOR_CHARACTERISTICS_QUERY,
    {
      variables: {
        page: page,
        offset: offset,
        technology: technology,
        motor_size: motor_size,
        type_motor: type_motor,
      },
    }
  );

  return status;
};

export interface MotorCharacteristicsLabelType {
  package_label_image?: string;
  motor_label_image?: string;
}

export interface MotorsCharacteristicsLabelOptions {
  searchMotors: {
    data: Array<MotorCharacteristicsLabelType>;
  };
}

export const useMotorCharacteristicsLabel = (
  page: number,
  offset: number,
  technology: string,
  motor_size: string,
  type_motor: string
) => {
  const status = useQuery<MotorsCharacteristicsLabelOptions>(
    MOTOR_CHARACTERISTICS_QUERY,
    {
      variables: {
        page: page,
        offset: offset,
        technology: technology,
        motor_size: motor_size,
        type_motor: type_motor,
      },
    }
  );

  return status;
};
