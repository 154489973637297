import { useQuery, gql } from '@apollo/client';
import { PaginateResultInterface } from '@eypconfig/shared/types';

const MOTORS_SEARCH_BACKOFFICE_QUERY = gql`
  query($page: Int, $offset: Int, $referenceLike: String) {
    result: searchMotors(
      page: $page
      offset: $offset
      referenceLike: $referenceLike
    ) {
      count
      offset
      page
      data {
        __typename
        objectTypeId: motor_reference_id
        reference
        designation
        technology
      }
    }
  }
`;

export interface MotorBackofficeListFilter {
  page?: string;
  offset?: string;
  referenceFilter?: string;
}

export interface MotorBackofficeSearchInterface {
  __typename: 'MotorReference';
  reference: string;
  designation: string;
  objectTypeId: string;
  technology?: string;
}

export interface SearchMotorsBackofficeQueryResultInterface {
  result: PaginateResultInterface<MotorBackofficeSearchInterface>;
}

export const useSearchMotorsBackoffice = (
  filters: MotorBackofficeListFilter
) => {
  const query = useQuery<SearchMotorsBackofficeQueryResultInterface | null>(
    MOTORS_SEARCH_BACKOFFICE_QUERY,
    {
      variables: {
        referenceLike: filters.referenceFilter,
        page: Number(filters.page),
        offset: Number(filters.offset),
      },
    }
  );

  return query;
};
