import React from 'react';
import './menu-tab.scss';
import Text from '../text/text';
import { AnimatePresence, motion } from 'framer-motion';

const svgVariants = {
  itemClose: {
    rotate: 0,
  },
  itemOpen: {
    rotate: -180,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
};

const pathVariants = {
  itemClose: {
    fill: '#fff',
  },
  itemOpen: {
    fill: '#000',
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
};

const childPathVariants = {
  itemClose: {
    fill: '#a7a7a7',
  },
  itemOpen: {
    fill: '#000',
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
};

const subMenuVariants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  collapsed: {
    opacity: 0,
    height: 0,
  },
};

const subMenuItemsVariants = {
  open: { opacity: 1 },
  collapsed: { opacity: 0 },
};

export type MenuTabType = {
  name: string;
  key: string;
  onClick: () => void;
  items: Array<{
    name: string;
    key: string;
    active?: boolean;
    onClick: () => void;
  }>;
};

/* eslint-disable-next-line */
export interface MenuTabProps {
  menuDescription: Array<MenuTabType>;
  firstLevelActive?: string;
  secondLevelActive?: string;
}

export const MenuTab: React.FC<MenuTabProps> = ({
  menuDescription,
  firstLevelActive,
  secondLevelActive,
}) => {
  return (
    <div className="way-menu-tabs">
      {menuDescription
        ? menuDescription.map((parent) => {
            return (
              <div
                className="way-menu-tab"
                data-cy={`menu-tab_${parent.key}_${
                  firstLevelActive === parent.key ? 'active' : ''
                }`}
                key={parent.key}
              >
                <button
                  className={`way-menu-tab__header ${
                    firstLevelActive === parent.key
                      ? 'way-menu-tab__header--active'
                      : ''
                  }`}
                  onClick={parent.onClick}
                  data-cy={`first-level-menu-button-${parent.key}`}
                  type="button"
                  tabIndex={0}
                  aria-haspopup="listbox"
                  aria-expanded={firstLevelActive === parent.key ? true : null}
                  style={{
                    backgroundColor:
                      firstLevelActive === parent.key ? '#8dd174' : '#000',
                  }}
                >
                  <Text
                    type="base-s-bold"
                    color={firstLevelActive === parent.key ? 'black' : 'white'}
                  >
                    {parent.name}
                  </Text>
                  <i>
                    <motion.svg
                      width="12"
                      height="7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      initial={
                        firstLevelActive === parent.key
                          ? 'itemOpen'
                          : 'itemClose'
                      }
                      animate={
                        firstLevelActive === parent.key
                          ? 'itemOpen'
                          : 'itemClose'
                      }
                      variants={svgVariants}
                      exit={
                        firstLevelActive === parent.key
                          ? 'itemClose'
                          : 'itemOpen'
                      }
                    >
                      <motion.path
                        d="M6.31299 6.57452l5.26411-5.143c.1683-.17027.1634-.44158-.0108-.606015-.17-.160418-.4395-.160418-.6095 0L6.00285 5.6655 1.04892.825505c-.17129-.167324-.44899-.167324-.620278 0-.171262.167373-.171262.438665 0 .606015l5.264068 5.143c.17132.16732.44899.16732.62028 0z"
                        fill="#A7A7A7"
                        variants={pathVariants}
                      />
                    </motion.svg>
                  </i>
                </button>
                <AnimatePresence initial={false}>
                  <motion.ul
                    className="way-menu-tab__body"
                    role="listbox"
                    tabIndex={-1}
                    variants={subMenuVariants}
                    initial={
                      firstLevelActive === parent.key ? 'open' : 'collapsed'
                    }
                    animate={
                      firstLevelActive === parent.key ? 'open' : 'collapsed'
                    }
                    inherit={false}
                    transition={{
                      ease: 'easeInOut',
                      duration: 0.5,
                    }}
                    layout
                  >
                    {parent.items.map((item) => {
                      const isActive =
                        secondLevelActive === item.key || item.active;
                      return (
                        <motion.li
                          key={item.key}
                          role="option"
                          aria-selected={isActive ? true : false}
                          tabIndex={0}
                          data-cy={`second-level-menu-button-${parent.key}-${item.key}`}
                          onClick={item.onClick}
                          onKeyUp={(event) =>
                            event.key === 'Enter' ? item.onClick : null
                          }
                          className={`way-menu-tab__body__item ${
                            isActive ? 'way-menu-tab__body__item--active' : ''
                          }`}
                          style={{
                            backgroundColor: isActive ? '#e0e0e0' : '#fff',
                          }}
                          inherit={false}
                          variants={subMenuItemsVariants}
                          transition={{
                            ease: 'easeInOut',
                            duration: 0.5,
                          }}
                          layout
                        >
                          <Text
                            type={isActive ? 'base-s-bold' : 'base-s'}
                            color="black"
                          >
                            {item.name}
                          </Text>
                          <i>
                            <motion.svg
                              width="12"
                              height="7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              initial={isActive ? 'itemOpen' : 'itemClose'}
                              animate={isActive ? 'itemOpen' : 'itemClose'}
                              exit={isActive ? 'itemClose' : 'itemOpen'}
                            >
                              <motion.path
                                d="M6.31299 6.57452l5.26411-5.143c.1683-.17027.1634-.44158-.0108-.606015-.17-.160418-.4395-.160418-.6095 0L6.00285 5.6655 1.04892.825505c-.17129-.167324-.44899-.167324-.620278 0-.171262.167373-.171262.438665 0 .606015l5.264068 5.143c.17132.16732.44899.16732.62028 0z"
                                fill="#A7A7A7"
                                variants={childPathVariants}
                              />
                            </motion.svg>
                          </i>
                        </motion.li>
                      );
                    })}
                  </motion.ul>
                </AnimatePresence>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default MenuTab;
