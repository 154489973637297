import { gql, useMutation } from '@apollo/client';

export const DELETE_PLASTIC_CAP_COLOR_SINGLE_QUERY = gql`
  mutation($plastic_cap_id: String!) {
    deletePlasticCapsColor(plastic_cap_id: $plastic_cap_id) {
      plastic_cap_id
    }
  }
`;

export const useDeletePlasticCapColor = (objectTypeId: string) => {
  const [doDelete, result] = useMutation(
    DELETE_PLASTIC_CAP_COLOR_SINGLE_QUERY,
    {
      variables: {
        plastic_cap_id: objectTypeId,
      },
    }
  );

  return {
    doDelete: () => doDelete().then((result) => !result.errors),
    loading: result.loading,
    error: result.error?.message,
  };
};
