import React from 'react';
import './text.scss';

export type textType =
  | 'base'
  | 'base-bold'
  | 'base-s'
  | 'base-s-bold'
  | 'base-s-bold-lh1'
  | 'base-xs'
  | 'base-xs-bold'
  | 'input'
  | 'link'
  | 'link2'
  | 'small-button-text'
  | 'title-area'
  | 'title-area-small'
  | 'column-title'
  | 'column-text'
  | 'small-link'
  | 'languages'
  | 'small-link2'
  | 'big-link'
  | 'info'
  | 'info-big'
  | 'info-languages'
  | 'button-text'
  | 'select-text'
  | 'accordion-text'
  | 'hint'
  | 'big-label'
  | 'column-text-small'
  | 'txt-download-list'
  | 'info-md'
  | 'info-bold'
  | 'popup-title'
  | 'label-input';

export type colorType =
  | 'black'
  | 'primarygreen'
  | 'lightgrey'
  | 'white'
  | 'darkgrey'
  | 'red'
  | 'midgrey'
  | 'midgreynoitalic'
  | 'blue'
  | 'lightgreen'
  | 'darkred';

/* eslint-disable-next-line */
export interface TextProps {
  type: textType;
  color: colorType;
  dataCy?: string;
  style?: React.CSSProperties;
  uppercase?: boolean;
  onClick?: () => void;
}

export const Text: React.FC<TextProps> = ({
  type,
  color,
  onClick,
  children,
  dataCy,
  style,
  uppercase,
}) => {
  return (
    <span
      data-cy={dataCy}
      className={`way-text way-text-${type} way-text--${color} ${
        onClick ? 'way-text--clickable' : ''
      } ${uppercase ? 'way-text--uppercased' : ''}`}
      onClick={onClick ? onClick : null}
      style={style}
    >
      {children}
    </span>
  );
};

export default Text;
