import { gql, useMutation } from '@apollo/client';
import { PlasticCapColorItem } from '../../get';

export const PLASTIC_CAP_COLOR_UPDATE_QUERY = gql`
  mutation($plasticCapItem: UpdatePlasticCapColorItemDto!) {
    updatePlasticCapsColor(plasticCapItem: $plasticCapItem) {
      plastic_cap_id
    }
  }
`;

export interface UpdatePlasticCapColorResponse {
  updatePlasticCapsColor: {
    plastic_cap_id: string;
  };
}

export type UpdatePlasticCapColorItem = Partial<
  Omit<PlasticCapColorItem, 'plastic_cap_id'>
> &
  Pick<PlasticCapColorItem, 'plastic_cap_id'>;

export const useUpdatePlasticCapColor = () => {
  const [mutate, { loading, error }] = useMutation(
    PLASTIC_CAP_COLOR_UPDATE_QUERY
  );
  return {
    update: ({
      objectTypeId,
      created_at,
      updated_at,
      ...items
    }: UpdatePlasticCapColorItem) =>
      mutate({
        variables: {
          plasticCapItem: {
            plastic_cap_id: objectTypeId,
            ...items,
          },
        },
      }).then((result) => !result.errors),
    loading,
    error: error?.message,
  };
};
