import { gql, useLazyQuery } from '@apollo/client';

export const TUBE_COLOR_SINGLE_QUERY = gql`
  query($tube_color_id: String!) {
    result: getTubeColor(tube_color_id: $tube_color_id) {
      objectTypeId: tube_color_id
      brand_classification
      range_for_configuration
      tube_color_id
      tube_color_image
      tube_color
      motor_size
      motor_type
      ral_name
      color_code
      ral_code
      created_at
      updated_at
    }
  }
`;

export interface TubeColorItem {
  objectTypeId: string;
  tube_color_id: string;
  tube_color_image?: string;
  tube_color?: string;
  brand_classification: Array<string>;
  range_for_configuration: boolean;
  motor_size: string;
  motor_type: Array<string>;
  ral_name: string;
  color_code: string;
  ral_code: string;
  created_at: Date;
  updated_at: Date;
  __typename: string;
}

export interface GetTubeColorResult {
  result: TubeColorItem;
}

export const useGetTubeColor = (tube_color_id: string) => {
  const res = useLazyQuery<GetTubeColorResult>(TUBE_COLOR_SINGLE_QUERY, {
    variables: { tube_color_id },
  });
  return res;
};
