import { gql, useMutation } from '@apollo/client';

export const CREATE_SOMFY_GROUP_QUERY = gql`
  mutation($name: String!) {
    createSomfyGroup(name: $name) {
      somfy_group_id
    }
  }
`;

export interface CreateSomfyGroupResult {
  createSomfyGroup: {
    somfy_group_id: string;
  };
}

export const useCreateSomfyGroup = () => {
  const [create, { loading, error }] = useMutation<CreateSomfyGroupResult>(
    CREATE_SOMFY_GROUP_QUERY
  );
  return {
    create: (name: string) =>
      create({ variables: { name } }).then((result) => {
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
        return result.data?.createSomfyGroup.somfy_group_id;
      }),
    error: error?.message,
    loading,
  };
};
