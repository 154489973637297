import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Text from '../text/text';
import { LoadingController } from '../loading/loading';

import './drag-and-drop.scss';

export const UploadIconDropdown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" />
    </svg>
  );
};

/* eslint-disable-next-line */
export interface DragAndDropProps {
  handleDrop: (file: File) => void;
  handleClick: () => void;
  loading: boolean;
}

export function DragAndDrop(props: DragAndDropProps) {
  const [dragging, setDragging] = useState(false);

  function handleDragIn(ev: React.DragEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.dataTransfer?.items?.length > 0) {
      setDragging(true);
    }
  }

  function handleDragOut(ev: React.DragEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    setDragging(false);
  }

  function handleDrag(ev: React.DragEvent) {
    ev.preventDefault();
    ev.stopPropagation();
  }

  function handleDrop(ev: React.DragEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    setDragging(false);
    if (ev.dataTransfer?.files?.length > 0) {
      props.handleDrop(ev.dataTransfer.files[0]);
      try {
        ev.dataTransfer?.clearData();
      } catch (error) {
        console.log('Error during drop, maybe firefox related', error);
      }
    }
  }

  return (
    <motion.div
      onClick={props.handleClick}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      onDragEnter={handleDragIn}
      className="way-drag-and-drop-container"
    >
      <LoadingController open={props.loading} />
      <AnimatePresence>
        {dragging && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="way-drag-and-drop-container__centered"
          >
            <Text
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
              type="info"
              color="lightgrey"
            >
              Drop the file here
            </Text>
          </motion.div>
        )}
        {!dragging && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="way-drag-and-drop-container__centered"
          >
            {props.loading ? (
              <Text
                style={{ marginBottom: 'auto', marginTop: 'auto' }}
                type="info"
                color="lightgrey"
              >
                Loading...
              </Text>
            ) : (
              <>
                <UploadIconDropdown
                  width={100}
                  height={100}
                  fill="lightgray"
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 'auto',
                  }}
                />
                <Text
                  style={{ marginBottom: 'auto' }}
                  type="info"
                  color="lightgrey"
                >
                  Click or drag a file to upload
                </Text>{' '}
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default DragAndDrop;
