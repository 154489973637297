import { gql, useLazyQuery } from '@apollo/client';

export const PACKAGE_SINGLE_QUERY = gql`
  query($package_id: String!) {
    result: getPackage(package_id: $package_id) {
      package_id
      objectTypeId: package_id
      brand_classification
      product_classification
      motor_size
      designation_1
      packaging_type
      packaging_image
      packaging_quantity
      packaging_dimension_l
      packaging_dimension_w
      packaging_dimension_h
      packaging_print_finiture
      updated_at
      created_at
    }
  }
`;

export interface PackageItem {
  package_id: string;
  objectTypeId: string;
  brand_classification: Array<string>;
  product_classification: string;
  motor_size?: string;
  designation_1?: string;
  packaging_type: Array<string>;
  packaging_image?: string;
  packaging_quantity?: string;
  packaging_dimension_l?: string;
  packaging_dimension_w?: string;
  packaging_dimension_h?: string;
  packaging_print_finiture?: string;
  updated_at: Date;
  created_at: Date;
  __typename: string;
}

export interface GetPackageResult {
  result: PackageItem;
}

export const useGetPackage = (package_id: string) => {
  const res = useLazyQuery<GetPackageResult>(PACKAGE_SINGLE_QUERY, {
    variables: { package_id },
  });
  return res;
};
