import ReactDOM from 'react-dom';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import './select.scss';
import { AnimatePresence, motion } from 'framer-motion';
import {
  useOnClickOutside,
  useOnKeysPressed,
  useOnScroll,
} from '@f-technology-srl/client-utils';

export type SelectType =
  | 'over'
  | 'edit'
  | 'left'
  | 'radio-button'
  | 'no-label-xs'
  | 'no-label-lg'
  | 'no-label-sm';
/* eslint-disable-next-line */
export type OptionsType = { label: string; value?: any };

export interface SelectProps {
  label?: string;
  type: SelectType;
  dataOptions: Array<OptionsType>;
  onSelect: (value) => void;
  error?: string;
  disabled?: boolean;
  'data-cy'?: string;
  /* eslint-disable-next-line */
  selected?: any;
  onTouched?: () => void;
  required?: boolean;
  width?: string;
}

const useBody = () => {
  const body = useRef<HTMLElement>(null);

  useEffect(() => {
    body.current = document.body;
  }, []);

  return body;
};

export function calculateOpeningPosition(
  container: React.MutableRefObject<HTMLDivElement>
) {
  const position = container.current?.getBoundingClientRect();

  const windowWidth = document.body.clientWidth;
  const windowHeight = Math.max(window.innerHeight, document.body.clientHeight);

  const triggerwidth = position?.width;
  const containerHeight = 200;

  const width = position?.width < 100 ? 100 : position?.width;
  const xOffset = width > triggerwidth ? (width - triggerwidth) / 2 : 0;

  let x = position?.left - xOffset;
  let y = position?.top + position?.height;

  let border = 'top';

  // Horizontaly exit from the left side of the screen
  if (x + width > windowWidth) {
    x = x - (x + width - windowWidth + 10);
  }

  // Horizontaly exit from the left side of the screen
  if (x < 0) {
    x = 10;
  }
  // if image is too big -> bottom of the image = bottom of the page
  if (y + containerHeight > windowHeight) {
    y = windowHeight - position?.top;
    border = 'bottom';
  }

  const result = {
    x,
    y,
    width,
    border,
  };

  return result;
}

interface OpeningContainerProps {
  opening: boolean;
  modal: React.MutableRefObject<HTMLUListElement>;
  handleClick: (label, value) => void;
  dataOptions: SelectProps['dataOptions'];
  selected: SelectProps['selected'];
  dataCy: SelectProps['data-cy'];
  position: {
    x: number;
    y: number;
    width: number;
    border: string;
  };
}

const variants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  collapsed: {
    opacity: 0,
    height: 0,
  },
};

const liVariants = {
  open: { opacity: 1 },
  collapsed: { opacity: 0 },
};

const OpeningContainer = React.forwardRef(
  (props: OpeningContainerProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const ancor = useBody();

    return ancor.current
      ? ReactDOM.createPortal(
          <div
            ref={ref}
            className={`way-select way-select__input ${
              props.position.border
                ? 'way-select__input__' + props.position.border
                : ''
            }`}
            style={{
              pointerEvents: props.opening ? 'all' : 'none',
              position: 'fixed',
              top: props.position.border === 'top' ? props.position.y : 'auto',
              left: props.position.x,
              bottom:
                props.position.border === 'bottom' ? props.position.y : 'auto',
              width: props.position.width,
            }}
          >
            <AnimatePresence initial={false}>
              {props.opening && (
                <motion.ul
                  ref={props.modal}
                  tabIndex={-1}
                  role="listbox"
                  aria-labelledby="select-label"
                  variants={variants}
                  initial={'collapsed'}
                  animate={'open'}
                  exit={'collapsed'}
                  inherit={false}
                  style={{
                    zIndex: 10,
                  }}
                  transition={{
                    ease: 'easeInOut',
                    duration: 0.3,
                  }}
                  layout
                >
                  {props.dataOptions
                    ? props.dataOptions.map((item) => {
                        return (
                          <motion.li
                            key={`${item.label}${item.value}`}
                            id={String(item.value)}
                            role="option"
                            tabIndex={0}
                            layout
                            inherit={false}
                            variants={liVariants}
                            transition={{
                              ease: 'easeInOut',
                              duration: 0.7,
                            }}
                            aria-selected={
                              props.selected === item.value ? true : false
                            }
                            onKeyUp={(event) =>
                              event.key === 'Enter'
                                ? props.handleClick(item.label, item.value)
                                : null
                            }
                            onClick={() =>
                              props.handleClick(item.label, item.value)
                            }
                            data-cy={
                              props.dataCy ? `${props.dataCy}-item` : null
                            }
                          >
                            {item.label}
                          </motion.li>
                        );
                      })
                    : null}
                </motion.ul>
              )}
            </AnimatePresence>
          </div>,
          ancor.current
        )
      : null;
  }
);

export const Select: React.FC<SelectProps> = ({
  label,
  type,
  dataOptions,
  onSelect,
  children,
  disabled,
  error,
  selected,
  required,
  width,
  'data-cy': dataCy,
  onTouched,
}) => {
  const [opening, setOpening] = useState(false);
  const selectionLabel = useMemo(
    () =>
      dataOptions?.find((option) => option.value === selected)?.label || '/',
    [dataOptions, selected]
  );
  const modal = useRef<HTMLUListElement>();
  const openCloseBtn = useRef<HTMLButtonElement>();
  const container = useRef<HTMLDivElement>();

  useOnScroll(() => {
    if (opening) {
      setOpening(false);
    }
  });

  useOnClickOutside(modal, (event) => {
    // Close only when the target of the click is not the button
    if (opening && event.target !== openCloseBtn.current) {
      setOpening(false);
    }
  });

  useOnKeysPressed(['Escape'], () => {
    if (opening) {
      setOpening(false);
    }
  });

  const handleClick = (label, value) => {
    setOpening((pre) => !pre);
    onSelect(value);
  };

  const position = calculateOpeningPosition(container);

  return (
    <div
      className={`way-select way-select--${type} ${
        width === '100' ? 'way-select--' + type + '-100' : ''
      }`}
    >
      {type !== 'no-label-xs' &&
      type !== 'no-label-sm' &&
      (label || children) ? (
        <div className="way-select__header">
          {label && (
            <label id="select-label">
              {label} {required && <span>*</span>}{' '}
            </label>
          )}
          {type === 'edit' || type === 'radio-button' ? children : null}
        </div>
      ) : null}
      <div
        ref={container}
        className={`way-select__input ${
          error ? 'way-select__input__error' : ''
        }`}
      >
        <button
          ref={openCloseBtn}
          aria-haspopup="listbox"
          aria-labelledby="select-label select-button"
          id="select-button"
          onClick={() => (disabled ? null : setOpening((prev) => !prev))}
          aria-expanded={opening ? true : null}
          disabled={disabled}
          type="button"
          data-cy={dataCy}
          onBlur={onTouched}
        >
          <p
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {selectionLabel}
          </p>
        </button>
        <OpeningContainer
          opening={opening}
          handleClick={handleClick}
          dataCy={dataCy}
          selected={selected}
          modal={modal}
          dataOptions={dataOptions}
          position={position}
        />
      </div>
    </div>
  );
};

export default Select;
