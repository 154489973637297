export const useUploadFile = (refreshTokenAction: () => Promise<boolean>) => {
  return (file: File): Promise<string> => {
    const formdata = new FormData();

    formdata.append('file', file);

    return fetcher(
      '/api/files/upload',
      {
        method: 'POST',
        body: formdata,
      },
      refreshTokenAction
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Error code ${response.status}`);
        }
      })
      .then((body) => body.uri);
  };
};

export async function fetcher(
  input: RequestInfo,
  init?: RequestInit,
  refreshTokenAction?: () => Promise<boolean>
) {
  const response = await fetch(input, init);
  if (response.status === 401) {
    const result = await refreshTokenAction();
    if (result) {
      return fetcher(input, init, refreshTokenAction);
    }
  }
  return response;
}
