import React, { useState } from 'react';
import './report-buttons.scss';
import { Button, buttonType } from '../button/button';
import { Save, Print, Send, Delete, Arrow } from '../icon/icon';
import { LoadingController } from '../loading/loading';

/* eslint-disable-next-line */
export interface ReportButtonsProps {
  onSave: () => Promise<unknown>;
  onPrint: () => Promise<unknown>;
  onSend: () => Promise<unknown>;
  onSaveAndClose: () => Promise<unknown>;
  onDelete: () => Promise<unknown>;
  onBack?: () => unknown;
  entitySaved: boolean;
}

export const ReportButtons: React.FC<ReportButtonsProps> = ({
  onSave,
  onPrint,
  onSend,
  onSaveAndClose,
  onDelete,
  onBack,
  entitySaved,
}) => {
  const [printLoading, setPrintLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveCloseLoading, setSaveCloseLoading] = useState(false);
  const [sendLoading, setsendLoading] = useState(false);

  return (
    <div className="way-report-buttons">
      <div className="way-report-buttons__group">
        <div className="way-report-buttons__group__item">
          <LoadingController
            open={
              printLoading || saveLoading || saveCloseLoading || sendLoading
            }
          />
          {onBack && (
            <Button
              label="PREVIOUS"
              type={buttonType.left}
              iconName={Arrow}
              onClick={() => onBack()}
              data-cy="previous-button"
            />
          )}
          <Button
            label={saveLoading ? 'SAVING...' : 'SAVE'}
            type={buttonType.icon}
            iconName={Save}
            onClick={() => {
              setSaveLoading(true);
              onSave().finally(() => setSaveLoading(false));
            }}
            disabled={saveLoading}
            data-cy="save-button"
          />
          <Button
            label={
              printLoading
                ? 'LOADING PRODUCT PREVIEW...'
                : 'PRINT PRODUCT PREVIEW'
            }
            type={buttonType.icon}
            iconName={Print}
            onClick={() => {
              setPrintLoading(true);
              onPrint().finally(() => setPrintLoading(false));
            }}
            disabled={!entitySaved || printLoading}
            data-cy="print-technical-sheet-button"
          />
        </div>
        <div className="way-report-buttons__group__item">
          <Button
            label={
              sendLoading
                ? 'SENDING PRODUCT PREVIEW...'
                : 'SEND PRODUCT PREVIEW'
            }
            type={buttonType.icon}
            iconName={Send}
            onClick={() => {
              setsendLoading(true);
              onSend().finally(() => setsendLoading(false));
            }}
            disabled={!entitySaved}
            data-cy="send-technical-sheet-button"
          />
          <Button
            label={saveCloseLoading ? 'SAVING...' : 'SAVE AND CLOSE'}
            type={buttonType.icon}
            iconName={Save}
            onClick={() => {
              setSaveCloseLoading(true);
              onSaveAndClose().finally(() => setSaveCloseLoading(false));
            }}
            disabled={saveCloseLoading}
            data-cy="save-and-close-button"
          />
        </div>
      </div>
      <div className="way-report-buttons__group way-report-buttons__group--right">
        <Button
          label="DELETE"
          type={buttonType.icon}
          iconName={Delete}
          onClick={onDelete}
          bgColor="#e45050"
          data-cy="delete-button"
        />
      </div>
    </div>
  );
};

export default ReportButtons;
