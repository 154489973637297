import { gql, useMutation } from '@apollo/client';
import { MotorsSearchFilter } from '@eypconfig/shared/types';
import { GetMotorsResultInterface } from '../backoffice/get';

export const BULK_UPDATE_MOTORS_MUTATION = gql`
  mutation (
    $filter: MotorsSearchFilter!
    $motorReference: MotorsReferenceBulkUpdateInput!
  ) {
    bulkUpdateMotors(filter: $filter, motorReference: $motorReference) {
      count
    }
  }
`;

export type BulkUpdateMotorsInterface = Partial<
  Omit<GetMotorsResultInterface['getMotorReference'], 'motor_reference_id'>
>;

export interface BulkUpdateMotorsResultInterface {
  bulkUpdateMotors: {
    count?: number;
  };
}

export const useBulkUpdateMotors = () => {
  const [bulkUpdateMotors, result] =
    useMutation<BulkUpdateMotorsResultInterface>(BULK_UPDATE_MOTORS_MUTATION);

  return {
    bulkUpdateMotors: (
      filter: MotorsSearchFilter,
      motorReference: BulkUpdateMotorsInterface
    ) =>
      bulkUpdateMotors({
        variables: {
          filter,
          motorReference,
        },
      }).then(({ data }) => data?.bulkUpdateMotors?.count),
    ...result,
  };
};
