import { gql, useMutation } from '@apollo/client';
import { BracketsSearchFilter } from '@eypconfig/shared/types';
import { GetBracketResultInterface } from '../backoffice/get';

export const BULK_UPDATE_BRACKETS_MUTATION = gql`
  mutation (
    $filter: BracketsSearchFilter!
    $bracketReference: BracketsReferenceBulkUpdateInput!
  ) {
    bulkUpdateBrackets(filter: $filter, bracketReference: $bracketReference) {
      count
    }
  }
`;

export type BulkUpdateBracketsInterface = Partial<
  Omit<GetBracketResultInterface['getBracketReference'], 'bracket_reference_id'>
>;

export interface BulkUpdateBracketsResultInterface {
  bulkUpdateBrackets: {
    count?: number;
  };
}

export const useBulkUpdateBrackets = () => {
  const [bulkUpdateBrackets, result] =
    useMutation<BulkUpdateBracketsResultInterface>(
      BULK_UPDATE_BRACKETS_MUTATION
    );

  return {
    bulkUpdateBrackets: (
      filter: BracketsSearchFilter,
      bracketReference: BulkUpdateBracketsInterface
    ) =>
      bulkUpdateBrackets({
        variables: {
          filter,
          bracketReference,
        },
      }).then(({ data }) => data?.bulkUpdateBrackets?.count),
    ...result,
  };
};
