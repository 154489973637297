import React from 'react';

export const IconCan = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="14"
      height="14"
      {...props}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1986 2.44765V0H5.2852V2.44765H0V3.33574H1.55957L2.68592 18H13.8195L14.9458 3.33574H16.7437V2.44765H11.1986ZM10.3321 0.866426V2.42599H6.17329V0.866426H10.3321ZM14.0578 3.29242L12.9964 17.0686H3.48736L2.44765 3.29242H14.0578Z"
        fill="black"
      />
      <path
        d="M8.68594 5.65343H7.79785V14.8159H8.68594V5.65343Z"
        fill="black"
      />
      <path
        d="M11.6192 5.66221L10.7324 5.61417L10.2355 14.7845L11.1223 14.8326L11.6192 5.66221Z"
        fill="black"
      />
      <path
        d="M6.23794 14.7888L5.74121 5.61842L4.85446 5.66646L5.35118 14.8368L6.23794 14.7888Z"
        fill="black"
      />
    </svg>
  );
};

export default IconCan;
