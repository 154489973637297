import { useQuery, gql } from '@apollo/client';
import { TubeType, HeadType } from '@eypconfig/shared/types';

export const TUBE_QUERY = gql`
  query($brand_classification: String, $motor_size: String) {
    aviableTubesColor(
      brand: $brand_classification
      motor_size: $motor_size
      range_for_configuration: true
    ) {
      tube_color_image
      ral_name
      tube_color
      color_code
    }
  }
`;

export const HEAD_QUERY = gql`
  query(
    $brand_classification: String
    $motor_size: String
    $type_code: String
    $motor_head: String
  ) {
    aviablePlasticCaps(
      brand: $brand_classification
      motor_size: $motor_size
      type_code: $type_code
      motor_head: $motor_head
    ) {
      cap_color_lable
      cap_color_code
      cap_logo_image
      cap_no_logo_image
    }
  }
`;

export interface HeadOptions {
  aviablePlasticCaps: Array<HeadType>;
}

export interface TubeOptions {
  aviableTubesColor: Array<TubeType>;
}

export const useTube = (brand_classification: string, motor_size: string) => {
  const status = useQuery<TubeOptions>(TUBE_QUERY, {
    variables: {
      motor_size,
      brand_classification: brand_classification
        .split('_')
        .join(' ')
        .toUpperCase(),
    },
  });

  return status;
};

export const useHead = (
  brand_classification: string,
  motor_size: string,
  type_code: string,
  motor_head: string
) => {
  const status = useQuery<HeadOptions>(HEAD_QUERY, {
    variables: {
      type_code,
      motor_size,
      motor_head,
      brand_classification: brand_classification
        .split('_')
        .join(' ')
        .toUpperCase(),
    },
  });

  return status;
};
