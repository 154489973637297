import { LoginType, TransportTypes } from '@f-technology-srl/api-authorization';
import { Reducer } from 'redux';
import { ValidationErrorDto } from '../types';
import {
  AuthorizationActionsType,
  AuthorizationActions,
} from './authorization.actions';

export interface AuthorizationConfiguration {
  type: LoginType;
  transport: TransportTypes;
  baseUri: string;
}

export interface AuthenticateStoreType {
  isLoggedIn: boolean;
  loading: boolean;
  errors?: ValidationErrorDto;
  config: AuthorizationConfiguration;
}

export const makeAuthorizationReducer = (
  config: AuthorizationConfiguration
) => {
  const initial: AuthenticateStoreType = {
    isLoggedIn: false,
    loading: false,
    config,
  };

  const reducer: Reducer<AuthenticateStoreType, AuthorizationActionsType> = (
    state = initial,
    action
  ) => {
    switch (action.type) {
      case AuthorizationActions.ERROR_LOGIN:
      case AuthorizationActions.ERROR_LOGOUT:
      case AuthorizationActions.ERROR_REFRESH:
        return {
          ...state,
          loading: false,
          isLoggedIn: false,
          errors: action.payload,
        };
      case AuthorizationActions.SET_LOGIN:
        return {
          ...state,
          loading: false,
          isLoggedIn: true,
        };
      case AuthorizationActions.SET_LOGOUT:
        return {
          ...state,
          loading: false,
          isLoggedIn: false,
        };
      case AuthorizationActions.SET_REFRESH:
        return {
          ...state,
          loading: false,
        };
      case AuthorizationActions.DO_LOGIN:
      case AuthorizationActions.DO_REFRESH:
      case AuthorizationActions.DO_LOGOUT:
        return {
          ...state,
          loading: true,
          errors: null,
        };
      case AuthorizationActions.ALREADY_LOGGED_IN:
        return {
          ...state,
          isLoggedIn: true,
        };
      default:
        return state;
    }
  };

  return reducer;
};
