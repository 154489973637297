import { gql, useMutation } from '@apollo/client';

export const DELETE_TUBE_COLOR_SINGLE_QUERY = gql`
  mutation($tube_color_id: String!) {
    deleteTubeColor(tube_color_id: $tube_color_id) {
      tube_color_id
    }
  }
`;

export const useDeleteTubeColor = (objectTypeId: string) => {
  const [doDelete, result] = useMutation(DELETE_TUBE_COLOR_SINGLE_QUERY, {
    variables: {
      tube_color_id: objectTypeId,
    },
  });

  return {
    doDelete: () => doDelete().then((result) => !result.errors),
    loading: result.loading,
    error: result.error?.message,
  };
};
