import React, { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import './accordion.scss';

/* eslint-disable-next-line */
export interface AccordionProps {
  isOpen?: boolean;
  title: string;
  'data-cy'?: string;
  paddingLeft?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({
  isOpen,
  title,
  children,
  'data-cy': dataCy,
  paddingLeft,
}) => {
  const oldState = useRef<boolean>(isOpen);
  const [opening, setOpening] = useState(oldState.current);

  useEffect(() => {
    oldState.current = opening;
  }, [opening]);

  const handleChange = () => {
    setOpening((pre) => !pre);
    window.dispatchEvent(new Event('resize'));
  };

  return (
    <motion.div
      className={`way-accordion ${opening ? 'way-accordion--open' : ''}`}
      layout
      data-cy={dataCy}
    >
      <motion.label
        className="way-accordion-label"
        onClick={handleChange}
        layout
      >
        {title}
      </motion.label>
      <AnimatePresence initial={false}>
        {opening && (
          <motion.div
            className="way-accordion__content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            layout
            style={{ paddingLeft: paddingLeft ? '30px' : 0 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Accordion;
