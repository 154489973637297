import { gql, useMutation } from '@apollo/client';

export const UPDATE_FILE_ADAPTER_MUTATION = gql`
  mutation(
    $signed_document_uri: String
    $date_sign: DateTime
    $reference: String
    $adapter_reference_id: String!
  ) {
    updateAdapterReference(
      adapterReference: {
        adapter_reference_id: $adapter_reference_id
        date_sign: $date_sign
        signed_document_uri: $signed_document_uri
        reference: $reference
      }
    ) {
      signed_document_uri
      date_sign
    }
  }
`;

export interface UpdateFileAdapterResultInterface {
  updateAdapterReference: {
    signed_document_uri?: string;
    date_sign?: Date;
  };
}

export const useUpdateFileAdapter = (adapter_reference_id: string) => {
  const [
    updateFileAdapter,
    result,
  ] = useMutation<UpdateFileAdapterResultInterface>(
    UPDATE_FILE_ADAPTER_MUTATION,
    {
      variables: {
        adapter_reference_id,
      },
    }
  );
  return {
    updateFileAdapter: (signed_document_uri: string) =>
      updateFileAdapter({
        variables: { signed_document_uri, date_sign: new Date() },
      }),
    ...result,
  };
};

export interface AdapterReferenceUpdateInterface {
  reference?: string;
}

export const useUpdateReferenceAdapter = (adapter_reference_id: string) => {
  const [updateAdapter, result] = useMutation<UpdateFileAdapterResultInterface>(
    UPDATE_FILE_ADAPTER_MUTATION,
    {
      variables: {
        adapter_reference_id,
      },
    }
  );
  return {
    updateAdapter: (adapterData: AdapterReferenceUpdateInterface) =>
      updateAdapter({
        variables: adapterData,
      }),
    ...result,
  };
};
