import { gql, useMutation } from '@apollo/client';
import { SomfyGroupsSearchItem } from '../../search';

export const SOMFY_GROUP_UPDATE_QUERY = gql`
  mutation($somfyGroupItem: UpdateSomfyGroupDto!) {
    updateSomfyGroup(somfyGroupItem: $somfyGroupItem) {
      somfy_group_id
    }
  }
`;

export interface UpdateSomfyGroupResponse {
  updateSomfyGroup: {
    somfy_group_id: string;
  };
}

export type UpdateSomfyGroupItem = Partial<
  Omit<SomfyGroupsSearchItem, 'somfy_group_id'>
> &
  Pick<SomfyGroupsSearchItem, 'somfy_group_id'>;

export const useUpdateSomfyGroup = () => {
  const [mutate, { loading, error }] = useMutation<UpdateSomfyGroupResponse>(
    SOMFY_GROUP_UPDATE_QUERY
  );
  return {
    update: ({ objectTypeId, __typename, ...items }: UpdateSomfyGroupItem) =>
      mutate({
        variables: {
          somfyGroupItem: {
            somfy_group_id: objectTypeId,
            ...items,
          },
        },
      }).then((result) => !result.errors),
    loading,
    error: error?.message,
  };
};
