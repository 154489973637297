import React from 'react';

/* eslint-disable-next-line */
export const IconHome = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
      <image
        width="32"
        height="32"
        style={{ transform: 'scale(0.9)' }}
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADUAAAAuCAMAAAC/KaEaAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABQVBMVEUAAAB4v1h4xlt5xVt4 x1p6v1p4xlt4x1twv2B6xl14xlt4xVx2v1t4xVt3xVx4xVp4x1h3w1lAv0B6xVp5xlx2v1J4xlt5 xll5xVp3xVt2xVt7xFh4xVx4x1tqv1V5x1t5xlx2xVt5xlp5xlx6yVp4xVt3xlpzzFl0xVd4xVx3 xVp3x1t4x1p5xlp4xVqAv2B4xlx4xlt3yFt5x1x4xVx5xluAv0B3xlx5x1tzv1l3xlt4xlx3xVp6 x1t4xlx6xVd4xVp3xVt4xlt7yFxwz1B4xlt5xlt1xVp4x1x4x1t4w1x4x1t4x1pwv1B4xlt4xlxv t1R5xlx4xlxlpUp4xlV5xlt3wlpzv1h4xFuAv1V4xlx3x1p2xFp5xlN1xVV5xlt1wllsr1B0v1l3 xlpyuVR4x1xYl0B5w1t1wVl4xlv///95LwgZAAAAaXRSTlMAILvvfzDfnxBH48Mcc++IIDwEm3Qc t3DzXIw0gJcMO9tU88tH68sULHes60SLgBC3+2un93AEi4MUz0h8l+MsYO/XUxDb7zB/x0B7nxCz 03zTJDAk29vfqAzbp2woMI/nUO+QTMMgTNcVyr7xAAAAAWJLR0RqJWKVDgAAAAlwSFlzAAALEwAA CxMBAJqcGAAAAAd0SU1FB+UDFwkyDOskFrIAAAGDSURBVEjH7dVnU8JAEAbgDeKJoBDQ2MXeFUEU RVHAig0Ve+/l/v8f8DYqCZCyYfymOwOT27uH2XnnMgCYlOSqcYPTqmWc8zqPM1Tv5Vi+BieoEYlf fJhER25EARmHZEGiCTUhagZQWvChlYQUlzja1q4+dyCjRCmpc3V+r7qQdYftUA+G1ysX1339Yj1g k8ngkDg0PKLrjDLbKMdwoPHSgdQoJybNw5tCFClvT0dFNzZjgsJxsTs7V7mRmMdfWzBEyUW8D0uG eylky0mTxFfSJnNksmJ3tSKTIIbHZDArwyjXcIToukW88oY4sbmlb+UQbSfAqnbUd2dXCy+gNvaA oIq3ct/HHSieD+FKOcA7niWpw5j4OhIvuITXlRUYSR2fYJReCU6/7jhRgYLsDM45vxDXlargMs/5 FcjXKeyRFcBNvPDTK1G3d1pVKl3p1T3XFfMQ1YNe8UeieqpWPctqZZypiHbyX/0F9UJUxvWr6jVj WTljRajq1FuZev+wN/7iP+InoCULbmMahPwAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDMtMjNU MDk6NDk6NTIrMDA6MDBRMO1DAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTAzLTIzVDA5OjQ5OjU0 KzAwOjAwQ71gxQAAAABJRU5ErkJggg=="
      />
    </svg>
  );
};

export default IconHome;
