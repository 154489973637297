import { gql, useMutation } from '@apollo/client';

// *** CABLE CONNECTOR SECTION ***

export const DELETE_CABLE_CONNECTOR_QUERY = gql`
  mutation($cable_connector_id: String!) {
    deleteCableConnector(cable_connector_id: $cable_connector_id) {
      cable_connector_id
    }
  }
`;

export const useDeleteCableConnector = (objectTypeId: string) => {
  const [doDelete, result] = useMutation(DELETE_CABLE_CONNECTOR_QUERY, {
    variables: {
      cable_connector_id: objectTypeId,
    },
  });

  return {
    doDelete: () => doDelete().then((result) => !result.errors),
    loading: result.loading,
    error: result.error?.message,
  };
};

// *** CABLE TYPE SECTION ***

export const DELETE_CABLE_TYPE_QUERY = gql`
  mutation($cable_type_id: String!) {
    deleteCableType(cable_type_id: $cable_type_id) {
      cable_type_id
    }
  }
`;

export const useDeleteCableType = (objectTypeId: string) => {
  const [doDelete, result] = useMutation(DELETE_CABLE_TYPE_QUERY, {
    variables: {
      cable_type_id: objectTypeId,
    },
  });

  return {
    doDelete: () => doDelete().then((result) => !result.errors),
    loading: result.loading,
    error: result.error?.message,
  };
};

// *** CABLE LENGTH SECTION ***

export const DELETE_CABLE_LENGHT_QUERY = gql`
  mutation($cable_length_id: String!) {
    deleteCableLength(cable_length_id: $cable_length_id) {
      cable_length_id
    }
  }
`;

export const useDeleteCableLength = (objectTypeId: string) => {
  const [doDelete, result] = useMutation(DELETE_CABLE_LENGHT_QUERY, {
    variables: {
      cable_length_id: objectTypeId,
    },
  });

  return {
    doDelete: () => doDelete().then((result) => !result.errors),
    loading: result.loading,
    error: result.error?.message,
  };
};
