import { gql, useMutation } from '@apollo/client';

export const RESET_PASSWORD_QUERY = gql`
  mutation($access_token: String!, $password: String!) {
    resetPassword(
      resetPasswordInfo: { access_token: $access_token, password: $password }
    ) {
      user_id
    }
  }
`;
export interface ResetPasswordResultInterface {
  resetPassword: {
    user_id: string;
  };
}

export const useResetPassword = (access_token?: string) => {
  const [resetPassword, result] = useMutation<ResetPasswordResultInterface>(
    RESET_PASSWORD_QUERY,
    { variables: { access_token } }
  );
  return {
    resetPassword: (password: string) =>
      resetPassword({ variables: { password } }),
    ...result,
  };
};

export const INIZIALIZE_RESET_PASSWORD_QUERY = gql`
  mutation($user_id: String!) {
    initializeResetPassword(user_id: $user_id) {
      user_id
    }
  }
`;

export interface InizializeResetPasswordResultInterface {
  initializeResetPassword: {
    user_id: string;
  };
}

export const useInizializeResetPassword = (user_id?: string) => {
  const [
    inizializeReset,
    result,
  ] = useMutation<InizializeResetPasswordResultInterface>(
    INIZIALIZE_RESET_PASSWORD_QUERY,
    { variables: { user_id } }
  );
  return {
    inizializeReset: () => inizializeReset(),
    ...result,
  };
};
