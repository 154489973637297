import { useQuery, gql } from '@apollo/client';
import { OptionsArgs } from '@eypconfig/shared/types';

export const QUERY_LABEL_IMAGES = gql`
  query(
    $brand: String
    $motor_size: String
    $technology: String
    $product_classification: String
    $type_code: String
  ) {
    label(
      brand: $brand
      motor_size: $motor_size
      technology: $technology
      product_classification: $product_classification
      type_code: $type_code
    ) {
      product_label_image
      package_label_image
      multi_package_label_image
    }
  }
`;

export interface QueryLabelImagesResponseInterface {
  label: {
    product_label_image?: string;
    package_label_image?: string;
    multi_package_label_image?: string;
  };
}

export const useLabelImages = (filter: OptionsArgs) => {
  const status = useQuery<QueryLabelImagesResponseInterface | null>(
    QUERY_LABEL_IMAGES,
    {
      variables: {
        ...filter,
        brand: filter?.brand?.split('_')?.join(' ')?.toUpperCase(),
      },
    }
  );
  return status;
};
