import { gql, useMutation } from '@apollo/client';

export const UPDATE_FILE_OTHER_ACC_MUTATION = gql`
  mutation(
    $signed_document_uri: String
    $date_sign: DateTime
    $reference: String
    $other_accessory_reference_id: String!
  ) {
    updateOtherAccessoryReference(
      otherAccessoryReference: {
        other_accessory_reference_id: $other_accessory_reference_id
        date_sign: $date_sign
        signed_document_uri: $signed_document_uri
        reference: $reference
      }
    ) {
      signed_document_uri
      date_sign
    }
  }
`;

export interface UpdateFileOtherAccResultInterface {
  otherAccessoryReference: {
    signed_document_uri?: string;
    date_sign?: Date;
  };
}

export const useUpdateFileOtherAcc = (other_accessory_reference_id: string) => {
  const [
    updateFileOtherAcc,
    result,
  ] = useMutation<UpdateFileOtherAccResultInterface>(
    UPDATE_FILE_OTHER_ACC_MUTATION,
    {
      variables: {
        other_accessory_reference_id,
      },
    }
  );
  return {
    updateFileOtherAcc: (signed_document_uri: string) =>
      updateFileOtherAcc({
        variables: { signed_document_uri, date_sign: new Date() },
      }),
    ...result,
  };
};

export interface OtherAccReferenceUpdateInterface {
  reference?: string;
}

export const useUpdateReferenceOtherAcc = (
  other_accessory_reference_id: string
) => {
  const [
    updateOtherAcc,
    result,
  ] = useMutation<UpdateFileOtherAccResultInterface>(
    UPDATE_FILE_OTHER_ACC_MUTATION,
    {
      variables: {
        other_accessory_reference_id,
      },
    }
  );
  return {
    updateOtherAcc: (OtherAccData: OtherAccReferenceUpdateInterface) =>
      updateOtherAcc({
        variables: OtherAccData,
      }),
    ...result,
  };
};
