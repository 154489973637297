import { useQuery, gql, useLazyQuery } from '@apollo/client';
import {
  PaginateResultInterface,
  PaginationArgs,
  SelectType,
} from '@eypconfig/shared/types';
import { useLazyOptionsMotors } from './motors.hook';

export const QUERY_OPTIONS_OTHER_ACCESSORIES = gql`
  query(
    $propertyToUnique: String!
    $textSearch: String
    $lableTemplate: String
    $page: Int
    $offset: Int
  ) {
    options: getOtherAccessoriesSelectOptions(
      propertyToUnique: $propertyToUnique
      textSearch: $textSearch
      lableTemplate: $lableTemplate
      page: $page
      offset: $offset
    ) {
      count
      page
      offset
      data {
        label
        value
      }
    }
  }
`;

export type OtherAccessoriesOptionsSelect = {
  options: PaginateResultInterface<SelectType>;
};

export const useOptionsOtherAccessories = (
  filter: {
    propertyToUnique: string;
    lableTemplate?: string;
    textSearch?: string;
  } & PaginationArgs
) => {
  const status = useQuery<OtherAccessoriesOptionsSelect | null>(
    QUERY_OPTIONS_OTHER_ACCESSORIES,
    {
      variables: filter,
    }
  );
  return status;
};

export const useLazyOptionsOtherAccessories = (
  filter: {
    propertyToUnique: string;
    lableTemplate?: string;
    textSearch?: string;
  } & PaginationArgs
) => {
  const otherAccessoryStandardOptions = useLazyQuery<OtherAccessoriesOptionsSelect | null>(
    QUERY_OPTIONS_OTHER_ACCESSORIES,
    {
      variables: filter,
    }
  );
  const motorsOptions = useLazyOptionsMotors({
    ...filter,
    propertyToUnique: 'type_motor_code',
    lableTemplate: '{{ type_motor }}',
  });
  return filter.propertyToUnique === 'motor_type'
    ? motorsOptions
    : otherAccessoryStandardOptions;
};
