import React, { useMemo, useRef } from 'react';
import './black-table.scss';
import Text from '../text/text';
import { AnimatePresence, motion } from 'framer-motion';

export interface BlackTableDescription<DataType> {
  headerName: string;
  headerComponent?: () => JSX.Element;
  component: (row: DataType) => JSX.Element;
  percSize?: number;
}

export interface BlackTableProps<DataType> {
  dataSource?: Array<DataType>;
  colDescription?: Array<BlackTableDescription<DataType>>;
  keyProp?: keyof DataType;
  onBodyScrollToBottom?: () => void;
  unsetMaxHeight?: boolean;
}

/**
 * Throttle scroll events
 * @param throttleBy number
 * @returns event handler
 */
export function useThrottle(
  throttleBy = 500
): (
  callBack: (value: React.UIEvent<HTMLDivElement, UIEvent>) => void
) => React.UIEventHandler<HTMLDivElement> {
  const prevTimeout = useRef<NodeJS.Timeout>(null);
  return (callBack) => (event) => {
    event.preventDefault();
    if (prevTimeout.current) {
      clearTimeout(prevTimeout.current);
    }
    prevTimeout.current = setTimeout(() => {
      callBack(event);
    }, throttleBy);
  };
}

export const BlackTable = <DataType,>({
  dataSource,
  colDescription,
  keyProp,
  onBodyScrollToBottom,
  unsetMaxHeight,
}: BlackTableProps<DataType>) => {
  const throttle = useThrottle(200);

  const colDescriptionSizes = useMemo(() => {
    const sizes = [];
    const aviableSpacePerc =
      colDescription?.reduce((acc, { percSize }) => {
        if (percSize) {
          return acc - percSize;
        }
        return acc;
      }, 100) || 100;

    const normalSize = colDescription
      ? aviableSpacePerc / colDescription.length
      : aviableSpacePerc;

    for (const { percSize } of colDescription || []) {
      if (percSize) {
        sizes.push(percSize);
      } else {
        sizes.push(normalSize);
      }
    }
    return sizes;
  }, [colDescription]);
  const minRows = 1;
  const emptyRows = dataSource ? minRows - dataSource.length : minRows;

  const getMinRows = () => {
    let rows: Array<JSX.Element> = [];

    const EmptyRowCmp = (number) => (
      <div key={number} className="way-black-table__body__row">
        {colDescription
          ? colDescription.map((_, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: `${colDescriptionSizes[index]}%`,
                    maxWidth: '100%',
                    flex: `1 1 ${colDescriptionSizes[index]}%`,
                  }}
                />
              );
            })
          : null}
      </div>
    );

    for (let i = 0; i < emptyRows; i++) {
      rows = [...rows, EmptyRowCmp(i)];
    }

    return rows;
  };

  return (
    <AnimatePresence>
      <div className="way-black-table">
        <div className="way-black-table__header">
          {colDescription
            ? colDescription.map(({ headerName, headerComponent }, index) => {
                return (
                  <div
                    key={index}
                    className="way-black-table__header__item"
                    style={{
                      width: `${colDescriptionSizes[index]}%`,
                      maxWidth: '100%',
                      flex: `1 1 ${colDescriptionSizes[index]}%`,
                    }}
                  >
                    {headerComponent ? (
                      headerComponent()
                    ) : (
                      <Text type="column-title" color="white">
                        {headerName}
                      </Text>
                    )}
                  </div>
                );
              })
            : null}
        </div>
        <div
          className="way-black-table__body"
          onScroll={
            // Register the event only if is needed
            onBodyScrollToBottom
              ? throttle((event) => {
                  // Avoid issues with table in table
                  event.stopPropagation();
                  const target = event.target as EventTarget & HTMLDivElement;
                  const { height } = target.getBoundingClientRect();
                  const lastTenPerc = (height / 100) * 5;
                  const triggerPoint = height - lastTenPerc;
                  if (target.scrollTop >= triggerPoint) {
                    onBodyScrollToBottom();
                  }
                })
              : null
          }
          style={{
            maxHeight: unsetMaxHeight ? 'none' : 'calc(100vh - 64px)',
            overflow: 'auto',
          }}
        >
          <AnimatePresence>
            {dataSource
              ? dataSource.map((data) => {
                  const key = keyProp
                    ? String(data[keyProp])
                    : JSON.stringify(data);

                  return (
                    <motion.div
                      key={key}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="way-black-table__body__row"
                    >
                      {colDescription
                        ? colDescription.map(({ component }, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  width: `${colDescriptionSizes[index]}%`,
                                  maxWidth: '100%',
                                  flex: `1 1 ${colDescriptionSizes[index]}%`,
                                }}
                              >
                                {component(data)}
                              </div>
                            );
                          })
                        : null}
                    </motion.div>
                  );
                })
              : null}
          </AnimatePresence>
          {emptyRows > 0 ? getMinRows() : null}
        </div>
      </div>
    </AnimatePresence>
  );
};

export default BlackTable;
