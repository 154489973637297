import { Middleware } from 'redux';
import { KeyPairSotreInterface } from '../types';
import { AuthorizationeStoreType } from './authorization-redux.module';
import {
  AuthorizationActions,
  AuthorizationActionsType,
  successLoginAction,
  errorLoginAction,
  successLogoutAction,
  errorLogoutAction,
  successRefreshTokenAction,
  errorRefreshTokenAction,
  LogoutAction,
} from './authorization.actions';

export const makeAuthorizationMiddlewares = (
  storeKeyPair?: KeyPairSotreInterface
) => {
  // Get the default headers for the requests
  const getDefaultHeaders = async () => {
    const headers: HeadersInit = new Headers();
    const auth_token = await storeKeyPair?.get();
    headers.set('content-type', 'application/json');
    if (auth_token) {
      headers.set('authorization', `Bearer ${auth_token}`);
    }
    return headers;
  };

  const doLoginMiddleware: Middleware<unknown, AuthorizationeStoreType> = (
    store
  ) => (next) => async (action: AuthorizationActionsType) => {
    next(action);

    // On logout
    if (action.type === AuthorizationActions.SET_LOGOUT) {
      const { auth } = store.getState();
      const config = auth.config;
      if (config.transport === 'header') {
        await storeKeyPair?.forget();
      }
      localStorage.removeItem('user-is-logged-in');
    }

    // On login
    if (action.type === AuthorizationActions.SET_LOGIN) {
      const { auth } = store.getState();
      const config = auth.config;
      if (config.transport === 'header') {
        const { refresh_token, authorization_token } = action.payload;
        await storeKeyPair?.store({
          refresh_token,
          authorization_token,
        });
      }
      if (action.payload.rememberMe) {
        localStorage.setItem('user-is-logged-in', 'true');
      }
    }

    // On refresh success
    if (action.type === AuthorizationActions.SET_REFRESH) {
      const { auth } = store.getState();
      const config = auth.config;
      if (config.transport === 'header') {
        const { refresh_token, authorization_token } = action.payload;
        await storeKeyPair?.store({
          refresh_token,
          authorization_token,
        });
      }
    }

    // On refresh error
    if (action.type === AuthorizationActions.ERROR_REFRESH) {
      store.dispatch(LogoutAction());
    }

    // Make refresh request
    if (action.type === AuthorizationActions.DO_REFRESH) {
      const { auth } = store.getState();
      const config = auth.config;
      let refresh_token = null;
      let authorization_token = null;

      if (config.transport === 'header') {
        const tokens = await storeKeyPair?.get();
        refresh_token = tokens.refresh_token;
        authorization_token = tokens.authorization_token;
      }

      const body =
        refresh_token && authorization_token
          ? JSON.stringify({ refresh_token })
          : null;

      const url = `${config.baseUri}/auth/refresh?transport=${config.transport}`;

      const response = await fetch(url, {
        method: 'post',
        body,
        headers: await getDefaultHeaders(),
      });

      if (response.status === 200) {
        if (config.transport === 'header') {
          const body = await response.json();
          store.dispatch(successRefreshTokenAction(body));
        } else {
          store.dispatch(successRefreshTokenAction());
        }
      } else {
        store.dispatch(
          errorRefreshTokenAction({
            message: 'Refresh request failed',
            metadata: {},
          })
        );
      }
    }

    // Make logout request
    if (action.type === AuthorizationActions.DO_LOGOUT) {
      const { auth } = store.getState();
      const config = auth.config;

      if (config.transport === 'cookie') {
        const response = await fetch(
          `${config.baseUri}/auth/logout?transport=${config.transport}`,
          { method: 'post', headers: await getDefaultHeaders() }
        );
        if (response.status === 200) {
          store.dispatch(successLogoutAction());
        } else {
          store.dispatch(
            errorLogoutAction({
              message: 'Logout request failed',
              metadata: {},
            })
          );
        }
      } else {
        store.dispatch(successLogoutAction());
      }
    }

    // Make login request
    if (action.type === AuthorizationActions.DO_LOGIN) {
      const { auth } = store.getState();
      const config = auth.config;
      const { username, password, rememberMe } = action.payload;

      try {
        const response = await fetch(
          `${config.baseUri}/auth/login?transport=${config.transport}&type=${config.type}`,
          {
            method: 'post',
            body: JSON.stringify({ username, password }),
            headers: await getDefaultHeaders(),
          }
        );

        if (response.status === 200) {
          if (config.transport === 'header') {
            const resp = await response.json();
            const result = await storeKeyPair?.store(resp);
            if (result) {
              store.dispatch(successLoginAction({ ...resp, rememberMe }));
            } else {
              store.dispatch(
                errorLoginAction({
                  message:
                    'The save operation from the keyStore returned false',
                  metadata: {},
                })
              );
            }
          } else {
            store.dispatch(successLoginAction({ rememberMe }));
          }
        } else {
          console.error(
            'Unable to authenticate the user:\n',
            'Config:\n',
            config,
            'Url:\n',
            `${config.baseUri}/auth/login?transport=${config.transport}&type=${config.type}`
          );
          try {
            const body = await response.json();
            store.dispatch(errorLoginAction(body));
          } catch (error) {
            store.dispatch(
              errorLoginAction({
                message: 'Internal error contact the support',
                metadata: {},
              })
            );
          }
        }
      } catch (error) {
        store.dispatch(
          errorLoginAction({
            message: error.message,
            metadata: {},
          })
        );
      }
    }
  };

  return [doLoginMiddleware];
};
