import { useQuery, gql, useLazyQuery } from '@apollo/client';
import {
  MotorsSearchFilter,
  PaginateResultInterface,
  PaginationArgs,
} from '@eypconfig/shared/types';

const MOTORS_SEARCH_CONFIGURATION_QUERY = gql`
  query (
    $page: Int
    $offset: Int
    $reference: [String!]
    $referenceLike: String
    $brand: [String!]
    $brand_classification: [String!]
    $customer: [String!]
    $somfy_match: [String!]
    $technology: [String!]
    $type_motor: [String!]
    $nominal_voltage: [String!]
    $size_motor: [String!]
    $motor_head: [String!]
    $nominal_torque: [String!]
    $nominal_speed: [String!]
    $cable_type: [String!]
    $cable_lenght: [String!]
    $cable_connector_type: [String!]
    $packaging_type: [String!]
  ) {
    searchMotors(
      page: $page
      offset: $offset
      reference: $reference
      brand: $brand
      brand_classification: $brand_classification
      customer: $customer
      somfy_match: $somfy_match
      technology: $technology
      type_motor: $type_motor
      nominal_voltage: $nominal_voltage
      size_motor: $size_motor
      motor_head: $motor_head
      nominal_torque: $nominal_torque
      nominal_speed: $nominal_speed
      cable_type: $cable_type
      cable_lenght: $cable_lenght
      cable_connector_type: $cable_connector_type
      packaging_type: $packaging_type
      referenceLike: $referenceLike
      range_for_configuration: true
    ) {
      count
      offset
      page
      data {
        __typename
        motor_reference_id
        designation
        designation_0
        designation_1
        technology
        product_image
        brand_image
        brand_classification_image
        torque_speed
        nr_of_end_limits
        type_lsu
        capacity_of_lsu
        adjustment_type
        nominal_torque
        cable_type
        certification_ce
        certification_vde
        certification_nf
        certification_cmim
        certification_pai
        certification_saber
        made_in
        brand
        motor_head
        motor_head_image
        packaging_type
        packaging_brand
        packaging_nr_of_motors
        packaging_nr_of_brackets
        packaging_nr_of_adapters
        packaging_nr_of_controls
        packaging_nr_of_instruction_leaflets
        instruction_logo
        somfy_match
        photo_low_resolution
        ean_code
        nominal_speed
        nominal_voltage
        rated_power
        rated_current
        l3_max
        l1_max
        l2_plus_minut_three_mm
        transmission_frequency
        coding
        memory_capacity
        range
        dimension_image
        euro_pallet_image
        technology_image
        cable_connector_image
        type_motor
        type_motor_code
        package_label_image
        motor_label_image
        new_customer_from_configuration
        motor_barcode
        noise_level
        weight
        designation
        basic_crown_for_tube
        basic_crown_for_tube_size
        tube_material
        thermal_time
        health_test
        electrical_safety
        electromagnetic_compatibility_1
        electromagnetic_compatibility_2
        electromagnetic_compatibility_3
        electromagnetic_compatibility_4
        electromagnetic_compatibility_5
        electromagnetic_compatibility_6
        configuration_a
        packaging_quantity
        packaging_graphic_layout_choice
        packaging_dimension_l
        packaging_dimension_w
        packaging_dimension_h
        packaging_weight
        multi_pack_type
        multi_pack_brand
        multi_pack_dimensions_image
        multi_pack_print_finiture
        multi_pack_dim_l
        multi_pack_dim_w
        multi_pack_dim_h
        multi_pack_weight
        pallet_type
        pallet_empty_dimensions
        pallet_empty_dimensions_image
        pallet_number_pcs_layers
        pallet_number_of_packages
        pallet_dimensions_l
        pallet_dimensions_w
        pallet_dimensions_h
        pallet_dimensions_image
        pallet_weight
        multi_package_label_image
        instruction_leaflets_image
      }
    }
  }
`;

export interface MotorConfigurationInterface {
  __typename: 'MotorReference';
  motor_reference_id: string;
  designation: string;
  designation_0?: string;
  designation_1?: string;
  technology: string;
  product_image?: string;
  brand_classification_image?: string;
  brand_image?: string;
  torque_speed?: string;
  nr_of_end_limits?: string;
  type_lsu?: string;
  capacity_of_lsu?: string;
  adjustment_type?: string;
  nominal_torque?: string;
  cable_type?: string;
  certification_ce?: boolean;
  certification_vde?: boolean;
  certification_nf?: boolean;
  certification_cmim?: boolean;
  certification_pai?: boolean;
  certification_saber?: boolean;
  made_in?: string;
  brand?: string;
  somfy_match?: string;
  motor_head?: string;
  motor_head_image?: string;
  packaging_type?: string;
  packaging_brand?: string;
  packaging_nr_of_motors?: string;
  packaging_nr_of_brackets?: string;
  packaging_nr_of_adapters?: string;
  packaging_nr_of_controls?: string;
  packaging_nr_of_instruction_leaflets?: string;
  instruction_logo?: string;
  photo_low_resolution?: string;
  ean_code?: string;
  nominal_speed?: string;
  nominal_voltage?: string;
  rated_power?: string;
  rated_current?: string;
  l3_max?: string;
  l1_max?: string;
  l2_plus_minut_three_mm?: string;
  transmission_frequency?: string;
  coding?: string;
  memory_capacity?: string;
  range?: string;
  dimension_image?: string;
  euro_pallet_image?: string;
  technology_image: string;
  type_motor: string;
  type_motor_code: string;
  package_label_image?: string;
  motor_label_image?: string;
  new_customer_from_configuration?: string;
  motor_barcode?: string;
  noise_level?: string;
  weight?: string;
  basic_crown_for_tube?: string;
  basic_crown_for_tube_size?: string;
  tube_material?: string;
  thermal_time?: string;
  health_test?: string;
  electrical_safety?: string;
  electromagnetic_compatibility_1?: string;
  electromagnetic_compatibility_2?: string;
  electromagnetic_compatibility_3?: string;
  electromagnetic_compatibility_4?: string;
  electromagnetic_compatibility_5?: string;
  electromagnetic_compatibility_6?: string;
  configuration_a?: string;
  packaging_quantity?: string;
  packaging_graphic_layout_choice?: string;
  cable_connector_type?: string;
  packaging_dimension_l?: string;
  packaging_dimension_w?: string;
  packaging_dimension_h?: string;
  packaging_weight?: string;
  multi_pack_type?: string;
  multi_pack_brand?: string;
  multi_pack_dimensions_image?: string;
  multi_pack_print_finiture?: string;
  multi_pack_dim_l?: string;
  multi_pack_dim_w?: string;
  multi_pack_dim_h?: string;
  multi_pack_weight?: string;
  pallet_type?: string;
  pallet_empty_dimensions?: string;
  pallet_empty_dimensions_image?: string;
  pallet_number_pcs_layers?: string;
  pallet_number_of_packages?: string;
  pallet_dimensions_l?: string;
  pallet_dimensions_w?: string;
  pallet_dimensions_h?: string;
  pallet_dimensions_image?: string;
  pallet_weight?: string;
  cable_connector_image?: string;
  multi_package_label_image?: string;
  instruction_leaflets_image?: string;
}

export interface SearchMotorsConfigurationQueryResultInterface {
  searchMotors: PaginateResultInterface<MotorConfigurationInterface>;
}

export const useSearchMotorsConfiguration = (
  filters: MotorsSearchFilter & PaginationArgs
) => {
  const data = useQuery<SearchMotorsConfigurationQueryResultInterface | null>(
    MOTORS_SEARCH_CONFIGURATION_QUERY,
    {
      variables: filters,
    }
  );

  return data;
};

export const useLazySearchMotorsConfiguration = (
  filters: MotorsSearchFilter & PaginationArgs
) => {
  const data =
    useLazyQuery<SearchMotorsConfigurationQueryResultInterface | null>(
      MOTORS_SEARCH_CONFIGURATION_QUERY,
      {
        variables: filters,
      }
    );

  return data;
};
