import { useQuery, gql, useLazyQuery } from '@apollo/client';
import {
  CatalogFilter,
  PaginateResultInterface,
  PaginationArgs,
} from '@eypconfig/shared/types';
import { ControlCatalogueInterface } from '.';
import { AdapterCatalogueInterface } from './adapters-search-catalogue.hook';
import { BracketCatalogueInterface } from './brackets-search-catalogue.hook';
import { MotorCatalogueInterface } from './motors-search-catalogue.hook';
import { OtherAccessorieCatalogueInterface } from './other-accessories-search-catalogue.hook';

export const QUERY_CATALOGUE_SEARCH = gql`
  query(
    $page: Int
    $offset: Int
    $reference: [String!]
    $customer: [String!]
    $brand: [String!]
    $somfy_match: [String!]
  ) {
    catalogueSearch(
      page: $page
      offset: $offset
      reference: $reference
      customer: $customer
      somfy_match: $somfy_match
      brand: $brand
    ) {
      count
      offset
      page
      data {
        __typename
        ... on BracketReference {
          bracket_reference_id
          reference
          designation
          bracket_image
          motor_size_bracket: motor_size
          motor_type
          somfy_match
          applications
          motor_head_bracket: motor_head
          package_type
          three_d_video
          catalogue
          product_technical_sheet
          ce_declaration
          vde_certification
          nf_certification
          compare_declaration
          photo_high_resolution
          photo_low_resolution
          dimensional_drawings
          mini_programming_guide
          video_installation
          nominal_torque
          made_in_bracket: made_in
          package_brand
          nr_of_brackets
        }
        ... on OtherAccessoryReference {
          other_accessory_reference_id
          reference
          designation
          other_accessory_image
          motor_type
          rolling_tube_other_accessory: rolling_tube
          somfy_match
          applications
          package_type
          motor_size_other_accessory: motor_size
          three_d_video
          catalogue
          product_technical_sheet
          ce_declaration
          vde_certification
          nf_certification
          compare_declaration
          photo_high_resolution
          photo_low_resolution
          dimensional_drawings
          mini_programming_guide
          video_installation
          motor_head_other_accessory: motor_head
          nominal_torque
          made_in_bracket: made_in
          package_brand
          nr_of_other_acc
        }
        ... on AdapterReference {
          adapter_reference_id
          reference
          designation
          adapter_image
          rolling_tube_adapter: rolling_tube
          somfy_match
          applications
          motor_type
          package_type
          motor_size_adapter: motor_size
          three_d_video
          catalogue
          product_technical_sheet
          ce_declaration
          vde_certification
          nf_certification
          compare_declaration
          photo_high_resolution
          photo_low_resolution
          dimensional_drawings
          mini_programming_guide
          video_installation
          nominal_torque
          made_in_bracket: made_in
          package_brand
          nr_of_adapters
        }
        ... on ControlReference {
          __typename
          reference
          designation
          designation_1
          short_designation
          remote_control_class
          protection_rate
          working_temperature
          storage_temperature
          battery_type
          certification_ce
          certification_vde
          certification_nf
          certification_cmim
          certification_pai
          certification_saber
          vertical_screen
          zebra_screen
          rolling_shutter
          lateral_arms_awning
          awning_with_arms
          casette_awning
          technology
          transmission_frequence
          range
          made_in_control: made_in
          brand
          brand_image
          product_image
          somfy_match
          package_type
          number_of_controls
          number_of_wall_support
          number_of_instruction_leaflets
          instruction_languages
          instructions_logo_address
          image_without_graphics_on_cover
          image_with_graphics_on_cover
          control_type
          control_reference_id
          dimension_drawing_image
          three_d_video
          catalogue
          product_technical_sheet
          number_of_instruction_leaflets
          warning_and_instructions_leaflets
          ce_declaration
          vde_certification
          nf_certification
          compare_declaration
          photo_high_resolution
          photo_low_resolution
          dimensional_drawings
          mini_programming_guide
          video_installation
          package_brand
          brand_address
        }
        ... on MotorReference {
          motor_reference_id
          reference
          designation
          designation_0
          designation_1
          technology
          product_image
          complete_product_image
          brand_image
          torque_speed
          nr_of_end_limits
          type_lsu
          adjustment_type
          nominal_torque
          cable_type
          certification_ce
          certification_vde
          certification_nf
          certification_cmim
          certification_pai
          certification_saber
          made_in
          brand
          motor_head
          packaging_type
          packaging_brand
          packaging_nr_of_motors
          packaging_nr_of_brackets
          packaging_nr_of_adapters
          packaging_nr_of_controls
          packaging_nr_of_instruction_leaflets
          packaging_nr_of_other_accessories
          instruction_logo
          somfy_match
          photo_low_resolution
          ean_code
          nominal_speed
          nominal_voltage
          rated_power
          rated_current
          l3_max
          dimension_image
          euro_pallet_image
          technology_image
          type_motor
          type_motor_code
          package_label_image
          motor_label_image
          three_d_video
          catalogue
          product_technical_sheet
          warning_and_instruction_leafsets
          ce_declaration
          vde_certification
          nf_certification
          compare_declaration
          photo_high_resolution
          dimensional_drawings
          mini_programming_guide
          video_installation
          obstacle_detection
          freez_detection
          end_limit_setting_tool
          transmission_frequency
          brand_and_address
          instruction_languages
        }
      }
    }
  }
`;

export const QUERY_CATALOGUE_COUNT = gql`
  query {
    catalogueSearch {
      count
    }
  }
`;

export type SearchAllCatalogBracketType = Omit<
  BracketCatalogueInterface,
  'motor_size' | 'motor_head' | 'made_in'
> & {
  motor_size_bracket: BracketCatalogueInterface['motor_size'];
  motor_head_bracket: BracketCatalogueInterface['motor_head'];
  made_in_bracket: BracketCatalogueInterface['made_in'];
};

export type SearchAllCatalogOtherAccessoryType = Omit<
  OtherAccessorieCatalogueInterface,
  'rolling_tube' | 'motor_size' | 'motor_head' | 'made_in'
> & {
  rolling_tube_other_accessory: OtherAccessorieCatalogueInterface['rolling_tube'];
  motor_size_other_accessory: OtherAccessorieCatalogueInterface['motor_size'];
  motor_head_other_accessory: OtherAccessorieCatalogueInterface['motor_head'];
  made_in_other_accessory: OtherAccessorieCatalogueInterface['made_in'];
};

export type SearchAllCatalogAdapterType = Omit<
  AdapterCatalogueInterface,
  'rolling_tube' | 'motor_size' | 'made_in'
> & {
  rolling_tube_adapter: AdapterCatalogueInterface['rolling_tube'];
  motor_size_adapter: AdapterCatalogueInterface['motor_size'];
  made_in_adapter: AdapterCatalogueInterface['made_in'];
};

export type SearchAllCatalogControlType = Omit<
  ControlCatalogueInterface,
  'made_in'
> & {
  made_in_control: ControlCatalogueInterface['made_in'];
};

export type SearchAllCatalogInterface =
  | MotorCatalogueInterface
  | SearchAllCatalogControlType
  | SearchAllCatalogAdapterType
  | SearchAllCatalogBracketType
  | SearchAllCatalogOtherAccessoryType;

export interface SearchAllCatalogQueryResultInterface {
  catalogueSearch: PaginateResultInterface<SearchAllCatalogInterface>;
}

export const useSearchAll = (filters: CatalogFilter & PaginationArgs) => {
  const data = useQuery<SearchAllCatalogQueryResultInterface | null>(
    QUERY_CATALOGUE_SEARCH,
    {
      variables: filters,
    }
  );

  return data;
};

export const useLazySearchAll = (filters: CatalogFilter & PaginationArgs) => {
  const data = useLazyQuery<SearchAllCatalogQueryResultInterface | null>(
    QUERY_CATALOGUE_SEARCH,
    {
      variables: filters,
    }
  );

  return data;
};

export interface CountAllCatalogQueryResultInterface {
  catalogueSearch: {
    count: number;
  };
}

export const useLazyCountAll = () => {
  const data = useLazyQuery<CountAllCatalogQueryResultInterface | null>(
    QUERY_CATALOGUE_COUNT
  );

  return data;
};
