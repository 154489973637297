import React from 'react';

/* eslint-disable-next-line */
export const IconClose = () => {
  return (
    <svg
      style={{ transform: 'scale(0.7)' }}
      width="40"
      height="40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20.4651" cy="19.5349" r="19.5349" fill="#8DD174" />
      <path
        d="M25.5505 14.8837L14.8838 25.5504M14.8838 14.8837l10.6667 10.6667"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconClose;
