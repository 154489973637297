import { gql, useMutation } from '@apollo/client';
import { AdaptersSearchFilter } from '@eypconfig/shared/types';
import { GetAdapterResultInterface } from '../backoffice/get';

export const BULK_UPDATE_ADAPTERS_MUTATION = gql`
  mutation (
    $filter: AdaptersSearchFilter!
    $adapterReference: AdaptersReferenceBulkUpdateInput!
  ) {
    bulkUpdateAdapters(filter: $filter, adapterReference: $adapterReference) {
      count
    }
  }
`;

export type BulkUpdateAdaptersInterface = Partial<
  Omit<GetAdapterResultInterface['getAdapterReference'], 'adapter_reference_id'>
>;

export interface BulkUpdateAdaptersResultInterface {
  bulkUpdateAdapters: {
    count?: number;
  };
}

export const useBulkUpdateAdapters = () => {
  const [bulkUpdateAdapters, result] =
    useMutation<BulkUpdateAdaptersResultInterface>(
      BULK_UPDATE_ADAPTERS_MUTATION
    );

  return {
    bulkUpdateAdapters: (
      filter: AdaptersSearchFilter,
      adapterReference: BulkUpdateAdaptersInterface
    ) =>
      bulkUpdateAdapters({
        variables: {
          filter,
          adapterReference,
        },
      }).then(({ data }) => data?.bulkUpdateAdapters?.count),
    ...result,
  };
};
