import React from 'react';
import './edit-button.scss';

export const editIcon = (
  <svg
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: 'scale(0.65)' }}
  >
    <path
      d="M15.1114 12.7217c.4909 0 .8889.3821.8889.8534 0 .4376-.3431.7983-.7852.8476l-.1037.0057H7.21018c-.49092 0-.88889-.382-.88889-.8533 0-.4376.34315-.7983.78522-.8476l.10367-.0058h7.90122z"
      fill="red"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.04007 10.2999L10.8466.730599C11.492.10083 12.4362-.14605 13.3214.0853933c.882.2306267 1.5682.9002237 1.8027 1.7541467.2336.85099-.0157 1.75921-.6566 2.3846L4.66094 13.7935c-.1139.1111-.25733.1902-.41473.2286l-3.13809.7655c-.650392.1587-1.242361-.4053-1.0820906-1.0308l.7845226-3.0622c.038163-.149.117449-.2853.229518-.3947zM13.405 2.27457c-.0733-.26692-.2843-.47279-.5509-.54249l-.0994-.01921c-.233-.02946-.4698.04773-.6409.21463l-9.63533 9.4014-.37244 1.4541 1.46133-.3567 9.63274-9.39906c.175-.17073.2573-.41234.2255-.65086l-.0206-.10181z"
      fill="red"
    />
  </svg>
);

/* eslint-disable-next-line */
export interface EditButtonProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  label?: string;
  onClick?: () => void;
  bold?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export function EditButton({
  label,
  onClick,
  bold,
  disabled,
  ...props
}: EditButtonProps) {
  return (
    <i
      className={`way-edit-button ${
        disabled ? 'way-edit-button_is_disabled' : ''
      }`}
      onClick={() => (onClick ? onClick() : null)}
      onKeyUp={(event) =>
        event.key === 'Enter' ? (onClick ? onClick() : null) : null
      }
      tabIndex={0}
      role="button"
      aria-pressed="true"
      aria-disabled={disabled}
      {...props}
    >
      {label ? <label className={bold ? 'bold' : ''}>{label}</label> : null}
      {editIcon}
    </i>
  );
}

export default EditButton;
