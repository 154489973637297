import React from 'react';

/* eslint-disable-next-line */
export const IconInfo = () => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#8DD174" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8867 6.20312c-.2161.22396-.5091.33594-.8789.33594-.36978 0-.66275-.11198-.87889-.33594-.21615-.22395-.32422-.5052-.32422-.84374 0-.34376.10937-.625.32812-.84376.21875-.21874.51042-.32812.87499-.32812.3646 0 .6563.10938.875.32812.2188.21876.3281.5.3281.84376 0 .33854-.108.61979-.3242.84374zm.2383 1.34376V16H8.90625V7.54688H11.125z"
        fill="#fff"
      />
    </svg>
  );
};

export default IconInfo;
