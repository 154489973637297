import { useEffect } from 'react';

export function useOnKeysPressed(
  keys: Array<string>,
  handler: (keycode: string) => unknown
) {
  useEffect(() => {
    const handleClick = (ev: KeyboardEvent) => {
      if (keys.includes(ev.key)) {
        handler(ev.key);
      }
    };

    document.addEventListener('keyup', handleClick);

    return () => {
      document.removeEventListener('keyup', handleClick);
    };
  }, [keys, handler]);
}
