import { gql, useMutation } from '@apollo/client';

export const ENABLE_ADAPTER_MUTATION = gql`
  mutation($motor_reference_id: String!) {
    enableMotorReference(
      motorsReference: { motor_reference_id: $motor_reference_id }
    ) {
      motor_reference_id
    }
  }
`;

export const useEnableAdapterReference = (motor_reference_id: string) => {
  const mutation = useMutation(ENABLE_ADAPTER_MUTATION, {
    variables: { motor_reference_id },
  });
  return mutation;
};
