import { gql, useMutation } from '@apollo/client';
import { InstructionItem } from '../../get';

export const INSTRUCTION_UPDATE_QUERY = gql`
  mutation($instructionItem: UpdateInstructionItemDto!) {
    updateInstruction(instructionItem: $instructionItem) {
      instruction_id
    }
  }
`;

export interface UpdateInstructionResponse {
  updateInstruction: {
    instruction_id: string;
  };
}

export type UpdateInstructionItem = Partial<
  Omit<InstructionItem, 'instruction_id'>
> &
  Pick<InstructionItem, 'instruction_id'>;

export const useUpdateInstruction = () => {
  const [mutate, { loading, error }] = useMutation<UpdateInstructionResponse>(
    INSTRUCTION_UPDATE_QUERY
  );
  return {
    update: ({
      objectTypeId,
      created_at,
      updated_at,
      ...items
    }: UpdateInstructionItem) =>
      mutate({
        variables: {
          instructionItem: {
            instruction_id: objectTypeId,
            ...items,
          },
        },
      }).then((result) => !result.errors),
    loading,
    error: error?.message,
  };
};
