import { gql, useMutation } from '@apollo/client';

export const DELETE_SOMFY_GROUP_SINGLE_QUERY = gql`
  mutation($somfy_group_id: String!) {
    deleteSomfyGroup(somfy_group_id: $somfy_group_id) {
      somfy_group_id
    }
  }
`;

export const useDeleteSomfyGroup = (objectTypeId: string) => {
  const [doDelete, result] = useMutation(DELETE_SOMFY_GROUP_SINGLE_QUERY, {
    variables: {
      somfy_group_id: objectTypeId,
    },
  });

  return {
    doDelete: () => doDelete().then((result) => !result.errors),
    loading: result.loading,
    error: result.error?.message,
  };
};
