import { gql, useMutation } from '@apollo/client';

export const UPDATE_FILE_BRACKET_MUTATION = gql`
  mutation(
    $signed_document_uri: String
    $date_sign: DateTime
    $reference: String
    $bracket_reference_id: String!
  ) {
    updateBracketReference(
      bracketReference: {
        bracket_reference_id: $bracket_reference_id
        date_sign: $date_sign
        signed_document_uri: $signed_document_uri
        reference: $reference
      }
    ) {
      signed_document_uri
      date_sign
    }
  }
`;

export interface UpdateFileBracketResultInterface {
  updateBracketReference: {
    signed_document_uri?: string;
    date_sign?: Date;
  };
}

export const useUpdateFileBracket = (bracket_reference_id: string) => {
  const [
    updateFileBracket,
    result,
  ] = useMutation<UpdateFileBracketResultInterface>(
    UPDATE_FILE_BRACKET_MUTATION,
    {
      variables: {
        bracket_reference_id,
      },
    }
  );
  return {
    updateFileBracket: (signed_document_uri: string) =>
      updateFileBracket({
        variables: { signed_document_uri, date_sign: new Date() },
      }),
    ...result,
  };
};

export interface BracketReferenceUpdateInterface {
  reference?: string;
}

export const useUpdateReferenceBracket = (bracket_reference_id: string) => {
  const [updateBracket, result] = useMutation<UpdateFileBracketResultInterface>(
    UPDATE_FILE_BRACKET_MUTATION,
    {
      variables: {
        bracket_reference_id,
      },
    }
  );
  return {
    updateBracket: (bracketData: BracketReferenceUpdateInterface) =>
      updateBracket({
        variables: bracketData,
      }),
    ...result,
  };
};
