import { useQuery, gql } from '@apollo/client';
import { SelectType } from '@eypconfig/shared/types';

export const CONTROLS_TECHNOLOGIES_QUERY = gql`
  query($brand_classification: String!) {
    getControlSelectOptions(
      propertyToUnique: "technology"
      brand_classification: $brand_classification
      range_for_configuration: true
    ) {
      data {
        label
        value
      }
    }
  }
`;

export interface ControlsTechnologiesOptions {
  getControlSelectOptions: {
    data: Array<SelectType>;
  };
}

export const useControlsTechnologies = (brand_classification: string) => {
  const status = useQuery<ControlsTechnologiesOptions>(
    CONTROLS_TECHNOLOGIES_QUERY,
    {
      variables: {
        brand_classification: brand_classification
          .split('_')
          .join(' ')
          .toUpperCase(),
      },
    }
  );

  return status;
};
