import { useLoginLogout } from '@f-technology-srl/react-authorization';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

export function PasswordlessLoginPage() {
  const { passwordlessLogin } = useLoginLogout();

  useEffect(() => {
    passwordlessLogin();
  }, [passwordlessLogin]);

  return <Redirect to="/" />;
}
