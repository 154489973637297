export function Centered({
  children,
  style,
  ...props
}: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  return (
    <div
      {...props}
      style={{ display: 'flex', alignItems: 'center', width: '100%', ...style }}
    >
      {children}
    </div>
  );
}

export default Centered;
