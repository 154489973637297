import React from 'react';

import './textarea.scss';

/* eslint-disable-next-line */
export interface TextareaProps {
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur?: (e) => void;
}

export function Textarea(
  props: TextareaProps &
    Omit<
      React.DetailedHTMLProps<
        React.TextareaHTMLAttributes<HTMLTextAreaElement>,
        HTMLTextAreaElement
      >,
      'onChange'
    >
) {
  const handleChange = (e) => {
    props.onChange(e.target.value);
  };

  return (
    <textarea
      {...props}
      placeholder={props.placeholder ? props.placeholder : ''}
      onChange={handleChange}
      value={props.value || ''}
      onBlur={props.onBlur}
    ></textarea>
  );
}

export default Textarea;
