import { gql, useQuery } from '@apollo/client';

export const DEFAULT_MOTOR_SELECTION = gql`
  query (
    $brand_classification: [String!]
    $size_motor: [String!]
    $type_motor: [String!]
    $technology: [String!]
    $motor_head: [String!]
    $nominal_voltage: [String!]
  ) {
    searchMotors(
      page: 0
      offset: 1
      brand_classification: $brand_classification
      size_motor: $size_motor
      type_motor: $type_motor
      technology: $technology
      motor_head: $motor_head
      nominal_voltage: $nominal_voltage
      range_for_configuration: true
    ) {
      data {
        reference
        __typename
        video_installation
        tube_color_image
        tube_hexdecimal_code
        tube_color
        plastic_cap_color_hex_code
        plastic_cap_color
        packaging_print_finiture
        brand
        product_image
        brand_image
        motor_head
        nr_of_end_limits
        end_limit_setting_tool
        adjustment_type
        obstacle_detection
        freez_detection
        nominal_voltage
        system_of_protection
        insulation_class
        noise_level
        temperature_working_range
        multi_pack_dimensions_image
        complete_product_image
        certification_ce
        certification_vde
        certification_nf
        certification_cmim
        certification_pai
        certification_saber
        made_in
        activation
        parallel_connection
        radio_compatibility
        cable_connector_image
        cable_type
        cable_type_image
        cable_number_of_wires
        cable_wire_section
        cable_lenght
        cable_color_type
        cable_tipology
        vertical_screens
        vertical_screens_image
        zebra_screen
        zebra_screen_image
        rolling_shutters
        rolling_shutters_image
        leteral_arms_awnings
        leteral_arms_awnings_image
        awnings_with_arms
        awnings_with_arms_image
        cassette_awnings
        cassette_awnings_image
        cable_connector
        cable_connector_name
        designation_1
      }
    }
  }
`;

export interface DefaultMotorSelectionFilter {
  brand_classification?: string;
  size_motor?: string | Array<string>;
  type_motor?: string | Array<string>;
  technology?: string | Array<string>;
  motor_head?: string | Array<string>;
  nominal_voltage?: string | Array<string>;
}

export interface DefaultMotorSelectionResult {
  searchMotors: {
    data: Array<{
      __typename: string;
      designation_1?: string;
      video_installation?: string;
      tube_color_image?: string;
      tube_hexdecimal_code?: string;
      tube_color?: string;
      plastic_cap_color_hex_code?: string;
      plastic_cap_color?: string;
      packaging_print_finiture?: string;
      brand?: string;
      product_image?: string;
      brand_image?: string;
      motor_head?: string;
      nr_of_end_limits?: string;
      end_limit_setting_tool?: string;
      adjustment_type?: string;
      obstacle_detection?: boolean;
      freez_detection?: boolean;
      nominal_voltage?: string;
      system_of_protection?: string;
      insulation_class?: string;
      noise_level?: string;
      temperature_working_range?: string;
      certification_ce: boolean;
      certification_vde: boolean;
      certification_nf: boolean;
      certification_cmim: boolean;
      certification_pai: boolean;
      certification_saber: boolean;
      made_in: string;
      activation?: string;
      parallel_connection?: string;
      radio_compatibility?: string;
      cable_connector_image?: string;
      cable_type?: string;
      cable_type_image?: string;
      cable_number_of_wires?: number;
      cable_wire_section?: string;
      cable_lenght?: string;
      cable_color_type?: string;
      cable_tipology?: string;
      vertical_screens?: boolean;
      vertical_screens_image?: string;
      zebra_screen?: boolean;
      zebra_screen_image?: string;
      rolling_shutters?: boolean;
      rolling_shutters_image?: string;
      leteral_arms_awnings?: boolean;
      leteral_arms_awnings_image?: string;
      awnings_with_arms?: boolean;
      awnings_with_arms_image?: string;
      cassette_awnings?: boolean;
      cassette_awnings_image?: string;
      cable_connector?: boolean;
      cable_connector_name?: string;
      multi_pack_dimensions_image?: string;
      complete_product_image?: string;
      reference: string;
    }>;
  };
}

export const useDefaultMotorSelection = (
  options: DefaultMotorSelectionFilter
) => {
  const result = useQuery<DefaultMotorSelectionResult>(
    DEFAULT_MOTOR_SELECTION,
    {
      variables: {
        brand_classification: options.brand_classification
          ?.split('_')
          ?.join(' ')
          ?.toUpperCase(),
        size_motor: options.size_motor,
        type_motor: options.type_motor,
        technology: options.technology,
        motor_head: options.motor_head,
        nominal_voltage: options.nominal_voltage,
      },
    }
  );
  return {
    ...result,
    data: result?.data?.searchMotors?.data?.[0],
  };
};
