import { gql, useMutation } from '@apollo/client';
import { PackageItem } from '../../get';

export const PACKAGE_UPDATE_QUERY = gql`
  mutation($packageItem: UpdatePackageItemDto!) {
    updatePackage(packageItem: $packageItem) {
      package_id
    }
  }
`;

export interface updatePackageResponse {
  updatePackage: {
    package_id: string;
  };
}

export type UpdatePackageItem = Partial<Omit<PackageItem, 'package_id'>> &
  Pick<PackageItem, 'package_id'>;

export const useUpdatePackage = () => {
  const [mutate, { loading, error }] = useMutation(PACKAGE_UPDATE_QUERY);
  return {
    update: ({
      objectTypeId,
      created_at,
      updated_at,
      ...items
    }: UpdatePackageItem) =>
      mutate({
        variables: {
          packageItem: {
            package_id: objectTypeId,
            ...items,
          },
        },
      }).then((result) => !result.errors),
    loading,
    error: error?.message,
  };
};
