import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Button, { buttonType } from '../button/button';
import { Checkbox } from '../checkbox/checkbox';
import TextInputSearch from '../text-input-search/text-input-search';
import { Text } from '../text/text';
import { SelectType } from '@eypconfig/shared/types';

import './search-select-box.scss';
import { motion, Variants } from 'framer-motion';

/* eslint-disable-next-line */
export interface SearchSelectBoxProps {
  nextPage: () => void;
  data: Array<SelectType>;
  hasMore: boolean;
  onSelect: (val: string) => void;
  onDeselect: (val: string) => void;
  onApplyFilter: () => void;
  selected: Array<string>;
  onSearch: (value: string) => void;
  onSelectAll: () => void;
  onClearAll: () => void;
}

const enterLefVariants: Variants = {
  initial: { x: -900, transition: { bounce: 0 } },
  animate: { x: 0, transition: { bounce: 0 } },
  exit: { x: -900, transition: { bounce: 0 } },
};

export function SearchSelectBox(props: SearchSelectBoxProps) {
  return (
    <motion.div
      variants={enterLefVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      className="way-search-select-box"
    >
      <div className="way-search-text-search" data-cy="search-container">
        <TextInputSearch onSearch={props.onSearch} />
      </div>
      <div
        id="scrollable-container"
        className="way-scrollable-container"
        data-cy="scroll-container"
      >
        <InfiniteScroll
          dataLength={props.data.length}
          next={props.nextPage}
          hasMore={props.hasMore}
          inverse={false}
          loader={
            <Text type="info" color="black">
              Loading...
            </Text>
          }
          scrollableTarget="scrollable-container"
        >
          {props.data.length === 0 && (
            <Text
              type="info"
              color="black"
              dataCy="search-select-box-not-found"
            >
              No results found
            </Text>
          )}
          {props.data.map((label, index) => {
            const isSelected = !!props.selected.find(
              (val) => val === label.value
            );
            return (
              <div
                className="way-search-item-list"
                key={index}
                data-cy={`checkbox-container-${index}`}
              >
                <Checkbox
                  label={label.label}
                  selected={isSelected}
                  value={label.value}
                  name={label.label}
                  onClick={() =>
                    isSelected
                      ? props.onDeselect(label.value)
                      : props.onSelect(label.value)
                  }
                />
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
      <div className="way-search-footer">
        <div className="way-search-footer-actions">
          <Text type="base-s-bold" color="black">
            {props.selected.length} Selected
          </Text>
          <div>
            <Text
              type="base-s-bold"
              color="primarygreen"
              onClick={props.onSelectAll}
            >
              Select all
            </Text>
            <Text
              type="base-s-bold"
              color="primarygreen"
              onClick={props.onClearAll}
            >
              Clear all
            </Text>
          </div>
        </div>
        <div>
          <Button
            type={buttonType.clean}
            label="APPLY FILTERS"
            data-cy="apply-filters-button"
            onClick={props.onApplyFilter}
          />
        </div>
      </div>
    </motion.div>
  );
}

export default SearchSelectBox;
