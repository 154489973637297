import { gql, useMutation } from '@apollo/client';
import { MotorsSearchFilter } from '@eypconfig/shared/types';

export const BULK_DELETE_MOTORS_MUTATION = gql`
  mutation ($filter: MotorsSearchFilter!) {
    bulkDeleteMotors(filter: $filter) {
      count
    }
  }
`;

export interface BulkDeleteMotorsResultInterface {
  bulkDeleteMotors: {
    count?: number;
  };
}

export const useBulkDeleteMotors = () => {
  const [bulkDeleteMotors, result] =
    useMutation<BulkDeleteMotorsResultInterface>(BULK_DELETE_MOTORS_MUTATION);

  return {
    bulkDeleteMotors: (filter: MotorsSearchFilter) =>
      bulkDeleteMotors({
        variables: {
          filter,
        },
      }).then(({ data }) => data?.bulkDeleteMotors?.count),
    ...result,
  };
};
