import { gql, useQuery } from '@apollo/client';

export const CABLE_CONNECTOR_DEFAULT = gql`
  query($name: String) {
    searchCableConnectors(name: $name, page: 0, offset: 1) {
      data {
        cable_connector_id
        name
        image
      }
    }
  }
`;

export interface DefaultCableConnectorResult {
  searchCableConnectors: {
    data: Array<{
      cable_connector_id: string;
      name: string;
      image?: string;
    }>;
  };
}

export function useDefaultCableConnector(name: string) {
  const result = useQuery<DefaultCableConnectorResult>(
    CABLE_CONNECTOR_DEFAULT,
    { variables: { name } }
  );
  return {
    ...result,
    data: result?.data?.searchCableConnectors?.data?.[0],
  };
}
