import { useQuery, gql, useLazyQuery } from '@apollo/client';
import {
  PaginateResultInterface,
  PaginationArgs,
  SelectType,
} from '@eypconfig/shared/types';

export const QUERY_OPTIONS_MOTOR = gql`
  query(
    $page: Int
    $propertyToUnique: String!
    $lableTemplate: String
    $textSearch: String
  ) {
    options: getMotorsSelectOptions(
      propertyToUnique: $propertyToUnique
      lableTemplate: $lableTemplate
      textSearch: $textSearch
      page: $page
    ) {
      page
      count
      offset
      data {
        label
        value
      }
    }
  }
`;

export type MotorsOptionsSelect = {
  options: PaginateResultInterface<SelectType>;
};

export const useOptionsMotors = (
  filter: {
    propertyToUnique: string;
    lableTemplate?: string;
    textSearch?: string;
  } & PaginationArgs
) => {
  const status = useQuery<MotorsOptionsSelect | null>(QUERY_OPTIONS_MOTOR, {
    variables: filter,
  });
  return status;
};

export const useLazyOptionsMotors = (
  filter: {
    propertyToUnique: string;
    lableTemplate?: string;
    textSearch?: string;
  } & PaginationArgs
) => {
  const status = useLazyQuery<MotorsOptionsSelect | null>(QUERY_OPTIONS_MOTOR, {
    variables: filter,
  });
  return status;
};
