import { useQuery, gql } from '@apollo/client';
import {
  PaginateResultInterface,
  PaginationArgs,
} from '@eypconfig/shared/types';

export const PACKAGING_OPTIONS_QUERY = gql`
  query(
    $product_classification: String!
    $motor_size: String
    $brand_classification: String!
    $designation_1: String
    $packaging_print_finiture: String
    $packaging_type: String
  ) {
    package(
      product_classification: $product_classification
      motor_size: $motor_size
      brand: $brand_classification
      designation_1: $designation_1
      packaging_type: $packaging_type
      packaging_print_finiture: $packaging_print_finiture
    ) {
      packaging_image
      designation_1
    }
  }
`;

export interface PackagingOptionsFiler extends PaginationArgs {
  product_classification: string;
  motor_size?: string;
  brand_classification: string;
  designation_1?: string;
  packaging_print_finiture?: string;
  packaging_type?: string;
}

export interface PackagingOption {
  package: {
    packaging_image?: string;
    designation_1?: string;
  };
}

export const usePackagingOption = (filters: PackagingOptionsFiler) => {
  return useQuery<PackagingOption>(PACKAGING_OPTIONS_QUERY, {
    variables: {
      ...filters,
      brand_classification: filters?.brand_classification
        ?.split('_')
        .join(' ')
        .toUpperCase(),
      product_classification: filters?.product_classification
        ?.split('_')
        .join(' ')
        .toUpperCase(),
    },
  });
};

export const PACKAGING_PRINT_FININTURE_OPTIONS = gql`
  query(
    $product_classification: String
    $motor_size: String
    $brand_classification: String
    $packaging_type: String
  ) {
    getPackagesSelectOptions(
      product_classification: $product_classification
      motor_size: $motor_size
      brand_classification: $brand_classification
      packaging_type: $packaging_type
      propertyToUnique: "packaging_print_finiture"
    ) {
      data {
        label
        value
      }
      offset
      count
      page
    }
  }
`;

export interface PackagingPrintFinitureOptionsFiler extends PaginationArgs {
  product_classification?: string;
  motor_size?: string;
  brand_classification?: string;
  packaging_type?: string;
}

export interface PackagingPrintFinitureOption {
  getPackagesSelectOptions: PaginateResultInterface<{
    label: string;
    value: string;
  }>;
}

export const usePackagingPrintFinintureOptions = (
  filters: PackagingPrintFinitureOptionsFiler
) => {
  return useQuery<PackagingPrintFinitureOption>(
    PACKAGING_PRINT_FININTURE_OPTIONS,
    {
      variables: {
        ...filters,
        brand_classification: filters?.brand_classification
          ?.split('_')
          .join(' ')
          .toUpperCase(),
        product_classification: filters?.product_classification
          ?.split('_')
          .join(' ')
          .toUpperCase(),
      },
    }
  );
};

export const PACKAGING_PRODUCT_CLASSIFICATION_OPTIONS = gql`
  query {
    getPackagesSelectOptions(propertyToUnique: "product_classification") {
      data {
        label
        value
      }
    }
  }
`;

export const usePackagingProductClassificationOptions = () => {
  return useQuery<PackagingPrintFinitureOption>(
    PACKAGING_PRODUCT_CLASSIFICATION_OPTIONS
  );
};

export const PACKAGING_TYPE_OPTIONS = gql`
  query(
    $motor_size: String
    $brand_classification: String
    $product_classification: String
  ) {
    getPackagesSelectOptions(
      propertyToUnique: "packaging_type"
      motor_size: $motor_size
      brand_classification: $brand_classification
      product_classification: $product_classification
    ) {
      data {
        label
        value
      }
    }
  }
`;

export interface PackagingTypeOptionFilterInterface {
  motor_size?: string;
  brand_classification?: string;
  product_classification?:
    | 'ADAPTERS'
    | 'BRACKETS'
    | 'CONTROLS'
    | 'MOTORS'
    | 'OTHER ACCESSORIES';
}

export const usePackagingTypeOptions = (
  variables: PackagingTypeOptionFilterInterface
) => {
  return useQuery<PackagingPrintFinitureOption>(PACKAGING_TYPE_OPTIONS, {
    variables,
  });
};
