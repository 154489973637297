import { gql, useMutation } from '@apollo/client';
import { AdaptersSearchFilter } from '@eypconfig/shared/types';

export const BULK_DELETE_ADAPTERS_MUTATION = gql`
  mutation ($filter: AdaptersSearchFilter!) {
    bulkDeleteAdapters(filter: $filter) {
      count
    }
  }
`;

export interface BulkDeleteAdaptersResultInterface {
  bulkDeleteAdapters: {
    count?: number;
  };
}

export const useBulkDeleteAdapters = () => {
  const [bulkDeleteAdapters, result] =
    useMutation<BulkDeleteAdaptersResultInterface>(
      BULK_DELETE_ADAPTERS_MUTATION
    );

  return {
    bulkDeleteAdapters: (filter: AdaptersSearchFilter) =>
      bulkDeleteAdapters({
        variables: {
          filter,
        },
      }).then(({ data }) => data?.bulkDeleteAdapters?.count),
    ...result,
  };
};
