import { gql, useMutation } from '@apollo/client';
import { BracketReferenceDto } from '@eypconfig/shared/import-validations';

export const CREATE_BRACKET_REFERENCE_MUTATION = gql`
  mutation ($bracketReference: CreateBracketsReferenceSingleInput!) {
    createBracketReference(bracketReference: $bracketReference) {
      reference_id: bracket_reference_id
    }
  }
`;

export function useCreateBracketReference() {
  const [createBracketReference, result] = useMutation(
    CREATE_BRACKET_REFERENCE_MUTATION
  );
  return {
    createBracketReference: (bracketReference: BracketReferenceDto) =>
      createBracketReference({ variables: { bracketReference } }),
    ...result,
  };
}
