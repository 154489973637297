import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { GetBracketResultInterface } from '../backoffice/get';

export const BRACKETS_OPTIONS_QUERY = gql`
  query($propertyToUnique: String!) {
    result: getBracketsSelectOptions(
      propertyToUnique: $propertyToUnique
      offset: 400
    ) {
      options: data {
        label
        value
      }
    }
  }
`;

export type PropertyToUniqueTypeBrackets = keyof Omit<
  GetBracketResultInterface['getBracketReference'],
  '__typename' | 'deleted_at' | 'updated_at'
>;

export interface GenericBracketOptionsResult {
  result: {
    options: Array<{
      label: string;
      value: string;
    }>;
  };
}

export const useGenericBracketOptions = (
  propertyToUnique: PropertyToUniqueTypeBrackets
) => {
  return useQuery<GenericBracketOptionsResult>(BRACKETS_OPTIONS_QUERY, {
    variables: { propertyToUnique },
  });
};
