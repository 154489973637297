import { gql, useQuery } from '@apollo/client';
import { PaginateResultInterface } from '@eypconfig/shared/types';

export const SOMFY_GROUPS_SEARCH_BACKOFFICE = gql`
  query($name: String, $page: Int, $offset: Int) {
    result: getSomfyGroups(name: $name, page: $page, offset: $offset) {
      count
      page
      offset
      data {
        name
        somfy_group_id
        objectTypeId: somfy_group_id
      }
    }
  }
`;

export interface SomfyGroupsSearchItem {
  somfy_group_id: string;
  objectTypeId: string;
  name: string;
  __typename: string;
}

export interface SearchSomfyGroupsResult {
  result: PaginateResultInterface<SomfyGroupsSearchItem>;
}

export const useSearchSomfyGroups = (search: {
  page?: string;
  offset?: string;
  textFilter?: string;
}) => {
  const result = useQuery<SearchSomfyGroupsResult>(
    SOMFY_GROUPS_SEARCH_BACKOFFICE,
    {
      variables: {
        page: Number(search.page),
        offset: Number(search.offset),
        name: search.textFilter,
      },
    }
  );
  return result;
};
