import { gql, useQuery } from '@apollo/client';

const GET_MOTOR_REFERENCE_QUERY = gql`
  query ($motor_reference_id: String!) {
    getMotorReference(motor_reference_id: $motor_reference_id) {
      motor_reference_id
      reference
      brand_classification
      brand_classification_image
      brand
      brand_image
      range_for_configuration
      made_in
      size_motor
      technology
      technology_image
      type_motor
      type_motor_code
      somfy_match
      activation
      parallel_connection
      radio_compatibility
      customer
      designation_0
      designation
      designation_1
      motor_head
      motor_head_image
      plastic_cap_color
      plastic_cap_color_image
      is_logo_on_the_cap
      tube_color
      tube_color_image
      tube_hexdecimal_code
      product_image
      complete_product_image
      type_lsu
      nr_of_end_limits
      adjustment_type
      obstacle_detection
      freez_detection
      power_supply_g
      nominal_voltage
      power_supply_tolerances
      voltage
      transmission_frequency
      brand_and_address
      working_frequency
      plastic_cap_color_hex_code
      cable_connector_type
      end_limit_setting_tool
      tube_material
      l1_max
      l2_plus_minut_three_mm
      range
      memory_capacity
      configuration_a
      full_address
      multi_pack_type
      multi_pack_dimensions_image
      multi_pack_brand
      multi_pack_print_finiture
      multi_pack_dim_l
      multi_pack_dim_w
      multi_pack_dim_h
      multi_pack_weight
      pallet_type
      pallet_empty_dimensions
      pallet_empty_dimensions_image
      pallet_number_pcs_layers
      pallet_number_of_packages
      pallet_dimensions_l
      pallet_dimensions_w
      pallet_dimensions_h
      pallet_weight
      pallet_dimensions_image
      reference_for_customer
      new_customer_from_configuration
      thermal_time
      thermal_time_label
      cable_tipology
      cable_color_type
      cable_connector
      cable_connector_name
      cable_connector_image
      cable_type
      cable_type_image
      cable_number_of_wires
      cable_wire_section
      cable_lenght
      brake_type
      capacity_of_lsu
      repeatability
      system_of_protection
      temperature_working_range
      noise_level
      interface_drawing
      electromagnetic_compatibility_1
      electromagnetic_compatibility_2
      electromagnetic_compatibility_3
      electromagnetic_compatibility_4
      electromagnetic_compatibility_5
      electromagnetic_compatibility_6
      basic_crown_for_tube
      basic_crown_for_tube_size
      electrical_safety
      health_test
      nominal_torque
      nominal_speed
      torque_speed
      rated_power
      rated_current
      insulation_class
      l3_max
      dimension_image
      weight
      included_bracket_meta
      included_adapters_meta
      other_accessories_meta
      included_control_meta
      certification_ce
      certification_vde
      certification_nf
      certification_cmim
      certification_pai
      certification_saber
      motor_label_image
      motor_barcode_image
      motor_barcode
      ean_code
      coding
      vertical_screens
      vertical_screens_image
      zebra_screen
      zebra_screen_image
      rolling_shutters
      rolling_shutters_image
      leteral_arms_awnings
      leteral_arms_awnings_image
      awnings_with_arms
      awnings_with_arms_image
      cassette_awnings
      cassette_awnings_image
      instruction_leaflets_image
      instruction_logo
      instruction_languages
      package_label_image
      multi_package_label_image
      packaging_nr_of_motors
      packaging_nr_of_brackets
      packaging_nr_of_adapters
      packaging_nr_of_controls
      packaging_nr_of_other_accessories
      packaging_nr_of_instruction_leaflets
      packaging_quantity
      packaging_graphic_layout_choice
      packaging_graphic_layout_image
      three_d_video
      packaging_type
      packaging_print_finiture
      packaging_brand
      packaging_dimension_l
      packaging_dimension_w
      packaging_dimension_h
      packaging_dimension_image
      packaging_weight
      euro_pallet_nr_psc
      euro_pallet_image
      euro_pallet_nr_single_package
      euro_pallet_total_dimensions
      catalogue
      product_technical_sheet
      warning_and_instruction_leafsets
      ce_declaration
      vde_certification
      nf_certification
      compare_declaration
      photo_high_resolution
      photo_low_resolution
      dimensional_drawings
      video_installation
      mini_programming_guide
      forecast_annual_volumes
      product_identification_file
      is_draft
      product_type
      other_accessories {
        other_accessory_reference_id
        reference
        package_position
        package_item_quantity
      }
      brackets {
        bracket_reference_id
        reference
        package_position
        package_item_quantity
      }
      adapters {
        adapter_reference_id
        reference
        package_position
        package_item_quantity
      }
      controls {
        control_reference_id
        reference
        package_position
        package_item_quantity
      }
      order
      signed_document_uri
      created_at
      updated_at
      deleted_at
    }
  }
`;

export interface GetMotorsResultInterface {
  getMotorReference: {
    __typename: string;
    motor_reference_id: string;
    reference?: string;
    brand_classification: Array<string>;
    brand_classification_image?: string;
    brand?: string;
    brand_image?: string;
    brand_and_address?: string;
    range_for_configuration: boolean;
    made_in: string;
    size_motor: string;
    technology: string;
    technology_image?: string;
    type_motor: string;
    type_motor_code: string;
    somfy_match?: string;
    activation?: string;
    parallel_connection?: string;
    radio_compatibility?: string;
    customer: Array<string>;
    designation_0?: string;
    designation?: string;
    designation_1?: string;
    motor_head?: string;
    motor_head_image?: string;
    plastic_cap_color?: string;
    plastic_cap_color_image?: string;
    is_logo_on_the_cap?: boolean;
    tube_color?: string;
    tube_color_image?: string;
    tube_hexdecimal_code?: string;
    product_image?: string;
    complete_product_image?: string;
    type_lsu?: string;
    nr_of_end_limits?: string;
    adjustment_type?: string;
    obstacle_detection?: boolean;
    freez_detection?: boolean;
    power_supply_g?: string;
    nominal_voltage?: string;
    power_supply_tolerances?: string;
    voltage?: string;
    transmission_frequency?: string;
    working_frequency?: string;
    plastic_cap_color_hex_code?: string;
    cable_connector_type?: string;
    end_limit_setting_tool?: string;
    tube_material?: string;
    l1_max?: string;
    l2_plus_minut_three_mm?: string;
    range?: string;
    coding?: string;
    memory_capacity?: string;
    configuration_a?: string;
    full_address?: string;
    multi_pack_type?: string;
    multi_pack_dimensions_image?: string;
    multi_pack_brand?: string;
    multi_pack_print_finiture?: string;
    multi_pack_dim_l?: string;
    multi_pack_dim_w?: string;
    multi_pack_dim_h?: string;
    multi_pack_weight?: string;
    pallet_type?: string;
    pallet_empty_dimensions?: string;
    pallet_empty_dimensions_image?: string;
    pallet_number_pcs_layers?: string;
    pallet_number_of_packages?: string;
    pallet_dimensions_l?: string;
    pallet_dimensions_w?: string;
    pallet_dimensions_h?: string;
    pallet_weight?: string;
    pallet_dimensions_image?: string;
    reference_for_customer?: string;
    new_customer_from_configuration?: string;
    three_d_video?: string;
    thermal_time?: string;
    thermal_time_label?: string;
    cable_tipology?: string;
    cable_color_type?: string;
    cable_connector?: boolean;
    cable_connector_name?: string;
    cable_connector_image?: string;
    cable_type?: string;
    cable_type_image?: string;
    cable_number_of_wires?: number;
    cable_wire_section?: string;
    cable_lenght?: string;
    brake_type?: string;
    capacity_of_lsu?: string;
    repeatability?: string;
    system_of_protection?: string;
    temperature_working_range?: string;
    noise_level?: string;
    interface_drawing?: string;
    electromagnetic_compatibility_1?: string;
    electromagnetic_compatibility_2?: string;
    electromagnetic_compatibility_3?: string;
    electromagnetic_compatibility_4?: string;
    electromagnetic_compatibility_5?: string;
    electromagnetic_compatibility_6?: string;
    basic_crown_for_tube?: string;
    basic_crown_for_tube_size?: string;
    electrical_safety?: string;
    health_test?: string;
    nominal_torque?: string;
    nominal_speed?: string;
    torque_speed?: string;
    rated_power?: string;
    rated_current?: string;
    insulation_class?: string;
    l3_max?: string;
    dimension_image?: string;
    weight?: string;
    included_bracket_meta?: boolean;
    included_adapters_meta?: boolean;
    other_accessories_meta?: boolean;
    included_control_meta?: boolean;
    certification_ce: boolean;
    certification_vde: boolean;
    certification_nf: boolean;
    certification_cmim: boolean;
    certification_pai: boolean;
    certification_saber: boolean;
    motor_label_image?: string;
    motor_barcode_image?: string;
    motor_barcode?: string;
    ean_code?: string;
    vertical_screens?: boolean;
    vertical_screens_image?: string;
    zebra_screen?: boolean;
    zebra_screen_image?: string;
    rolling_shutters?: boolean;
    rolling_shutters_image?: string;
    leteral_arms_awnings?: boolean;
    leteral_arms_awnings_image?: string;
    awnings_with_arms?: boolean;
    awnings_with_arms_image?: string;
    cassette_awnings?: boolean;
    cassette_awnings_image?: string;
    instruction_leaflets_image?: string;
    instruction_logo?: string;
    instruction_languages: Array<string>;
    package_label_image?: string;
    multi_package_label_image?: string;
    packaging_nr_of_motors?: string;
    packaging_nr_of_brackets?: string;
    packaging_nr_of_adapters?: string;
    packaging_nr_of_controls?: string;
    packaging_nr_of_other_accessories?: string;
    packaging_nr_of_instruction_leaflets?: string;
    packaging_quantity?: string;
    packaging_graphic_layout_choice?: string;
    packaging_graphic_layout_image?: string;
    packaging_type?: string;
    packaging_print_finiture?: string;
    packaging_brand?: string;
    packaging_dimension_l?: string;
    packaging_dimension_w?: string;
    packaging_dimension_h?: string;
    packaging_dimension_image?: string;
    packaging_weight?: string;
    euro_pallet_nr_psc?: string;
    euro_pallet_image?: string;
    euro_pallet_nr_single_package?: string;
    euro_pallet_total_dimensions?: string;
    catalogue?: string;
    product_technical_sheet?: string;
    warning_and_instruction_leafsets?: string;
    ce_declaration?: string;
    vde_certification?: string;
    nf_certification?: string;
    compare_declaration?: string;
    photo_high_resolution?: string;
    photo_low_resolution?: string;
    dimensional_drawings?: string;
    video_installation?: string;
    mini_programming_guide?: string;
    forecast_annual_volumes?: string;
    product_identification_file?: string;
    is_draft: boolean;
    other_accessories: Array<{
      __typename: string;
      other_accessory_reference_id: string;
      reference: string;
      package_position: string;
      package_item_quantity: string;
    }>;
    brackets: Array<{
      __typename: string;
      bracket_reference_id: string;
      reference: string;
      package_position: string;
      package_item_quantity: string;
    }>;
    adapters: Array<{
      __typename: string;
      adapter_reference_id: string;
      reference: string;
      package_position: string;
      package_item_quantity: string;
    }>;
    controls: Array<{
      __typename: string;
      control_reference_id: string;
      reference: string;
      package_position: string;
      package_item_quantity: string;
    }>;
    order: number;
    signed_document_uri?: string;
    created_at: Date;
    updated_at: Date;
    deleted_at?: Date;
    product_type?: string;
  };
}

export const useGetMotorReference = (motor_reference_id: string) => {
  const result = useQuery<GetMotorsResultInterface>(GET_MOTOR_REFERENCE_QUERY, {
    variables: { motor_reference_id },
  });
  return result;
};
