import { gql, useMutation } from '@apollo/client';

const DELETE_BRACKET_REFERENCE_MUTATION = gql`
  mutation($bracket_reference_id: String!) {
    deleteBracketReference(
      bracketDeleteFilter: { bracket_reference_id: $bracket_reference_id }
    ) {
      bracket_reference_id
    }
  }
`;

export const useDeleteBracket = (bracket_reference_id: string) => {
  const [deleteBracket, result] = useMutation(
    DELETE_BRACKET_REFERENCE_MUTATION
  );
  return {
    doDelete: () =>
      deleteBracket({
        variables: {
          bracket_reference_id,
        },
      }).then((result) => !result.errors),
    ...result,
    error: result.error?.message,
  };
};
