import { gql, useQuery } from '@apollo/client';

export const GET_BRANDS_QUERY = gql`
  query {
    brands(offset: 400) {
      data {
        label
        value
      }
    }
  }
`;

export interface GetBrandsResultInterface {
  brands: {
    data: Array<{
      label: string;
      value: string;
    }>;
  };
}

export const useGetAllBrands = () => {
  const result = useQuery<GetBrandsResultInterface>(GET_BRANDS_QUERY);
  return result;
};
