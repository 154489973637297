import { useState } from 'react';
import { Icon, Search } from '../icon/icon';
import './text-input-search.scss';

/* eslint-disable-next-line */
export interface TextInputSearchProps {
  onSearch: (value: string) => void;
  initialValue?: string;
}

export function TextInputSearch({
  onSearch,
  initialValue = '',
}: TextInputSearchProps) {
  const [value, setValue] = useState(initialValue);

  const handleClick = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="way-input-search">
      <label htmlFor="search">Search:</label>
      <input
        type="text"
        name="search"
        onChange={handleClick}
        value={value}
        onKeyUp={(event) => (event.key === 'Enter' ? onSearch(value) : null)}
      />
      <span
        onClick={() => onSearch(value)}
        onKeyUp={(event) => (event.key === 'Enter' ? onSearch(value) : null)}
        tabIndex={0}
        role="button"
        aria-pressed="true"
      >
        <Icon iconName={Search} />
      </span>
    </div>
  );
}

export default TextInputSearch;
