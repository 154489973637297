import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  ControlsSearchFilter,
  PaginateResultInterface,
  PaginationArgs,
} from '@eypconfig/shared/types';

export const CONTROLS_SEARCH_CATALOGUE_QUERY = gql`
  query(
    $page: Int
    $offset: Int
    $reference: [String!]
    $brand: [String!]
    $brand_classification: [String!]
    $customer: [String!]
    $somfy_match: [String!]
    $control_type: [String!]
    $technology: [String!]
    $package_type: [String!]
    $range_for_configuration: Boolean
  ) {
    searchControls(
      page: $page
      offset: $offset
      reference: $reference
      brand: $brand
      brand_classification: $brand_classification
      customer: $customer
      somfy_match: $somfy_match
      technology: $technology
      control_type: $control_type
      package_type: $package_type
      range_for_configuration: $range_for_configuration
    ) {
      count
      offset
      data {
        __typename
        reference
        designation
        designation_1
        insulation_class
        protection_degree
        power_supply_or_battery
        short_designation
        remote_control_class
        protection_rate
        working_temperature
        storage_temperature
        battery_type
        certification_ce
        certification_vde
        certification_nf
        certification_cmim
        certification_pai
        certification_saber
        vertical_screen
        zebra_screen
        rolling_shutter
        lateral_arms_awning
        awning_with_arms
        casette_awning
        technology
        transmission_frequence
        range
        made_in
        brand
        brand_image
        product_image
        complete_product_image
        dimension_image
        somfy_match
        package_type
        number_of_controls
        number_of_wall_support
        number_of_instruction_leaflets
        instruction_languages
        instructions_logo_address
        image_without_graphics_on_cover
        image_with_graphics_on_cover
        control_type
        control_reference_id
        dimension_drawing_image
        three_d_video
        catalogue
        product_technical_sheet
        number_of_instruction_leaflets
        warning_and_instructions_leaflets
        ce_declaration
        vde_certification
        nf_certification
        compare_declaration
        photo_high_resolution
        photo_low_resolution
        dimensional_drawings
        mini_programming_guide
        video_installation
        package_brand
        brand_address
        graphic_on_the_cover_image
      }
    }
  }
`;

export const CONTROLS_COUNT_CATALOGUE_QUERY = gql`
  query {
    searchControls {
      count
    }
  }
`;

export interface ControlCatalogueInterface {
  __typename: 'ControlReference';
  reference: string;
  designation: string;
  image_without_graphics_on_cover?: string;
  image_with_graphics_on_cover?: string;
  designation_1?: string;
  insulation_class?: string;
  protection_degree?: string;
  power_supply_or_battery?: string;
  short_designation?: string;
  remote_control_class?: string;
  protection_rate?: string;
  working_temperature?: string;
  storage_temperature?: string;
  battery_type?: string;
  certification_ce: boolean;
  certification_vde: boolean;
  certification_nf: boolean;
  certification_cmim: boolean;
  certification_pai: boolean;
  certification_saber: boolean;
  vertical_screen: boolean;
  zebra_screen: boolean;
  rolling_shutter: boolean;
  lateral_arms_awning: boolean;
  awning_with_arms: boolean;
  casette_awning: boolean;
  technology?: string;
  transmission_frequence?: string;
  range?: string;
  made_in?: string;
  brand?: string;
  brand_image?: string;
  product_image?: string;
  complete_product_image?: string;
  dimension_image?: string;
  somfy_match?: string;
  package_type?: string;
  number_of_controls?: string;
  number_of_wall_support?: string;
  instruction_languages?: Array<string>;
  instructions_logo_address?: string;
  control_type: string;
  control_reference_id: string;
  dimension_drawing_image?: string;
  three_d_video?: string;
  catalogue?: string;
  product_technical_sheet?: string;
  number_of_instruction_leaflets?: string;
  warning_and_instructions_leaflets?: string;
  ce_declaration?: string;
  vde_certification?: string;
  nf_certification?: string;
  compare_declaration?: string;
  photo_high_resolution?: string;
  photo_low_resolution?: string;
  dimensional_drawings?: string;
  mini_programming_guide?: string;
  video_installation?: string;
  package_brand?: string;
  brand_address?: string;
  graphic_on_the_cover_image?: string;
}

export interface SearchControlsCatalogQueryResultInterface {
  searchControls: PaginateResultInterface<ControlCatalogueInterface>;
}

export const useSearchControlsCatalogue = (
  filter?: ControlsSearchFilter & PaginationArgs
) => {
  const brand_classification = filter?.brand_classification?.map((brand) => {
    return brand.split('_').join(' ').toUpperCase();
  });
  const data = useQuery<SearchControlsCatalogQueryResultInterface | null>(
    CONTROLS_SEARCH_CATALOGUE_QUERY,
    {
      variables: {
        ...filter,
        brand_classification,
      },
    }
  );

  return data;
};

export const useLazySearchControlsCatalogue = (
  filter?: ControlsSearchFilter & PaginationArgs
) => {
  const data = useLazyQuery<SearchControlsCatalogQueryResultInterface | null>(
    CONTROLS_SEARCH_CATALOGUE_QUERY,
    {
      variables: filter,
    }
  );

  return data;
};

export interface CountControlsCatalogQueryResultInterface {
  searchControls: {
    count: number;
  };
}

export const useLazyCountControls = () => {
  const data = useLazyQuery<CountControlsCatalogQueryResultInterface | null>(
    CONTROLS_COUNT_CATALOGUE_QUERY
  );

  return data;
};
