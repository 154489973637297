import { gql, useQuery } from '@apollo/client';
import { PaginateResultInterface } from '@eypconfig/shared/types';

export const LABEL_SEARCH_BACKOFFICE = gql`
  query($technology: String, $page: Int, $offset: Int) {
    result: searchLabel(technology: $technology, page: $page, offset: $offset) {
      count
      page
      offset
      data {
        label_id
        objectTypeId: label_id
        technology
        motor_size
        product_classification
        type_code
      }
    }
  }
`;

export interface LabelSearchItem {
  label_id: string;
  objectTypeId: string;
  technology: string;
  motor_size?: string;
  product_classification?: string;
  type_code?: string;
}

export interface SearchLabelColorResult {
  result: PaginateResultInterface<LabelSearchItem>;
}

export const useSearchLabel = (search: {
  page?: string;
  offset?: string;
  textFilter?: string;
}) => {
  const result = useQuery<SearchLabelColorResult>(LABEL_SEARCH_BACKOFFICE, {
    variables: {
      page: Number(search.page),
      offset: Number(search.offset),
      technology: search.textFilter,
    },
  });
  return result;
};
