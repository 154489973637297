import { gql, useLazyQuery } from '@apollo/client';

export const NOMINAL_VOLTAGE_SINGLE_QUERY = gql`
  query($nominal_voltage_id: String!) {
    result: getNominalVoltage(nominal_voltage_id: $nominal_voltage_id) {
      objectTypeId: nominal_voltage_id
      nominal_voltage_id
      motor_size
      type_code
      descriptions
      technology
      created_at
      updated_at
    }
  }
`;

export interface NominalVoltageItem {
  nominal_voltage_id: string;
  objectTypeId: string;
  motor_size: Array<string>;
  type_code: string;
  descriptions: Array<string>;
  technology: string;
  updated_at: Date;
  created_at: Date;
  __typename: string;
}

export interface GetNominalVoltageResult {
  result: NominalVoltageItem;
}

export const useGetNominalVoltage = (nominal_voltage_id: string) => {
  const res = useLazyQuery<GetNominalVoltageResult>(
    NOMINAL_VOLTAGE_SINGLE_QUERY,
    {
      variables: { nominal_voltage_id },
    }
  );
  return res;
};
