import { gql, useMutation } from '@apollo/client';
import { OtherAccessoriesSearchFilter } from '@eypconfig/shared/types';
import { GetOtherAccResultInterface } from '../backoffice/get';

export const BULK_UPDATE_OTHER_ACCESSORIES_MUTATION = gql`
  mutation (
    $filter: OtherAccessoriesSearchFilter!
    $otherAccessoryReference: OtherAccessoryReferenceBulkUpdateInput!
  ) {
    bulkUpdateOtherAccessories(
      filter: $filter
      otherAccessoryReference: $otherAccessoryReference
    ) {
      count
    }
  }
`;

export type BulkUpdateOtherInterface = Partial<
  Omit<
    GetOtherAccResultInterface['getOtherAccessoryReference'],
    'other_accessory_reference_id'
  >
>;

export interface BulkUpdateOtherAccessoriesResultInterface {
  bulkUpdateOtherAccessories: {
    count?: number;
  };
}

export const useBulkUpdateOtherAccessories = () => {
  const [bulkUpdateOtherAccessories, result] =
    useMutation<BulkUpdateOtherAccessoriesResultInterface>(
      BULK_UPDATE_OTHER_ACCESSORIES_MUTATION
    );

  return {
    bulkUpdateOtherAccessories: (
      filter: OtherAccessoriesSearchFilter,
      otherAccessoryReference: BulkUpdateOtherInterface
    ) =>
      bulkUpdateOtherAccessories({
        variables: {
          filter,
          otherAccessoryReference,
        },
      }).then(({ data }) => data?.bulkUpdateOtherAccessories?.count),
    ...result,
  };
};
