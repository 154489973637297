import { gql, useQuery } from '@apollo/client';
import { PaginateResultInterface } from '@eypconfig/shared/types';

// *** CABLE CONNECTOR SECTION ***

export const CABLE_CONNECTOR_SEARCH_BACKOFFICE = gql`
  query($name: String, $page: Int, $offset: Int) {
    result: searchCableConnectors(name: $name, page: $page, offset: $offset) {
      count
      page
      offset
      data {
        objectTypeId: cable_connector_id
        cable_connector_id
        name
      }
    }
  }
`;

export interface CableConnectorSearchItem {
  objectTypeId: string;
  cable_connector_id: string;
  name: string;
}

export interface SearchCableConnectorResult {
  result: PaginateResultInterface<CableConnectorSearchItem>;
}

export const useSearchCableConnector = (search: {
  page?: string;
  offset?: string;
  textFilter?: string;
}) => {
  const result = useQuery<SearchCableConnectorResult>(
    CABLE_CONNECTOR_SEARCH_BACKOFFICE,
    {
      variables: {
        page: Number(search.page),
        offset: Number(search.offset),
        name: search.textFilter,
      },
    }
  );
  return result;
};

// *** CABLE LENGTH SECTION ***

export const CABLE_LENGTH_BACKOFFICE = gql`
  query($label: String, $page: Int, $offset: Int) {
    result: searchCableLengths(label: $label, page: $page, offset: $offset) {
      count
      page
      offset
      data {
        objectTypeId: cable_length_id
        cable_length_id
        label
        value
      }
    }
  }
`;

export interface CableLengthSearchItem {
  objectTypeId: string;
  cable_length_id: string;
  label: string;
  value: string;
}

export interface SearchCableLengthResult {
  result: PaginateResultInterface<CableLengthSearchItem>;
}

export const useSearchCableLength = (search: {
  page?: string;
  offset?: string;
  textFilter?: string;
}) => {
  const result = useQuery<SearchCableLengthResult>(CABLE_LENGTH_BACKOFFICE, {
    variables: {
      page: Number(search.page),
      offset: Number(search.offset),
      label: search.textFilter,
    },
  });
  return result;
};

// *** CABLE TYPE SECTION ***

export const CABLE_TYPE_SEARCH_BACKOFFICE = gql`
  query($label: String, $page: Int, $offset: Int) {
    result: searchCableTypes(label: $label, page: $page, offset: $offset) {
      count
      page
      offset
      data {
        objectTypeId: cable_type_id
        cable_type_id
        label
        code
      }
    }
  }
`;

export interface CableTypeSearchItem {
  objectTypeId: string;
  cable_type_id: string;
  label: string;
  code: string;
}

export interface SearchCableTypeResult {
  result: PaginateResultInterface<CableTypeSearchItem>;
}

export const useSearchCableType = (search: {
  page?: string;
  offset?: string;
  textFilter?: string;
}) => {
  const result = useQuery<SearchCableTypeResult>(CABLE_TYPE_SEARCH_BACKOFFICE, {
    variables: {
      page: Number(search.page),
      offset: Number(search.offset),
      label: search.textFilter,
    },
  });
  return result;
};
