import { useEffect } from 'react';

export function useOnScroll(
  handler: (ev: MouseEvent) => unknown,
  dependencyes = []
) {
  useEffect(
    () => {
      const listener = (event) => {
        handler(event);
      };

      // Select all the divs in the page
      const divlist = document.querySelectorAll('div');
      window.addEventListener('scroll', listener);
      document.addEventListener('scroll', listener);
      divlist.forEach((item) => item.addEventListener('scroll', listener));

      return () => {
        document.removeEventListener('scroll', listener);
        window.removeEventListener('scroll', listener);
        divlist.forEach((item) => item.removeEventListener('scroll', listener));
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [handler, ...dependencyes]
  );
}
