import { gql, useMutation } from '@apollo/client';
import { SetConfigurationInterface } from './create-configuration.hook';

export const UPDATE_CONFIGURATION = gql`
  mutation(
    $configurationData: ConfigurationUpdateInput
    $motorReferencesList: [MotorsReferenceUpdateInput!]
    $adapterReferencesList: [AdapterReferenceUpdateInput!]
    $otherAccessoryReferencesList: [OtherAccessoryReferenceUpdateInput!]
    $bracketReferencesList: [BracketReferenceUpdateInput!]
    $controlReferencesList: [ControlReferenceUpdateInput!]
  ) {
    updateConfiguration(
      configurationData: $configurationData
      motorReferencesList: $motorReferencesList
      adapterReferencesList: $adapterReferencesList
      otherAccessoryReferencesList: $otherAccessoryReferencesList
      bracketReferencesList: $bracketReferencesList
      controlReferencesList: $controlReferencesList
    ) {
      configuration_id
    }
  }
`;

export const useSaveConfiguration = () => {
  const [saveConfiguration, result] = useMutation(UPDATE_CONFIGURATION);
  return {
    ...result,
    saveConfiguration: ({
      motorReferencesList,
      adapterReferencesList,
      otherAccessoryReferencesList,
      bracketReferencesList,
      controlReferencesList,
      ...configurationData
    }: SetConfigurationInterface) =>
      saveConfiguration({
        variables: {
          configurationData,
          motorReferencesList,
          adapterReferencesList,
          otherAccessoryReferencesList,
          bracketReferencesList,
          controlReferencesList,
        },
      }),
  };
};
