import { gql, useMutation } from '@apollo/client';
import { OtherAccessoryReferenceDto } from '@eypconfig/shared/import-validations';

export const CREATE_OTHER_ACCESSORY_REFERENCE_MUTATION = gql`
  mutation (
    $otherAccessoriesReference: CreateOtherAccessoriesReferenceSingleInput!
  ) {
    createOtherAccessoryReference(
      otherAccessoriesReference: $otherAccessoriesReference
    ) {
      reference_id: other_accessory_reference_id
    }
  }
`;

export function useCreateOtherAccessoryReference() {
  const [createOtherAccessoryReference, result] = useMutation(
    CREATE_OTHER_ACCESSORY_REFERENCE_MUTATION
  );
  return {
    createOtherAccessoryReference: (
      otherAccessoriesReference: OtherAccessoryReferenceDto
    ) =>
      createOtherAccessoryReference({
        variables: { otherAccessoriesReference },
      }),
    ...result,
  };
}
