import { gql, useMutation } from '@apollo/client';
import { ControlsSearchFilter } from '@eypconfig/shared/types';

export const BULK_DELETE_CONTROLS_MUTATION = gql`
  mutation ($filter: ControlsSearchFilter!) {
    bulkDeleteControls(filter: $filter) {
      count
    }
  }
`;

export interface BulkDeleteControlsResultInterface {
  bulkDeleteControls: {
    count?: number;
  };
}

export const useBulkDeleteControls = () => {
  const [bulkDeleteControls, result] =
    useMutation<BulkDeleteControlsResultInterface>(
      BULK_DELETE_CONTROLS_MUTATION
    );

  return {
    bulkDeleteControls: (filter: ControlsSearchFilter) =>
      bulkDeleteControls({
        variables: {
          filter,
        },
      }).then(({ data }) => data?.bulkDeleteControls?.count),
    ...result,
  };
};
