import { gql, useMutation } from '@apollo/client';
import { GetAdapterResultInterface } from '../get';

export const UPDATE_FULL_ADAPTER_MUTATION = gql`
  mutation($adapterReference: AdapterReferenceUpdateInput) {
    updateAdapterReference(adapterReference: $adapterReference) {
      adapter_reference_id
    }
  }
`;

export interface UpdateAdapterResultInterface {
  updateAdapterReference: {
    adapter_reference_id: string;
  };
}

export type UpdateAdapterRequestInterface = Partial<
  Omit<GetAdapterResultInterface['getAdapterReference'], 'adapter_reference_id'>
> & { adapter_reference_id: string };

export const useUpdateAdapter = () => {
  const [updateAdapter, result] = useMutation<UpdateAdapterResultInterface>(
    UPDATE_FULL_ADAPTER_MUTATION
  );
  return {
    updateAdapter: (adapterReference: UpdateAdapterRequestInterface) =>
      updateAdapter({
        variables: {
          adapterReference,
        },
      }),
    ...result,
  };
};
