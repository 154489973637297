import React, { useEffect, useState } from 'react';
import Button, { buttonType } from '../button/button';
import EditButton from '../edit-button/edit-button';
import { Arrow } from '../icon/icon';
import PopupContainer from '../popup-container/popup-container';
import Text from '../text/text';
import Textarea from '../textarea/textarea';

import './popup-textarea.scss';

export interface PopupTextareaProps {
  popContainerId: string;
  label?: string;
  onTextChanged?: (value: string) => void;
  onClose?: () => void;
  open?: boolean;
  textAreaPlaceholder?: string;
  titlePopup: string;
  disabled?: boolean;
  initialValue?: string;
}

export function PopupTextarea({
  popContainerId,
  textAreaPlaceholder,
  onTextChanged,
  onClose,
  open: openProps,
  label,
  titlePopup,
  disabled,
  initialValue,
}: PopupTextareaProps) {
  const [text, setText] = useState(initialValue || '');

  useEffect(() => {
    if (initialValue !== text) {
      setText(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const isOpenProps = typeof openProps === 'boolean';

  return (
    <PopupContainer
      open={openProps}
      openControllerComponent={(props) =>
        !isOpenProps ? (
          <EditButton
            label={label}
            onClick={() => (disabled ? null : props.onClick())}
            disabled={disabled}
          ></EditButton>
        ) : null
      }
      popContainerId={popContainerId}
      onClose={onClose}
    >
      {({ close }) => {
        return (
          <div
            className="way-popup-container-textarea"
            style={{
              textAlign: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'column',
            }}
          >
            <div style={{ paddingBottom: 30 }}>
              <Text type="info-bold" color="black">
                {titlePopup}
              </Text>
            </div>
            <div style={{ paddingBottom: 20 }}>
              <Textarea
                placeholder={textAreaPlaceholder || 'Start typing...'}
                value={text}
                onChange={setText}
                data-cy="textarea-popup"
              />
            </div>
            <div style={{ maxWidth: 200, margin: 'auto' }}>
              <Button
                type={buttonType.right}
                iconName={Arrow}
                label="Save"
                data-cy="popup-save-button"
                onClick={() => {
                  if (onTextChanged) {
                    onTextChanged(text);
                  }
                  close();
                }}
              />
            </div>
          </div>
        );
      }}
    </PopupContainer>
  );
}

export default PopupTextarea;
