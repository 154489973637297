import { gql, useMutation } from '@apollo/client';

export const DELETE_CONFIGURATION_QUERY = gql`
  mutation($configuration_id: String!) {
    deleteConfiguration(configuration_id: $configuration_id) {
      configuration_id
    }
  }
`;

export const useDeleteConfiguration = (configuration_id: string) => {
  const [deleteConf] = useMutation(DELETE_CONFIGURATION_QUERY);

  return () => deleteConf({ variables: { configuration_id } });
};
