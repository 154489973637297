import { getAuthorizationModule } from '@f-technology-srl/react-authorization';
import { createStore, IModuleStore } from 'redux-dynamic-modules';

export const store: IModuleStore<unknown> = createStore(
  {
    initialState: {},
    enhancers: [],
    extensions: [],
  },
  getAuthorizationModule({
    type: 'password',
    transport: 'cookie',
    baseUri: '/api',
  })
);

export default store;
