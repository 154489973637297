import { gql, useMutation } from '@apollo/client';
import { TubeColorItem } from '../../get/options/tube-color-reference.hook';

export const TUBE_COLOR_UPDATE_QUERY = gql`
  mutation($tubeColorItem: UpdateTubeColorItemDto!) {
    updateTubeColor(tubeColorItem: $tubeColorItem) {
      tube_color_id
    }
  }
`;

export interface UpdateTubeColorResponse {
  updateTubeColor: {
    tube_color_id: string;
  };
}

export type UpdateTubeColorItem = Partial<
  Omit<TubeColorItem, 'tube_color_id'>
> &
  Pick<TubeColorItem, 'tube_color_id'>;

export const useUpdateTubeColor = () => {
  const [mutate, { loading, error }] = useMutation(TUBE_COLOR_UPDATE_QUERY);
  return {
    update: ({
      objectTypeId,
      created_at,
      updated_at,
      ...items
    }: UpdateTubeColorItem) =>
      mutate({
        variables: {
          tubeColorItem: {
            tube_color_id: objectTypeId,
            ...items,
          },
        },
      }).then((result) => !result.errors),
    loading,
    error: error?.message,
  };
};
