import { gql, useApolloClient } from '@apollo/client';

export const GET_REPORT_FILE_CONFIGURATION = gql`
  query($configuration_id: String!) {
    getConfiguration(configuration_id: $configuration_id) {
      report_url_file
    }
  }
`;

export interface ReportConfigurationResult {
  getConfiguration: {
    report_url_file?: string;
  };
}

export const useReportFile = (configuration_id?: string) => {
  const client = useApolloClient();

  return () => {
    if (configuration_id) {
      return client.query<ReportConfigurationResult>({
        query: GET_REPORT_FILE_CONFIGURATION,
        variables: { configuration_id },
        fetchPolicy: 'network-only',
      });
    }
  };
};
