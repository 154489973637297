import { useQuery, gql, useLazyQuery } from '@apollo/client';
import {
  PaginateResultInterface,
  PaginationArgs,
  SelectType,
} from '@eypconfig/shared/types';

export const QUERY_OPTIONS_CONTROLS = gql`
  query(
    $propertyToUnique: String!
    $textSearch: String
    $lableTemplate: String
    $page: Int
    $offset: Int
  ) {
    options: getControlSelectOptions(
      propertyToUnique: $propertyToUnique
      textSearch: $textSearch
      lableTemplate: $lableTemplate
      page: $page
      offset: $offset
    ) {
      count
      page
      offset
      data {
        label
        value
      }
    }
  }
`;

export type ControlsOptionsSelect = {
  options: PaginateResultInterface<SelectType>;
};

export const useOptionsControls = (
  filter: {
    propertyToUnique: string;
    lableTemplate?: string;
    textSearch?: string;
  } & PaginationArgs
) => {
  const status = useQuery<ControlsOptionsSelect | null>(
    QUERY_OPTIONS_CONTROLS,
    {
      variables: filter,
    }
  );
  return status;
};

export const useLazyOptionsControls = (
  filter: {
    propertyToUnique: string;
    lableTemplate?: string;
    textSearch?: string;
  } & PaginationArgs
) => {
  const status = useLazyQuery<ControlsOptionsSelect | null>(
    QUERY_OPTIONS_CONTROLS,
    {
      variables: filter,
    }
  );
  return status;
};
