import { gql, useQuery } from '@apollo/client';
import { PaginateResultInterface } from '@eypconfig/shared/types';

export const NOMINAL_VOLTAGE_SEARCH_BACKOFFICE = gql`
  query($type_code: String, $page: Int, $offset: Int) {
    result: searchNominalVoltages(
      type_code: $type_code
      page: $page
      offset: $offset
    ) {
      count
      page
      offset
      data {
        nominal_voltage_id
        objectTypeId: nominal_voltage_id
        motor_size
        type_code
        technology
        descriptions
      }
    }
  }
`;

export interface NominalVoltageSearchItem {
  nominal_voltage_id: string;
  objectTypeId: string;
  type_code: string;
  motor_size: Array<string>;
  technology: string;
  descriptions: Array<string>;
}

export interface SearchNominalVoltageColorResult {
  result: PaginateResultInterface<NominalVoltageSearchItem>;
}

export const useSearchNominalVoltage = (search: {
  page?: string;
  offset?: string;
  textFilter?: string;
}) => {
  const result = useQuery<SearchNominalVoltageColorResult>(
    NOMINAL_VOLTAGE_SEARCH_BACKOFFICE,
    {
      variables: {
        page: Number(search.page),
        offset: Number(search.offset),
        type_code: search.textFilter,
      },
    }
  );
  return result;
};
