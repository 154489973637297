import React from 'react';
import { Icon } from '../icon/icon';
import './text-input.scss';

export type InputType = 'icon' | 'left' | 'plain';

/* eslint-disable-next-line */
export interface TextInputProps<DynamicVal> {
  type: InputType;
  name: string;
  label?: string;
  iconName?: () => JSX.Element;
  required?: boolean;
  invalid?: boolean;
  secure?: boolean;
  value?: DynamicVal;
  onChange: (value: DynamicVal) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  extraClass?: string;
  isUppercase?: boolean;
}

export function TextInput<DynamicVal extends string | number>({
  type,
  name,
  label,
  iconName,
  required,
  invalid,
  secure,
  value,
  onChange,
  onBlur,
  extraClass,
  isUppercase,
}: TextInputProps<DynamicVal>) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div
      className={`way-input-text way-input-text--${type} ${
        isUppercase ? `way-input-text--uppercase` : ``
      }`}
    >
      {type === 'left' && label && required ? (
        <label htmlFor={name}>
          {label}
          <span>*</span>
        </label>
      ) : null}
      {type === 'left' && label && !required ? (
        <label htmlFor={name}>{label}</label>
      ) : null}
      {type === 'icon' && iconName ? <Icon iconName={iconName} /> : null}
      <input
        type={secure ? 'password' : 'text'}
        name={name}
        className={
          invalid
            ? 'way-input-invalid'
            : null + extraClass
            ? ' ' + extraClass
            : ''
        }
        required={required}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
      />
    </div>
  );
}

export default TextInput;
