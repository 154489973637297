import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useField } from 'formik';
import EditButton from '../edit-button/edit-button';
import PopupContainer from '../popup-container/popup-container';
import Text from '../text/text';
import Textarea from '../textarea/textarea';
import Button, { buttonType } from '../button/button';
import { Arrow } from '../icon/icon';
import { useUploadFile } from '@eypconfig/client/data-access';
import { useRefreshToken } from '@f-technology-srl/react-authorization';
import DragAndDrop from '../drag-and-drop/drag-and-drop';

import './editable-input-wrapper.scss';

export interface TextEditFormPopupInterface {
  onTextChanged: (text: string) => void;
  initalValue?: string;
  label: string;
  'data-cy'?: string;
  errorText?: string;
}

export function TextEditFormPopup(props: TextEditFormPopupInterface) {
  const [text, setText] = useState(props.initalValue);
  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
      }}
    >
      <div style={{ paddingBottom: 30 }}>
        <Text type="info-bold" color="black">
          {props.label}
        </Text>
      </div>
      <div style={{ paddingBottom: 10 }}>
        <Textarea
          placeholder={'Start typing...'}
          value={text}
          onChange={setText}
          data-cy={props['data-cy']}
        />
      </div>
      <div style={{ paddingBottom: 10 }}>
        <Text type="info" color="red">
          {props.errorText}
        </Text>
      </div>
      <div style={{ margin: 'auto' }}>
        <Button
          type={buttonType.right}
          iconName={Arrow}
          label="Save"
          onClick={() => {
            props.onTextChanged(text);
          }}
          data-cy={
            props['data-cy'] ? `save-button-for-${props['data-cy']}` : null
          }
        />
      </div>
    </div>
  );
}

export interface FileEditFormPopupInterface {
  onUriChanged: (text: string) => void;
  label: string;
  withoutSaveButton?: boolean;
  resetValue?: (cb: (newValue: string | undefined) => void) => void;
}

export function FileEditFormPopup({
  onUriChanged,
  label,
  withoutSaveButton,
  resetValue,
}: FileEditFormPopupInterface) {
  const [uri, setUri] = useState<string>(null);
  const inputRef = useRef<HTMLInputElement>();
  const [isUploading, setIsUploading] = useState(false);
  const refreshTokenAction = useRefreshToken();
  const uploadFile = useUploadFile(refreshTokenAction);
  const isChrome = window.navigator.userAgent.includes('Chrome');

  useEffect(() => {
    if (resetValue) {
      resetValue((newValue) => {
        setUri(newValue);
      });
    }
  }, [resetValue]);

  const mimetype = useMemo(() => {
    if (!uri) {
      return null;
    }
    const splitted = uri.split('.');
    const extension = splitted[splitted.length - 1];
    if (extension === 'mp4' || extension === 'webm' || extension === 'ogg') {
      return `video/${extension}`;
    }
    if (extension === 'png') {
      return 'image/png';
    }
    if (extension === 'jpg' || extension === 'jpeg') {
      return 'image/jpeg';
    }
    if (extension === 'pdf') {
      return 'application/pdf';
    }
    return extension;
  }, [uri]);

  function handleFileUpload(file: File) {
    setIsUploading(true);
    uploadFile(file)
      .then((uri) => {
        setIsUploading(false);
        return uri;
      })
      .then(setUri)
      .catch((error) => {
        setIsUploading(false);
        alert(`Unable to upload file: ${error.message}`);
      });
  }

  useEffect(() => {
    if (withoutSaveButton) {
      onUriChanged(uri);
    }
  }, [uri, withoutSaveButton, onUriChanged]);

  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
      }}
    >
      <div style={{ paddingBottom: 20 }}>
        <Text type="base-bold" color="black">
          {label}
        </Text>
      </div>
      <div style={{ paddingBottom: 20 }}>
        {uri ? (
          mimetype.includes('video') ? (
            <video width="500" height="200" controls>
              <source src={uri} type={mimetype} />
            </video>
          ) : isChrome && mimetype.includes('pdf') ? (
            <Text type="info-big" color="black">
              Item has been loaded at{' '}
              <Text
                type="link"
                color="lightgreen"
                onClick={() => window.open(uri)}
              >
                this link
              </Text>{' '}
              unable to generate a preview
            </Text>
          ) : (
            <object
              data={uri}
              type={mimetype}
              style={{
                width: 500,
                height: 250,
                objectFit: 'contain',
              }}
              aria-label="pdf-preview"
            />
          )
        ) : (
          <div>
            <DragAndDrop
              handleClick={() => inputRef.current.click()}
              handleDrop={handleFileUpload}
              loading={isUploading}
            />
            <input
              type="file"
              ref={inputRef}
              style={{ visibility: 'hidden', position: 'absolute' }}
              onChange={() => {
                const file = inputRef.current.files[0];
                if (file) {
                  handleFileUpload(file);
                }
              }}
            />
          </div>
        )}
      </div>
      {!withoutSaveButton && (
        <div style={{ margin: 'auto' }}>
          <Button
            type={buttonType.right}
            iconName={Arrow}
            label="Save"
            onClick={() => {
              onUriChanged(uri);
            }}
          />
        </div>
      )}
    </div>
  );
}

export interface EditableInputWrapperProps {
  name: string;
  label: string;
  isEditable?: boolean;
  isFile?: boolean;
  children?: (value: string, isTouched: boolean) => JSX.Element;
}

export function EditableInputWrapper(props: EditableInputWrapperProps) {
  const [field, meta, helper] = useField({ name: props.name });

  const error = useMemo(() => meta.touched && meta.error, [meta]);

  if (!props.isEditable) {
    return props.children ? props.children(field.value, false) : null;
  }

  return (
    <div className="way-editable-wrapper">
      {props.children ? props.children(field.value, meta.touched) : null}
      <PopupContainer
        openControllerComponent={(propsOpenController) => (
          <>
            <EditButton
              style={{ marginLeft: 1, maxHeight: 20 }}
              {...propsOpenController}
              data-cy={`editable-wrapper-icon-for-${props.name}`}
            />
            <Text type="info" color="red">
              {error}
            </Text>
          </>
        )}
        popContainerId="editable-wrapper-popup-id"
      >
        {({ close }) => {
          if (props.isFile) {
            return (
              <FileEditFormPopup
                label={props.label}
                onUriChanged={(value) => {
                  helper.setTouched(true);
                  helper.setValue(value);
                  close();
                }}
              />
            );
          }
          return (
            <TextEditFormPopup
              label={props.label}
              initalValue={field.value}
              onTextChanged={(value) => {
                helper.setTouched(true);
                helper.setValue(value);
                close();
              }}
              data-cy={`editable-wrapper-text-${props.name}`}
            />
          );
        }}
      </PopupContainer>
    </div>
  );
}

export default EditableInputWrapper;
