import { gql, useMutation } from '@apollo/client';
import { InstructionItem } from '../../get';

export const CREATE_INSTRUCTION_QUERY = gql`
  mutation($instructionItem: CreateInstructionItemDto!) {
    createInstruction(instructionItem: $instructionItem) {
      instruction_id
    }
  }
`;

export interface CreateInstructionResult {
  createInstruction: {
    instruction_id: string;
  };
}

export type CreateInstructionItem = Omit<
  InstructionItem,
  'instruction_id' | '__typename' | 'created_at' | 'updated_at' | 'objectTypeId'
>;

export const useCreateInstruction = () => {
  const [create, { loading, error }] = useMutation<CreateInstructionResult>(
    CREATE_INSTRUCTION_QUERY
  );
  return {
    create: (instructionItem: CreateInstructionItem) =>
      create({ variables: { instructionItem } }).then((result) => {
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
        return result.data?.createInstruction.instruction_id;
      }),
    error: error?.message,
    loading,
  };
};
