import { useQuery, gql } from '@apollo/client';
import { SelectType } from '@eypconfig/shared/types';

export const MOTOR_HEAD_QUERY = gql`
  query(
    $brand_classification: String
    $technology: String
    $size_motor: String
    $type_motor_code: String
  ) {
    getMotorsSelectOptions(
      propertyToUnique: "motor_head"
      lableTemplate: "{{ motor_head }}---{{ motor_head_image }}"
      brand_classification: $brand_classification
      technology: $technology
      size_motor: $size_motor
      type_motor_code: $type_motor_code
      range_for_configuration: true
    ) {
      data {
        label
        value
      }
    }
  }
`;

export interface MotorHeadOptions {
  getMotorsSelectOptions: {
    data: Array<SelectType>;
  };
}

export const useHeadsMotor = (
  brand_classification: string,
  technology?: string,
  size_motor?: string,
  type_motor_code?: string
) => {
  const status = useQuery<MotorHeadOptions>(MOTOR_HEAD_QUERY, {
    variables: {
      brand_classification: brand_classification
        .split('_')
        .join(' ')
        .toUpperCase(),
      technology: technology,
      size_motor: size_motor,
      type_motor_code: type_motor_code,
    },
  });

  return status;
};
