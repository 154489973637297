import { useQuery, gql } from '@apollo/client';
import { SelectType } from '@eypconfig/shared/types';

export const CONTROLS_TYPE_QUERY = gql`
  query($brand_classification: String) {
    getControlSelectOptions(
      propertyToUnique: "control_type"
      brand_classification: $brand_classification
    ) {
      data {
        label
        value
      }
    }
  }
`;

export interface ControlsTypeOptions {
  getControlSelectOptions: {
    data: Array<SelectType>;
  };
}

export const useControlsType = (brand_classification?: string) => {
  const status = useQuery<ControlsTypeOptions>(CONTROLS_TYPE_QUERY, {
    variables: {
      brand_classification: brand_classification
        ?.split('_')
        .join(' ')
        .toUpperCase(),
    },
  });

  return status;
};
