import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import './checkbox.scss';

const svgVariants = {
  hidden: {
    pathLength: 0,
  },
  visible: {
    pathLength: 1,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
};

export const whiteV = (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="11"
    fill="none"
    viewBox="0 0 18 14"
    variants={svgVariants}
    initial="hidden"
    animate="visible"
    exit="hidden"
  >
    <motion.path
      fill="#72C355"
      fillRule="evenodd"
      d="M7.702 12.803l9.157-10.824c.205-.243.184-.614-.047-.83-.231-.216-.585-.194-.79.048L7.217 11.603 1.91 7.142c-.242-.203-.594-.162-.787.092-.193.253-.154.624.087.827l5.723 4.81c.103.087.227.129.35.129.155 0 .308-.067.419-.197z"
      clipRule="evenodd"
      variants={svgVariants}
    />
    <motion.path
      stroke="#fff"
      d="M7.702 12.803l9.157-10.824c.205-.243.184-.614-.047-.83-.231-.216-.585-.194-.79.048L7.217 11.603 1.91 7.142c-.242-.203-.594-.162-.787.092-.193.253-.154.624.087.827l5.723 4.81c.103.087.227.129.35.129.155 0 .308-.067.419-.197"
      variants={svgVariants}
    />
  </motion.svg>
);

export const blackV = (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    variants={pathVariants}
    initial="hidden"
    animate="visible"
    exit="hidden"
  >
    <motion.path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.7016 11.8025L15.8585 0.979216C16.064 0.736344 16.0432 0.364505 15.812 0.14858C15.5809 -0.0672276 15.227 -0.0453997 15.0215 0.197472L6.21772 10.6033L0.90982 6.14189C0.668242 5.93885 0.315898 5.98004 0.122758 6.23379C-0.0704937 6.48749 -0.0312946 6.85774 0.210172 7.06072L5.93324 11.8711C6.03628 11.9577 6.15987 12 6.28279 12C6.43757 12 6.59128 11.933 6.7016 11.8025Z"
      fill="#727272"
      variants={pathVariants}
    />
  </motion.svg>
);

export interface CheckboxProps<T> {
  label: string;
  selected: boolean;
  value: T;
  onClick: () => void;
  name: string;
  size?: 'lg' | 'md' | 'sm';
  disabled?: boolean;
  hide?: string;
  whiteLabel?: boolean;
  blueLabel?: boolean;
  boldLabel?: boolean;
}

export function Checkbox<T = unknown>({
  selected,
  value,
  onClick,
  name,
  label,
  size,
  disabled,
  hide,
  whiteLabel,
  blueLabel,
  boldLabel,
}: CheckboxProps<T>) {
  const type = selected ? 'green' : 'white';
  return (
    <div
      tabIndex={0}
      className={`way-checkbox way-checkbox--${type} way-checkbox--${type}--${size} way-checkbox--${hide}`}
      onKeyUp={(event) => (event.key === ' ' ? onClick() : null)}
      onClick={disabled ? null : onClick}
      data-cy={`select-${name}`}
    >
      <div className={`${disabled ? 'disabled' : 'internalCheck'}`}>
        <AnimatePresence initial={false}>
          {selected ? (
            <motion.i>{type === 'green' ? whiteV : blackV}</motion.i>
          ) : null}
        </AnimatePresence>

        <input
          name={name}
          type="checkbox"
          value={`${value}`}
          checked={selected}
          onChange={onClick}
          disabled={disabled}
        />
      </div>
      <label
        htmlFor={name}
        className={`${disabled ? 'disabled' : ''} ${
          whiteLabel ? 'white' : ''
        } ${blueLabel ? 'blue' : ''} ${boldLabel ? 'bold' : ''}`}
      >
        {label}
      </label>
    </div>
  );
}

export default Checkbox;
