import { gql, useMutation } from '@apollo/client';
import { UserRoles } from './user-data.hook';

const UPDATE_USER_QUERY = gql`
  mutation ($user: UpdateUserDto!) {
    updateUser(user: $user) {
      user_id
    }
  }
`;

export interface UpdateUserPayloadInterface {
  email?: string;
  username?: string;
  name?: string;
  surname?: string;
  internal_role?: UserRoles;
  customers?: Array<string>;
  brand_classification?: Array<string>;
}

export interface UpdateUserMutationResult {
  updateUser: {
    user_id: string;
  };
}

export const useUpdateUser = (user_id?: string) => {
  const [update, result] =
    useMutation<UpdateUserMutationResult>(UPDATE_USER_QUERY);
  return {
    update: (user: UpdateUserPayloadInterface) =>
      update({
        variables: {
          user: { user_id, ...user },
        },
      }),
    ...result,
  };
};
