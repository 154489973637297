import { gql, useMutation } from '@apollo/client';
import { PackageItem } from '../../get';

export const CREATE_PACKAGES_QUERY = gql`
  mutation($packageItem: CreatePackageItemDto!) {
    createPackage(packageItem: $packageItem) {
      package_id
    }
  }
`;

export interface CreatePackageResult {
  createPackage: {
    package_id: string;
  };
}

export type CreatePackageItem = Omit<
  PackageItem,
  'package_id' | '__typename' | 'created_at' | 'updated_at' | 'objectTypeId'
>;

export const useCreatePackages = () => {
  const [create, { loading, error }] = useMutation<CreatePackageResult>(
    CREATE_PACKAGES_QUERY
  );
  return {
    create: (packageItem: CreatePackageItem) =>
      create({ variables: { packageItem } }).then((result) => {
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
        return result.data?.createPackage.package_id;
      }),
    error: error?.message,
    loading,
  };
};
