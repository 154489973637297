import { gql, useMutation } from '@apollo/client';
import { GetMotorsResultInterface } from '../get';

export const UPDATE_MOTORS_MUTATION = gql`
  mutation($motorsReference: MotorsReferenceSingleInput) {
    updateMotorReference(motorsReference: $motorsReference) {
      motor_reference_id
    }
  }
`;

export interface UpdateMotorsResultInterface {
  updateMotorReference: {
    motor_reference_id: string;
  };
}

export type UpdateMotorRequestInterface = Partial<
  Omit<
    GetMotorsResultInterface['getMotorReference'],
    | 'motor_reference_id'
    | 'other_accessories'
    | 'brackets'
    | 'adapters'
    | 'controls'
  >
> & {
  motor_reference_id: string;
  other_accessories: Array<{
    other_accessory_reference_id: string;
    package_position: string;
    package_item_quantity: string;
  }>;
  brackets: Array<{
    bracket_reference_id: string;
    package_position: string;
    package_item_quantity: string;
  }>;
  adapters: Array<{
    adapter_reference_id: string;
    package_position: string;
    package_item_quantity: string;
  }>;
  controls: Array<{
    control_reference_id: string;
    package_position: string;
    package_item_quantity: string;
  }>;
};

export const useUpdateMotor = () => {
  const [updateMotor, result] = useMutation<UpdateMotorsResultInterface>(
    UPDATE_MOTORS_MUTATION
  );
  return {
    updateMotor: (motorsReference: UpdateMotorRequestInterface) =>
      updateMotor({
        variables: {
          motorsReference,
        },
      }),
    ...result,
  };
};
