import { gql, useMutation } from '@apollo/client';

export const ENABLE_BRACKET_MUTATION = gql`
  mutation($bracket_reference_id: String!) {
    enableBracketReference(
      bracketReference: { bracket_reference_id: $bracket_reference_id }
    ) {
      bracket_reference_id
    }
  }
`;

export const useEnableBracketReference = (bracket_reference_id: string) => {
  const mutation = useMutation(ENABLE_BRACKET_MUTATION, {
    variables: { bracket_reference_id },
  });
  return mutation;
};
