/* eslint-disable react/jsx-no-useless-fragment */
import { Text } from '../text/text';
import React from 'react';

export interface SafeLoadingWrapperProps {
  loading: boolean;
  error?: string;
  loader: () => JSX.Element;
}

export function SafeLoadingWrapper(
  props: React.PropsWithChildren<SafeLoadingWrapperProps>
): JSX.Element {
  if (props.loading) {
    return props.loader();
  }

  if (props.error) {
    return (
      <Text color="red" type="column-title">
        {props.error}
      </Text>
    );
  }

  return <>{props.children}</>;
}
