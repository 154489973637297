import { gql, useMutation } from '@apollo/client';
import { NominalVoltageItem } from '../../get';

export const NOMINAL_VOLTAGE_UPDATE_QUERY = gql`
  mutation($nominalVoltageItem: UpdateNominalVoltageDto!) {
    updateNominalVoltage(nominalVoltageItem: $nominalVoltageItem) {
      nominal_voltage_id
    }
  }
`;

export interface UpdateNominalVoltageResponse {
  updateNominalVoltage: {
    nominal_voltage_id: string;
  };
}

export type UpdateNominalVoltageItem = Partial<
  Omit<NominalVoltageItem, 'nominal_voltage_id'>
> &
  Pick<NominalVoltageItem, 'nominal_voltage_id'>;

export const useUpdateNominalVoltage = () => {
  const [
    mutate,
    { loading, error },
  ] = useMutation<UpdateNominalVoltageResponse>(NOMINAL_VOLTAGE_UPDATE_QUERY);
  return {
    update: ({
      objectTypeId,
      created_at,
      updated_at,
      ...items
    }: UpdateNominalVoltageItem) =>
      mutate({
        variables: {
          nominalVoltageItem: {
            nominal_voltage_id: objectTypeId,
            ...items,
          },
        },
      }).then((result) => !result.errors),
    loading,
    error: error?.message,
  };
};
