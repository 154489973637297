import { gql, useMutation } from '@apollo/client';

const DELETE_ADAPTER_REFERENCE_MUTATION = gql`
  mutation($adapter_reference_id: String!) {
    deleteAdapterReference(
      adapterDeleteFilter: { adapter_reference_id: $adapter_reference_id }
    ) {
      adapter_reference_id
    }
  }
`;

export const useDeleteAdapter = (adapter_reference_id: string) => {
  const [deleteAdapter, result] = useMutation(
    DELETE_ADAPTER_REFERENCE_MUTATION
  );
  return {
    doDelete: () =>
      deleteAdapter({
        variables: {
          adapter_reference_id,
        },
      }).then((result) => !result.errors),
    ...result,
    error: result.error?.message,
  };
};
