import { gql, useQuery } from '@apollo/client';
import { SelectType } from '@eypconfig/shared/types';

export const CABLE_TYPE_SELECT_QUERY = gql`
  query {
    options: cableTypeSelect {
      label
      value
    }
  }
`;

export const CABLE_LENGTH_SELECT_QUERY = gql`
  query {
    options: cableLengthSelect {
      label
      value
    }
  }
`;

export const CABLE_CONNECTOR_SELECT_QUERY = gql`
  query {
    options: cableConnectorSelect {
      label
      value
    }
  }
`;

export interface CableSelectOptionsResult {
  options: Array<SelectType>;
}

export const useCableConnectorOptions = () => {
  const query = useQuery<CableSelectOptionsResult>(
    CABLE_CONNECTOR_SELECT_QUERY
  );
  return query;
};

export const useCableLengthOptions = () => {
  const query = useQuery<CableSelectOptionsResult>(CABLE_LENGTH_SELECT_QUERY);
  return query;
};

export const useCableTypeOptions = () => {
  const query = useQuery<CableSelectOptionsResult>(CABLE_TYPE_SELECT_QUERY);
  return query;
};
