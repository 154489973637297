import { useQuery, gql, useLazyQuery } from '@apollo/client';
import {
  MotorsSearchFilter,
  PaginateResultInterface,
  PaginationArgs,
} from '@eypconfig/shared/types';

const MOTORS_SEARCH_CATALOGUE_QUERY = gql`
  query(
    $page: Int
    $offset: Int
    $reference: [String!]
    $referenceLike: String
    $brand: [String!]
    $brand_classification: [String!]
    $customer: [String!]
    $somfy_match: [String!]
    $technology: [String!]
    $type_motor: [String!]
    $nominal_voltage: [String!]
    $size_motor: [String!]
    $motor_head: [String!]
    $nominal_torque: [String!]
    $nominal_speed: [String!]
    $cable_type: [String!]
    $cable_lenght: [String!]
    $cable_connector_type: [String!]
    $packaging_type: [String!]
    $configuration_a: [String!]
  ) {
    searchMotors(
      page: $page
      offset: $offset
      reference: $reference
      brand: $brand
      brand_classification: $brand_classification
      customer: $customer
      somfy_match: $somfy_match
      technology: $technology
      type_motor: $type_motor
      nominal_voltage: $nominal_voltage
      size_motor: $size_motor
      motor_head: $motor_head
      nominal_torque: $nominal_torque
      nominal_speed: $nominal_speed
      cable_type: $cable_type
      cable_lenght: $cable_lenght
      cable_connector_type: $cable_connector_type
      packaging_type: $packaging_type
      referenceLike: $referenceLike
      configuration_a: $configuration_a
    ) {
      count
      offset
      page
      data {
        __typename
        motor_reference_id
        reference
        designation
        designation_0
        designation_1
        technology
        product_image
        complete_product_image
        brand_image
        torque_speed
        nr_of_end_limits
        type_lsu
        adjustment_type
        nominal_torque
        cable_type
        certification_ce
        certification_vde
        certification_nf
        certification_cmim
        certification_pai
        certification_saber
        made_in
        brand
        motor_head
        packaging_type
        packaging_brand
        packaging_nr_of_motors
        packaging_nr_of_brackets
        packaging_nr_of_adapters
        packaging_nr_of_controls
        packaging_nr_of_instruction_leaflets
        packaging_nr_of_other_accessories
        instruction_logo
        somfy_match
        photo_low_resolution
        ean_code
        nominal_speed
        nominal_voltage
        rated_power
        rated_current
        l3_max
        dimension_image
        euro_pallet_image
        technology_image
        type_motor
        type_motor_code
        package_label_image
        motor_label_image
        three_d_video
        catalogue
        product_technical_sheet
        warning_and_instruction_leafsets
        ce_declaration
        vde_certification
        nf_certification
        compare_declaration
        photo_high_resolution
        dimensional_drawings
        mini_programming_guide
        video_installation
        obstacle_detection
        freez_detection
        end_limit_setting_tool
        transmission_frequency
        brand_and_address
        instruction_languages
      }
    }
  }
`;

const MOTORS_COUNT_QUERY = gql`
  query {
    searchMotors {
      count
    }
  }
`;

export interface MotorCatalogueInterface {
  __typename: 'MotorReference';
  motor_reference_id: string;
  reference: string;
  designation: string;
  designation_0?: string;
  designation_1?: string;
  technology: string;
  product_image?: string;
  complete_product_image?: string;
  brand_image?: string;
  torque_speed?: string;
  nr_of_end_limits?: string;
  type_lsu?: string;
  adjustment_type?: string;
  nominal_torque?: string;
  cable_type?: string;
  certification_ce?: boolean;
  certification_vde?: boolean;
  certification_nf?: boolean;
  certification_cmim?: boolean;
  certification_pai?: boolean;
  certification_saber?: boolean;
  made_in?: string;
  brand?: string;
  somfy_match?: string;
  motor_head?: string;
  packaging_type?: string;
  packaging_brand?: string;
  packaging_nr_of_motors?: string;
  packaging_nr_of_brackets?: string;
  packaging_nr_of_adapters?: string;
  packaging_nr_of_controls?: string;
  packaging_nr_of_instruction_leaflets?: string;
  packaging_nr_of_other_accessories?: string;
  instruction_logo?: string;
  photo_low_resolution?: string;
  ean_code?: string;
  nominal_speed?: string;
  nominal_voltage?: string;
  rated_power?: string;
  rated_current?: string;
  l3_max?: string;
  dimension_image?: string;
  euro_pallet_image?: string;
  technology_image: string;
  type_motor: string;
  type_motor_code: string;
  package_label_image?: string;
  motor_label_image?: string;
  three_d_video?: string;
  catalogue?: string;
  product_technical_sheet?: string;
  warning_and_instruction_leafsets?: string;
  ce_declaration?: string;
  vde_certification?: string;
  nf_certification?: string;
  compare_declaration?: string;
  photo_high_resolution?: string;
  dimensional_drawings?: string;
  mini_programming_guide?: string;
  video_installation?: string;
  obstacle_detection?: boolean;
  freez_detection?: string;
  end_limit_setting_tool?: string;
  transmission_frequency?: string;
  brand_and_address?: string;
  instruction_languages?: Array<string>;
}

export interface SearchMotorsCatalogQueryResultInterface {
  searchMotors: PaginateResultInterface<MotorCatalogueInterface>;
}

export const useSearchMotorsCatalogue = (
  filters: MotorsSearchFilter & PaginationArgs
) => {
  const data = useQuery<SearchMotorsCatalogQueryResultInterface | null>(
    MOTORS_SEARCH_CATALOGUE_QUERY,
    {
      variables: filters,
    }
  );

  return data;
};

export const useLazySearchMotorsCatalogue = (
  filters: MotorsSearchFilter & PaginationArgs
) => {
  const data = useLazyQuery<SearchMotorsCatalogQueryResultInterface | null>(
    MOTORS_SEARCH_CATALOGUE_QUERY,
    {
      variables: filters,
    }
  );

  return data;
};

export interface CountMotorsCatalogQueryResultInterface {
  searchMotors: {
    count: number;
  };
}

export const useLazyCountMotors = () => {
  const data = useLazyQuery<CountMotorsCatalogQueryResultInterface | null>(
    MOTORS_COUNT_QUERY
  );

  return data;
};
