import { gql, useMutation } from '@apollo/client';
import { GetControlResultInterface } from '../get';

export const UPDATE_CONTROLLER_MUTATION = gql`
  mutation($controlReference: ControlReferenceUpdateInput) {
    updateControlReference(controlReference: $controlReference) {
      control_reference_id
    }
  }
`;

export interface UpdateControllerResultInterface {
  updateControlReference: {
    control_reference_id: string;
  };
}

export type ControlReferenceUpdateInterface = Partial<
  Omit<GetControlResultInterface['getControlReference'], 'control_reference_id'>
> & { control_reference_id: string };

export const useUpdateController = () => {
  const [
    updateController,
    result,
  ] = useMutation<UpdateControllerResultInterface>(UPDATE_CONTROLLER_MUTATION);
  return {
    updateController: (controlReference: ControlReferenceUpdateInterface) =>
      updateController({
        variables: {
          controlReference,
        },
      }),
    ...result,
  };
};
