import { gql, useMutation } from '@apollo/client';

export const DELETE_INSTRUCTION_SINGLE_QUERY = gql`
  mutation($instruction_id: String!) {
    deleteInstruction(instruction_id: $instruction_id) {
      instruction_id
    }
  }
`;

export const useDeleteInstruction = (objectTypeId: string) => {
  const [doDelete, result] = useMutation(DELETE_INSTRUCTION_SINGLE_QUERY, {
    variables: {
      instruction_id: objectTypeId,
    },
  });

  return {
    doDelete: () => doDelete().then((result) => !result.errors),
    loading: result.loading,
    error: result.error?.message,
  };
};
