import { gql, useQuery } from '@apollo/client';
import { PaginateResultInterface } from '@eypconfig/shared/types';

export const BRACKETS_SEARCH_BACKOFFICE_QUERY = gql`
  query($page: Int, $offset: Int, $referenceLike: String) {
    result: searchBrackets(
      page: $page
      offset: $offset
      referenceLike: $referenceLike
    ) {
      count
      offset
      data {
        __typename
        objectTypeId: bracket_reference_id
        reference
        designation
      }
    }
  }
`;

export interface BracketBackofficeListFilter {
  page?: string;
  offset?: string;
  referenceFilter?: string;
}

export interface BracketBackofficeInterface {
  __typename: 'BracketReference';
  objectTypeId: string;
  reference: string;
  designation: string;
}

export interface SearchBracketsBackofficeQueryResultInterface {
  result: PaginateResultInterface<BracketBackofficeInterface>;
}

export const useSearchBracketsBackoffice = (
  filter?: BracketBackofficeListFilter
) => {
  const data = useQuery<SearchBracketsBackofficeQueryResultInterface | null>(
    BRACKETS_SEARCH_BACKOFFICE_QUERY,
    {
      variables: {
        referenceLike: filter.referenceFilter,
        page: Number(filter.page),
        offset: Number(filter.offset),
      },
    }
  );

  return data;
};
