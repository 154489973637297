import { gql, useQuery } from '@apollo/client';

export const MOTOR_3D_VIDEO = gql`
  query(
    $brand_classification: [String!]
    $size_motor: [String!]
    $type_motor: [String!]
  ) {
    searchMotors(
      page: 0
      offset: 1
      brand_classification: $brand_classification
      size_motor: $size_motor
      type_motor: $type_motor
    ) {
      data {
        video_installation
      }
    }
  }
`;

export interface Motor3DVideoOptions {
  brand_classification: string;
  size_motor: string;
  type_motor: string;
}

export const useMotor3DVideo = (options: Motor3DVideoOptions) => {
  const result = useQuery(MOTOR_3D_VIDEO, {
    variables: {
      brand_classification: options.brand_classification
        ?.split('_')
        ?.join(' ')
        ?.toUpperCase(),
      size_motor: options.size_motor,
      type_motor: options.type_motor,
    },
  });
  return {
    ...result,
    data: result.data?.searchMotors?.data?.[0]?.video_installation as
      | string
      | null,
  };
};
