import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { GetControlResultInterface } from '../backoffice/get';

export const GENERIC_CONTROL_OPTIONS_QUERY = gql`
  query($propertyToUnique: String!) {
    result: getControlSelectOptions(
      propertyToUnique: $propertyToUnique
      offset: 400
    ) {
      options: data {
        label
        value
      }
    }
  }
`;

export type PropertyToUniqueControlType = keyof Omit<
  GetControlResultInterface['getControlReference'],
  '__typename' | 'deleted_at' | 'updated_at'
>;

export interface GenericControlOptionsResult {
  result: {
    options: Array<{
      label: string;
      value: string;
    }>;
  };
}

export const useGenericControlOptions = (
  propertyToUnique: PropertyToUniqueControlType
) => {
  return useQuery<GenericControlOptionsResult>(GENERIC_CONTROL_OPTIONS_QUERY, {
    variables: { propertyToUnique },
  });
};
