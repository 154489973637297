import { gql, useMutation } from '@apollo/client';

const DELETE_CONTROL_REFERENCE_MUTATION = gql`
  mutation($control_reference_id: String!) {
    deleteControlReference(
      controlDeleteFilter: { control_reference_id: $control_reference_id }
    ) {
      control_reference_id
    }
  }
`;

export const useDeleteControl = (control_reference_id: string) => {
  const [deleteControl, result] = useMutation(
    DELETE_CONTROL_REFERENCE_MUTATION
  );
  return {
    doDelete: () =>
      deleteControl({
        variables: {
          control_reference_id,
        },
      }).then((result) => !result.errors),
    ...result,
    error: result.error?.message,
  };
};
