import { gql, useMutation } from '@apollo/client';

const DELETE_OTHER_ACCESSORY_REFERENCE_MUTATION = gql`
  mutation($other_accessory_reference_id: String!) {
    deleteOtherAccessoryReference(
      otherAccessoryDeleteFilter: {
        other_accessory_reference_id: $other_accessory_reference_id
      }
    ) {
      other_accessory_reference_id
    }
  }
`;

export const useDeleteOtherAccessory = (
  other_accessory_reference_id: string
) => {
  const [deleteOtherAccessory, result] = useMutation(
    DELETE_OTHER_ACCESSORY_REFERENCE_MUTATION
  );
  return {
    doDelete: () =>
      deleteOtherAccessory({
        variables: {
          other_accessory_reference_id,
        },
      }).then((result) => !result.errors),
    ...result,
    error: result.error?.message,
  };
};
