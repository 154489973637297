import { Link } from 'react-router-dom';
import ReactDom from 'react-dom';
import {
  IconGear,
  IconImportExport,
  IconMenuArchive,
  IconMenuHome,
  IconMenuReference,
  IconPeople,
} from '../icons-button/icons';
import Text from '../text/text';

import './side-navigation.scss';

/* eslint-disable-next-line */
export interface SideNavigationProps {
  state: boolean;
  idPortal: string;
  nameSurname: string;
  onLogout: () => void;
  isAdmin?: boolean;
  isEditor?: boolean;
}

export function SideNavigation({
  state,
  idPortal,
  onLogout,
  nameSurname,
  isAdmin,
  isEditor,
}: SideNavigationProps) {
  return ReactDom.createPortal(
    <div
      className={`way-navigation ${
        state === true ? 'way-navigation-open' : ''
      }`}
    >
      <div className="way-nav-container">
        <div className="way-nav-user">
          <div className="way-nav-single-circle__container">
            <span className="way-nav-single-circle__letter">
              {nameSurname[0]}
            </span>
          </div>
          <div className="way-avatar-data">
            <Text type="base-bold" color="white">
              {nameSurname}
            </Text>
            <div data-cy="logout-button">
              <Text type="base-s" color="white" onClick={onLogout}>
                Logout
              </Text>
            </div>
          </div>
        </div>
        <ul className="way-nav ">
          <li className="way-nav-item">
            <Link data-cy="home-button" className="nav-link" to="/home">
              <IconMenuHome />
              <Text type="base-s-bold" color="white">
                Home
              </Text>
            </Link>
          </li>
          <li className="way-nav-item">
            <Link data-cy="catalog-button" className="nav-link" to="/catalog">
              <IconMenuArchive />
              <Text type="base-s-bold" color="white">
                Search existing reference
              </Text>
            </Link>
          </li>
          {(isAdmin || isEditor) && (
            <li className="way-nav-item">
              <Link
                data-cy="configurator-button"
                className="nav-link"
                to="/configurator"
              >
                <IconMenuReference />
                <Text type="base-s-bold" color="white">
                  Configurate new reference
                </Text>
              </Link>
            </li>
          )}
          {isAdmin && (
            <li className="way-nav-item">
              <Link
                data-cy="user-management-button"
                className="nav-link"
                to="/admin/user-management"
              >
                <IconPeople width={24} height={24} />
                <Text type="base-s-bold" color="white">
                  User management
                </Text>
              </Link>
            </li>
          )}
          {isAdmin && (
            <li className="way-nav-item">
              <Link
                data-cy="backoffice-button"
                className="nav-link"
                to="/admin/data-management"
              >
                <IconGear size="medium" />
                <Text type="base-s-bold" color="white">
                  Data management
                </Text>
              </Link>
            </li>
          )}
          {isAdmin && (
            <li className="way-nav-item">
              <Link
                data-cy="import-export-csv-button"
                className="nav-link"
                to="/admin/import-export-csv"
              >
                <IconImportExport size="medium" />
                <Text type="base-s-bold" color="white">
                  Import / Export CSV
                </Text>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>,
    document.getElementById(idPortal)
  );
}

export default SideNavigation;
