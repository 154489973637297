import { gql, useLazyQuery } from '@apollo/client';

// *** CABLE CONNECTION SECTION ***

export const GET_CABLE_CONNECTOR_QUERY = gql`
  query($cable_connector_id: String!) {
    result: getCableConnector(cable_connector_id: $cable_connector_id) {
      cable_connector_id
      objectTypeId: cable_connector_id
      name
      image
      created_at
      updated_at
    }
  }
`;

export interface CableConnectorItem {
  cable_connector_id: string;
  objectTypeId: string;
  name: string;
  image?: string;
  created_at: Date;
  updated_at: Date;
  __typename: string;
}

export interface GetCableConnectorResult {
  result: CableConnectorItem;
}

export const useGetCableConnector = (cable_connector_id: string) => {
  const res = useLazyQuery<GetCableConnectorResult>(GET_CABLE_CONNECTOR_QUERY, {
    variables: { cable_connector_id },
  });
  return res;
};

// *** CABLE TYPE SECTION ***

export const GET_CABLE_TYPE_QUERY = gql`
  query($cable_type_id: String!) {
    result: getCableType(cable_type_id: $cable_type_id) {
      objectTypeId: cable_type_id
      cable_type_id
      label
      code
      color
      created_at
      updated_at
    }
  }
`;

export interface CableTypeItem {
  objectTypeId: string;
  cable_type_id: string;
  label: string;
  code: string;
  color: string;
  created_at: Date;
  updated_at: Date;
  __typename: string;
}

export interface GetCableTypeResult {
  result: CableTypeItem;
}

export const useGetCableType = (cable_type_id: string) => {
  const res = useLazyQuery<GetCableTypeResult>(GET_CABLE_TYPE_QUERY, {
    variables: { cable_type_id },
  });
  return res;
};

// *** CABLE LENGTH SECTION ***

export const GET_CABLE_LENGTH_QUERY = gql`
  query($cable_length_id: String!) {
    result: getCableLength(cable_length_id: $cable_length_id) {
      objectTypeId: cable_length_id
      cable_length_id
      label
      value
      meters
      created_at
      updated_at
    }
  }
`;

export interface CableLengthItem {
  objectTypeId: string;
  cable_length_id: string;
  label: string;
  value: string;
  meters: number;
  created_at: Date;
  updated_at: Date;
  __typename: string;
}

export interface GetCableLengthResult {
  result: CableLengthItem;
}

export const useGetCableLength = (cable_length_id: string) => {
  const res = useLazyQuery<GetCableLengthResult>(GET_CABLE_LENGTH_QUERY, {
    variables: { cable_length_id },
  });
  return res;
};
