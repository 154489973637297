import { useQuery, gql } from '@apollo/client';
import { SelectType } from '@eypconfig/shared/types';

export const CONTROLS_PACKAGE_TYPE_QUERY = gql`
  query($brand_classification: String, $product_classification: String) {
    getPackagesSelectOptions(
      propertyToUnique: "packaging_type"
      brand_classification: $brand_classification
      product_classification: $product_classification
    ) {
      data {
        label
        value
      }
    }
  }
`;

export interface ControlsPackageTypeOptions {
  getPackagesSelectOptions: {
    data: Array<SelectType>;
  };
}

export const useControlsPackageType = (
  brand_classification: string,
  product_classification: string
) => {
  const status = useQuery<ControlsPackageTypeOptions>(
    CONTROLS_PACKAGE_TYPE_QUERY,
    {
      variables: {
        brand_classification: brand_classification
          .split('_')
          .join(' ')
          .toUpperCase(),
        product_classification: product_classification
          .split('_')
          .join(' ')
          .toUpperCase(),
      },
    }
  );

  return status;
};
