import { gql, useMutation } from '@apollo/client';

export const ENABLE_OTHER_ACCESSORY_MUTATION = gql`
  mutation($other_accessory_reference_id: String!) {
    enableOtherAccessoryReference(
      otherAccessoryReference: {
        other_accessory_reference_id: $other_accessory_reference_id
      }
    ) {
      other_accessory_reference_id
    }
  }
`;

export const useEnableOtherAccessoryReference = (
  other_accessory_reference_id: string
) => {
  const mutation = useMutation(ENABLE_OTHER_ACCESSORY_MUTATION, {
    variables: { other_accessory_reference_id },
  });
  return mutation;
};
