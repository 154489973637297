import { gql, useLazyQuery } from '@apollo/client';

export const LABEL_SINGLE_QUERY = gql`
  query($label_id: String!) {
    result: getLabel(label_id: $label_id) {
      label_id
      objectTypeId: label_id
      brand_classification
      product_classification
      product_label_image
      motor_size
      type_code
      technology
      package_label_image
      multi_package_label_image
      created_at
      updated_at
    }
  }
`;

export interface LabelItem {
  label_id: string;
  objectTypeId: string;
  brand_classification: string;
  product_classification: Array<string>;
  product_label_image?: string;
  motor_size: string;
  type_code: Array<string>;
  technology: string;
  package_label_image?: string;
  multi_package_label_image?: string;
  updated_at: Date;
  created_at: Date;
  __typename: string;
}

export interface GetLabelResult {
  result: LabelItem;
}

export const useGetLabel = (label_id: string) => {
  const res = useLazyQuery<GetLabelResult>(LABEL_SINGLE_QUERY, {
    variables: { label_id },
  });
  return res;
};
