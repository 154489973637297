import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

export const GET_SINGLE_CONFIGURATION = gql`
  query ($configuration_id: String!) {
    getConfiguration(configuration_id: $configuration_id) {
      configuration_id
      zip_code
      website
      group_entity
      customer_name
      address_1
      address_2
      city
      zip_code
      country
      phone
      website
      email
      customer_logo_and_graphic_elements
      clone_controls_reference_when_motors
      brand_classification
      brand_name
      motorReferencesList {
        motor_reference_id
        reference
        brand_classification
        brand_classification_image
        brand
        brand_image
        range_for_configuration
        made_in
        size_motor
        technology
        technology_image
        type_motor
        type_motor_code
        somfy_match
        activation
        parallel_connection
        radio_compatibility
        customer
        new_customer_from_configuration
        designation_0
        designation
        designation_1
        complete_product_image
        motor_head
        motor_head_image
        plastic_cap_color
        plastic_cap_color_image
        is_logo_on_the_cap
        tube_color
        tube_hexdecimal_code
        plastic_cap_color_hex_code
        tube_color_image
        tube_material
        product_image
        type_lsu
        nr_of_end_limits
        adjustment_type
        obstacle_detection
        freez_detection
        power_supply_g
        nominal_voltage
        power_supply_tolerances
        voltage
        working_frequency
        thermal_time
        thermal_time_label
        cable_tipology
        cable_color_type
        cable_connector
        cable_connector_name
        cable_connector_image
        cable_type
        cable_type_image
        cable_number_of_wires
        cable_wire_section
        cable_lenght
        brake_type
        capacity_of_lsu
        repeatability
        system_of_protection
        temperature_working_range
        noise_level
        interface_drawing
        electromagnetic_compatibility_1
        electromagnetic_compatibility_2
        electromagnetic_compatibility_3
        electromagnetic_compatibility_4
        electromagnetic_compatibility_5
        electromagnetic_compatibility_6
        basic_crown_for_tube
        electrical_safety
        health_test
        nominal_torque
        nominal_speed
        torque_speed
        rated_power
        rated_current
        insulation_class
        l3_max
        dimension_image
        weight
        included_bracket_meta
        included_adapters_meta
        other_accessories_meta
        included_control_meta
        certification_ce
        certification_vde
        certification_nf
        certification_cmim
        certification_pai
        certification_saber
        motor_label_image
        motor_barcode_image
        ean_code
        vertical_screens
        vertical_screens_image
        zebra_screen
        zebra_screen_image
        rolling_shutters
        rolling_shutters_image
        leteral_arms_awnings
        leteral_arms_awnings_image
        awnings_with_arms
        awnings_with_arms_image
        cassette_awnings
        cassette_awnings_image
        instruction_leaflets_image
        instruction_logo
        instruction_languages
        package_label_image
        packaging_nr_of_motors
        packaging_nr_of_brackets
        packaging_nr_of_adapters
        packaging_nr_of_controls
        packaging_nr_of_instruction_leaflets
        packaging_quantity
        packaging_graphic_layout_choice
        packaging_type
        packaging_print_finiture
        packaging_brand
        packaging_dimension_l
        packaging_dimension_w
        packaging_dimension_h
        packaging_dimension_image
        packaging_weight
        euro_pallet_nr_psc
        euro_pallet_image
        euro_pallet_nr_single_package
        euro_pallet_total_dimensions
        catalogue
        product_technical_sheet
        warning_and_instruction_leafsets
        ce_declaration
        vde_certification
        nf_certification
        compare_declaration
        photo_high_resolution
        photo_low_resolution
        dimensional_drawings
        video_installation
        size_motor
        forecast_annual_volumes
        is_draft
        created_at
        end_limit_setting_tool
        transmission_frequency
        coding
        memory_capacity
        range
        multi_pack_type
        multi_pack_print_finiture
        multi_pack_brand
        pallet_type
        pallet_empty_dimensions
        multi_pack_dimensions_image
        pallet_dimensions_image
        multi_pack_weight
        pallet_number_of_packages
        pallet_number_pcs_layers
        pallet_weight
        multi_package_label_image
        multi_pack_dim_l
        multi_pack_dim_w
        multi_pack_dim_h
        motor_barcode
        pallet_dimensions_l
        pallet_dimensions_w
        pallet_dimensions_h
        other_accessories {
          other_accessory_reference_id
          reference
          unit_reference
          brand_classification
          range_for_configuration
          brand
          brand_image
          customer
          designation
          other_accessory_image
          somfy_match
          motor_size
          rolling_tube
          motor_type
          package_type
          package_box
          package_lenght
          package_height
          package_width
          package_weight
          package_image
          applications
          package_item_quantity
          packaging_quantity
          package_position
          is_draft
          new_customer
        }
        brackets {
          bracket_reference_id
          reference
          unit_reference
          brand_classification
          range_for_configuration
          brand
          brand_image
          customer
          designation
          bracket_image
          somfy_match
          motor_size
          motor_type
          motor_head
          nominal_torque
          package_quantity
          package_lenght
          package_height
          package_width
          package_weight
          unit_weight
          package_image
          package_label_image
          package_type
          applications
          package_item_quantity
          packaging_quantity
          package_position
          is_draft
          new_customer
        }
        adapters {
          adapter_reference_id
          reference
          unit_reference
          brand_classification
          range_for_configuration
          brand
          brand_image
          customer
          designation
          adapter_image
          somfy_match
          motor_size
          motor_type
          rolling_tube
          package_box
          package_lenght
          package_height
          package_width
          package_weight
          unit_weight
          package_image
          package_type
          applications
          package_item_quantity
          packaging_quantity
          package_position
          is_draft
          new_customer
        }
        controls {
          control_reference_id
          reference
          brand_classification
          range_for_configuration
          brand
          brand_image
          made_in
          technology
          control_type
          somfy_match
          customer
          short_designation
          designation
          designation_1
          graphic_on_the_cover_image
          product_image
          dimension_drawing_image
          transmission_frequence
          range
          remote_control_class
          protection_rate
          working_temperature
          storage_temperature
          battery_type
          package_box
          package_type
          package_quantity
          package_item_quantity
          package_length
          package_width
          package_height
          package_weight
          package_image
          package_label_image
          number_of_controls
          number_of_wall_support
          number_of_instruction_leaflets
          instructions_logo_address
          instructions_image
          instruction_languages
          package_position
          is_draft
          new_customer
        }
      }
      adapterReferencesList {
        adapter_reference_id
        reference
        unit_reference
        brand_classification
        range_for_configuration
        brand
        brand_image
        customer
        designation
        adapter_image
        somfy_match
        motor_size
        motor_type
        rolling_tube
        package_box
        package_lenght
        package_height
        package_width
        package_weight
        unit_weight
        package_image
        package_type
        applications
        packaging_quantity
        is_draft
        nr_of_adapters
        created_at
        nominal_torque
        pallet_nr_pcs
        ref_customer
        barcode
        ean
        multi_pack_lenght
        multi_pack_width
        multi_pack_height
        multi_pack_weight
        pallet_lenght
        pallet_width
        pallet_height
        pallet_nr_packages
        pallet_weight
        package_cardboard
        package_brand
        multi_pack_type
        multi_pack_cardboard
        multi_pack_brand
        pallet_type
        pallet_empty_dimensions
        multi_pack_dimensions_image
        pallet_dimensions_image
        made_in
        new_customer
      }
      otherAccessoryReferencesList {
        other_accessory_reference_id
        reference
        unit_reference
        brand_classification
        range_for_configuration
        brand
        brand_image
        customer
        designation
        other_accessory_image
        somfy_match
        motor_size
        rolling_tube
        motor_type
        package_type
        package_box
        package_lenght
        package_height
        package_width
        package_weight
        package_image
        applications
        packaging_quantity
        is_draft
        package_dimension_image
        nr_of_other_acc
        motor_head
        nominal_torque
        unit_weight
        made_in
        package_cardboard
        package_brand
        multi_pack_type
        multi_pack_cardboard
        multi_pack_brand
        pallet_type
        pallet_empty_dimensions
        multi_pack_dimensions_image
        pallet_dimensions_image
        pallet_nr_pcs
        ref_customer
        barcode
        ean
        multi_pack_lenght
        multi_pack_width
        multi_pack_height
        multi_pack_weight
        pallet_lenght
        pallet_width
        pallet_height
        pallet_nr_packages
        pallet_weight
        new_customer
      }
      controlReferencesList {
        control_reference_id
        reference
        brand_classification
        range_for_configuration
        brand
        made_in
        technology
        control_type
        somfy_match
        customer
        brand_image
        short_designation
        designation
        designation_1
        graphic_on_the_cover_image
        product_image
        dimension_drawing_image
        transmission_frequence
        range
        remote_control_class
        protection_rate
        working_temperature
        storage_temperature
        battery_type
        package_box
        package_type
        package_quantity
        package_length
        package_width
        package_height
        package_weight
        package_image
        certification_ce
        certification_vde
        certification_nf
        certification_cmim
        certification_pai
        certification_saber
        vertical_screen
        zebra_screen
        rolling_shutter
        lateral_arms_awning
        awning_with_arms
        casette_awning
        package_label_image
        number_of_controls
        number_of_wall_support
        number_of_instruction_leaflets
        instructions_logo_address
        instructions_image
        instruction_languages
        graphics_on_cover
        is_draft
        complete_product_image
        complete_dimension_image
        barcode
        ean_code
        cover_color
        number_of_channels
        insulation_class
        protection_degree
        dimensions_all
        forecast_annual_volumes
        multi_package_label_image
        control_label_image
        pallet_dimensions_all
        multi_package_weight
        pallet_nr_pcs_layers
        pallet_nr_packages
        pallet_weight
        package_cardboard_print_finiture
        package_brand
        multi_package_type
        multi_package_cardboard_print_finiture
        multi_package_brand
        pallet_type
        package_dimensions_image
        multi_package_dimensions_image
        pallet_dimensions_image
        ref_for_customer
        pallet_empty_dimensions_all
        multi_package_lenght
        multi_package_width
        multi_package_height
        pallet_lenght
        pallet_width
        pallet_height
        created_at
        new_customer
      }
      bracketReferencesList {
        bracket_reference_id
        reference
        unit_reference
        brand_classification
        range_for_configuration
        brand
        brand_image
        customer
        designation
        bracket_image
        somfy_match
        motor_size
        motor_type
        motor_head
        nominal_torque
        package_quantity
        package_lenght
        package_height
        package_width
        package_weight
        unit_weight
        package_image
        package_label_image
        package_type
        applications
        packaging_quantity
        is_draft
        nr_of_brackets
        made_in
        created_at
        ref_customer
        barcode
        ean
        multi_pack_weight
        pallet_nr_pcs
        pallet_nr_packages
        pallet_weight
        multi_pack_lenght
        multi_pack_width
        multi_pack_height
        pallet_lenght
        pallet_width
        pallet_height
        pallet_empty_dimensions
        pallet_dimensions_image
        multi_pack_type
        package_brand
        package_cardboard
        multi_pack_cardboard
        multi_pack_brand
        pallet_type
        new_customer
      }
    }
  }
`;

export interface GetBracketConfigurationReferenceInterface {
  __typename?: string;
  bracket_reference_id: string;
  reference?: string;
  unit_reference?: string;
  brand_classification: Array<string>;
  range_for_configuration: boolean;
  brand?: string;
  brand_image?: string;
  customer?: Array<string>;
  designation?: string;
  bracket_image?: string;
  somfy_match?: string;
  motor_size: string;
  motor_type: Array<string>;
  motor_head: string;
  nominal_torque?: string;
  package_lenght?: string;
  package_height?: string;
  package_width?: string;
  package_weight?: string;
  unit_weight?: string;
  package_image?: string;
  package_label_image?: string;
  package_type?: string;
  applications?: string;
  packaging_quantity?: string;
  package_quantity?: string;
  package_position?: string;
  is_draft: boolean;
  nr_of_brackets?: string;
  made_in?: string;
  created_at: Date;
  ref_customer?: string;
  barcode?: string;
  ean?: string;
  multi_pack_weight?: string;
  pallet_nr_pcs?: string;
  pallet_nr_packages?: string;
  pallet_weight?: string;
  multi_pack_lenght?: string;
  multi_pack_width?: string;
  multi_pack_height?: string;
  pallet_lenght?: string;
  pallet_width?: string;
  pallet_height?: string;
  pallet_empty_dimensions?: string;
  pallet_dimensions_image?: string;
  multi_pack_type?: string;
  package_brand?: string;
  package_cardboard?: string;
  multi_pack_cardboard?: string;
  multi_pack_brand?: string;
  pallet_type?: string;
  new_customer?: string;
}

export interface GetControlConfigurationReferenceInterface {
  __typename?: string;
  control_reference_id: string;
  reference?: string;
  brand_classification: Array<string>;
  range_for_configuration: boolean;
  brand?: string;
  brand_image?: string;
  made_in?: string;
  technology: string;
  control_type: string;
  somfy_match?: string;
  customer?: Array<string>;
  short_designation?: string;
  designation?: string;
  designation_1?: string;
  graphic_on_the_cover_image?: string;
  product_image?: string;
  dimension_drawing_image?: string;
  transmission_frequence?: string;
  range?: string;
  remote_control_class?: string;
  protection_rate?: string;
  working_temperature?: string;
  storage_temperature?: string;
  battery_type?: string;
  package_box?: string;
  package_type?: string;
  package_length?: string;
  package_width?: string;
  package_height?: string;
  package_weight?: string;
  certification_ce: boolean;
  certification_vde: boolean;
  certification_nf: boolean;
  certification_cmim: boolean;
  certification_pai: boolean;
  certification_saber: boolean;
  vertical_screen: boolean;
  rolling_shutter: boolean;
  lateral_arms_awning: boolean;
  awning_with_arms: boolean;
  casette_awning: boolean;
  zebra_screen: boolean;
  package_image?: string;
  package_label_image?: string;
  number_of_controls?: string;
  number_of_wall_support?: string;
  number_of_instruction_leaflets?: string;
  instructions_logo_address?: string;
  instructions_image?: string;
  instruction_languages: Array<string>;
  package_quantity?: string;
  package_position?: string;
  graphics_on_cover: boolean;
  is_draft: boolean;
  complete_product_image?: string;
  complete_dimension_image?: string;
  barcode?: string;
  ean_code?: string;
  cover_color?: string;
  number_of_channels?: string;
  insulation_class?: string;
  protection_degree?: string;
  dimensions_all?: string;
  forecast_annual_volumes?: string;
  multi_package_label_image?: string;
  control_label_image?: string;
  pallet_dimensions_all?: string;
  multi_package_weight?: string;
  pallet_nr_pcs_layers?: string;
  pallet_nr_packages?: string;
  pallet_weight?: string;
  package_cardboard_print_finiture?: string;
  package_brand?: string;
  multi_package_type?: string;
  multi_package_cardboard_print_finiture?: string;
  multi_package_brand?: string;
  pallet_type?: string;
  package_dimensions_image?: string;
  multi_package_dimensions_image?: string;
  pallet_dimensions_image?: string;
  ref_for_customer?: string;
  pallet_empty_dimensions_all?: string;
  multi_package_lenght?: string;
  multi_package_width?: string;
  multi_package_height?: string;
  pallet_lenght?: string;
  pallet_width?: string;
  pallet_height?: string;
  new_customer?: string;
  created_at?: Date;
}

export interface GetOtherAccessoryConfigurationReferenceInterface {
  __typename?: string;
  other_accessory_reference_id: string;
  reference?: string;
  unit_reference?: string;
  brand_classification: Array<string>;
  range_for_configuration: boolean;
  brand?: string;
  brand_image?: string;
  customer: Array<string>;
  designation?: string;
  other_accessory_image?: string;
  somfy_match?: string;
  motor_size: Array<string>;
  rolling_tube: string;
  motor_type: Array<string>;
  package_type?: string;
  package_box?: string;
  package_lenght?: string;
  package_height?: string;
  package_width?: string;
  package_weight?: string;
  package_image?: string;
  applications?: string;
  packaging_quantity?: string;
  package_position?: string;
  is_draft: boolean;
  package_dimension_image?: string;
  nr_of_other_acc?: string;
  motor_head?: string;
  nominal_torque?: string;
  unit_weight?: string;
  made_in?: string;
  package_cardboard?: string;
  package_brand?: string;
  multi_pack_type?: string;
  multi_pack_cardboard?: string;
  multi_pack_brand?: string;
  pallet_type?: string;
  pallet_empty_dimensions?: string;
  multi_pack_dimensions_image?: string;
  pallet_dimensions_image?: string;
  pallet_nr_pcs?: string;
  ref_customer?: string;
  barcode?: string;
  ean?: string;
  multi_pack_lenght?: string;
  multi_pack_width?: string;
  multi_pack_height?: string;
  multi_pack_weight?: string;
  pallet_lenght?: string;
  pallet_width?: string;
  pallet_height?: string;
  pallet_nr_packages?: string;
  pallet_weight?: string;
  new_customer?: string;
}

export interface GetAdapterConfigurationReferenceInterface {
  __typename?: string;
  adapter_reference_id: string;
  reference?: string;
  unit_reference?: string;
  brand_classification: Array<string>;
  range_for_configuration: boolean;
  brand?: string;
  brand_image?: string;
  customer: Array<string>;
  designation?: string;
  adapter_image?: string;
  somfy_match?: string;
  motor_size: string;
  motor_type: Array<string>;
  rolling_tube: string;
  package_box?: string;
  package_lenght?: string;
  package_height?: string;
  package_width?: string;
  package_weight?: string;
  unit_weight?: string;
  package_image?: string;
  package_type?: string;
  applications?: string;
  packaging_quantity?: string;
  package_position?: string;
  is_draft: boolean;
  nr_of_adapters?: string;
  created_at?: Date;
  nominal_torque?: string;
  pallet_nr_pcs?: string;
  ref_customer?: string;
  barcode?: string;
  ean?: string;
  multi_pack_lenght?: string;
  multi_pack_width?: string;
  multi_pack_height?: string;
  multi_pack_weight?: string;
  pallet_lenght?: string;
  pallet_width?: string;
  pallet_height?: string;
  pallet_nr_packages?: string;
  pallet_weight?: string;
  package_cardboard?: string;
  package_brand?: string;
  multi_pack_type?: string;
  multi_pack_cardboard?: string;
  multi_pack_brand?: string;
  pallet_type?: string;
  pallet_empty_dimensions?: string;
  multi_pack_dimensions_image?: string;
  pallet_dimensions_image?: string;
  made_in?: string;
  new_customer?: string;
}

export type WithPackageItemQuantity<Type> = Type & {
  package_item_quantity?: string;
};

export interface GetMotorConfigurationReferenceInterface {
  __typename?: string;
  motor_reference_id: string;
  reference?: string;
  brand_classification: Array<string>;
  brand_classification_image?: string;
  brand?: string;
  brand_image: string;
  range_for_configuration: boolean;
  made_in: string;
  size_motor: string;
  technology: string;
  technology_image: string;
  type_motor: string;
  type_motor_code: string;
  somfy_match?: string;
  activation?: string;
  parallel_connection?: string;
  radio_compatibility?: string;
  customer?: Array<string>;
  new_customer_from_configuration?: string;
  designation_0?: string;
  designation?: string;
  designation_1?: string;
  complete_product_image?: string;
  motor_head?: string;
  motor_head_image?: string;
  plastic_cap_color?: string;
  plastic_cap_color_image?: string;
  is_logo_on_the_cap?: boolean;
  tube_color?: string;
  tube_hexdecimal_code?: string;
  plastic_cap_color_hex_code?: string;
  tube_color_image?: string;
  tube_material?: string;
  product_image?: string;
  type_lsu?: string;
  nr_of_end_limits?: string;
  adjustment_type?: string;
  obstacle_detection?: boolean;
  freez_detection?: boolean;
  power_supply_g?: string;
  nominal_voltage?: string;
  power_supply_tolerances?: string;
  voltage?: string;
  working_frequency?: string;
  thermal_time?: string;
  thermal_time_label?: string;
  cable_tipology?: string;
  cable_color_type?: string;
  cable_connector?: boolean;
  cable_connector_name?: string;
  cable_connector_image?: string;
  cable_type?: string;
  cable_type_image?: string;
  cable_number_of_wires?: number;
  cable_wire_section?: string;
  cable_lenght?: string;
  brake_type?: string;
  capacity_of_lsu?: string;
  repeatability?: string;
  system_of_protection?: string;
  temperature_working_range?: string;
  noise_level?: string;
  interface_drawing?: string;
  electromagnetic_compatibility_1?: string;
  electromagnetic_compatibility_2?: string;
  electromagnetic_compatibility_3?: string;
  electromagnetic_compatibility_4?: string;
  electromagnetic_compatibility_5?: string;
  electromagnetic_compatibility_6?: string;
  basic_crown_for_tube?: string;
  electrical_safety?: string;
  health_test?: string;
  nominal_torque?: string;
  nominal_speed?: string;
  torque_speed?: string;
  rated_power?: string;
  rated_current?: string;
  insulation_class?: string;
  l3_max?: string;
  dimension_image?: string;
  weight?: string;
  included_bracket_meta?: boolean;
  included_adapters_meta?: boolean;
  other_accessories_meta?: boolean;
  included_control_meta?: boolean;
  certification_ce: boolean;
  certification_vde: boolean;
  certification_nf: boolean;
  certification_cmim: boolean;
  certification_pai: boolean;
  certification_saber: boolean;
  motor_label_image?: string;
  motor_barcode_image?: string;
  ean_code?: string;
  vertical_screens?: boolean;
  vertical_screens_image?: string;
  zebra_screen?: boolean;
  zebra_screen_image?: string;
  rolling_shutters?: boolean;
  rolling_shutters_image?: string;
  leteral_arms_awnings?: boolean;
  leteral_arms_awnings_image?: string;
  awnings_with_arms?: boolean;
  awnings_with_arms_image?: string;
  cassette_awnings?: boolean;
  cassette_awnings_image?: string;
  instruction_leaflets_image?: string;
  instruction_logo?: string;
  instruction_languages: Array<string>;
  package_label_image?: string;
  packaging_nr_of_motors?: string;
  packaging_nr_of_brackets?: string;
  packaging_nr_of_adapters?: string;
  packaging_nr_of_controls?: string;
  packaging_nr_of_instruction_leaflets?: string;
  packaging_quantity?: string;
  packaging_graphic_layout_choice?: string;
  packaging_type?: string;
  packaging_print_finiture?: string;
  packaging_brand?: string;
  packaging_dimension_l?: string;
  packaging_dimension_w?: string;
  packaging_dimension_h?: string;
  packaging_dimension_image?: string;
  packaging_weight?: string;
  euro_pallet_nr_psc?: string;
  euro_pallet_image?: string;
  euro_pallet_nr_single_package?: string;
  euro_pallet_total_dimensions?: string;
  catalogue?: string;
  product_technical_sheet?: string;
  warning_and_instruction_leafsets?: string;
  ce_declaration?: string;
  vde_certification?: string;
  nf_certification?: string;
  compare_declaration?: string;
  photo_high_resolution?: string;
  photo_low_resolution?: string;
  dimensional_drawings?: string;
  video_installation?: string;
  forecast_annual_volumes?: string;
  is_draft: boolean;
  created_at: Date;
  end_limit_setting_tool?: string;
  transmission_frequency?: string;
  coding?: string;
  memory_capacity?: string;
  range?: string;
  multi_pack_type?: string;
  multi_pack_print_finiture?: string;
  multi_pack_brand?: string;
  pallet_type?: string;
  pallet_empty_dimensions?: string;
  multi_pack_dimensions_image?: string;
  pallet_dimensions_image?: string;
  multi_pack_weight?: string;
  pallet_number_of_packages?: string;
  pallet_number_pcs_layers?: string;
  pallet_weight?: string;
  multi_package_label_image?: string;
  multi_pack_dim_l?: string;
  multi_pack_dim_w?: string;
  multi_pack_dim_h?: string;
  motor_barcode?: string;
  pallet_dimensions_l?: string;
  pallet_dimensions_w?: string;
  pallet_dimensions_h?: string;
  adapters: Array<
    WithPackageItemQuantity<GetAdapterConfigurationReferenceInterface>
  >;
  other_accessories: Array<
    WithPackageItemQuantity<GetOtherAccessoryConfigurationReferenceInterface>
  >;
  brackets: Array<
    WithPackageItemQuantity<GetBracketConfigurationReferenceInterface>
  >;
  controls: Array<
    WithPackageItemQuantity<GetControlConfigurationReferenceInterface>
  >;
}

export interface GetConfigurationInterface {
  __typename?: string;
  configuration_id: string;
  brand_classification: string;
  group_entity?: string;
  customer_name?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  zip_code?: string;
  country?: string;
  phone?: string;
  website?: string;
  email?: string;
  brand_name?: string;
  customer_logo_and_graphic_elements?: string;
  clone_controls_reference_when_motors: boolean;
  motorReferencesList: Array<GetMotorConfigurationReferenceInterface>;
  adapterReferencesList: Array<GetAdapterConfigurationReferenceInterface>;
  otherAccessoryReferencesList: Array<GetOtherAccessoryConfigurationReferenceInterface>;
  controlReferencesList: Array<GetControlConfigurationReferenceInterface>;
  bracketReferencesList: Array<GetBracketConfigurationReferenceInterface>;
}

export interface ConfigurationQueryResultInterface {
  getConfiguration: GetConfigurationInterface;
}

export const useConfiguration = (configuration_id?: string) => {
  const [fetch, result] = useLazyQuery<ConfigurationQueryResultInterface>(
    GET_SINGLE_CONFIGURATION
  );

  useEffect(() => {
    if (configuration_id) {
      fetch({
        variables: {
          configuration_id,
        },
      });
    }
  }, [configuration_id]);

  return result;
};
