import { gql, useMutation } from '@apollo/client';

export const UPDATE_FILE_MOTORS_MUTATION = gql`
  mutation(
    $signed_document_uri: String
    $date_sign: DateTime
    $reference: String
    $motor_reference_id: String!
  ) {
    updateMotorReference(
      motorsReference: {
        motor_reference_id: $motor_reference_id
        date_sign: $date_sign
        signed_document_uri: $signed_document_uri
        reference: $reference
      }
    ) {
      signed_document_uri
      date_sign
    }
  }
`;

export interface UpdateFileMotorsResultInterface {
  updateMotorReference: {
    signed_document_uri?: string;
    date_sign?: Date;
  };
}

export const useUpdateFileMotors = (motor_reference_id: string) => {
  const [
    updateFileMotors,
    result,
  ] = useMutation<UpdateFileMotorsResultInterface>(
    UPDATE_FILE_MOTORS_MUTATION,
    {
      variables: {
        motor_reference_id,
      },
    }
  );
  return {
    updateFileMotors: (signed_document_uri: string) =>
      updateFileMotors({
        variables: { signed_document_uri, date_sign: new Date() },
      }),
    ...result,
  };
};

export interface MotorReferenceUpdateInterface {
  reference?: string;
}

export const useUpdateReferenceMotor = (motor_reference_id: string) => {
  const [updateMotor, result] = useMutation<UpdateFileMotorsResultInterface>(
    UPDATE_FILE_MOTORS_MUTATION,
    {
      variables: {
        motor_reference_id,
      },
    }
  );
  return {
    updateMotor: (motorData: MotorReferenceUpdateInterface) =>
      updateMotor({
        variables: motorData,
      }),
    ...result,
  };
};
