import React from 'react';
import './button.scss';
import { Icon } from '../icon/icon';
import { motion } from 'framer-motion';

export enum buttonType {
  left = 'left',
  right = 'right',
  icon = 'icon',
  clean = 'clean',
}

/* eslint-disable-next-line */
export interface ButtonPropsShared {
  label: string;
  disabled?: boolean;
  isSubmit?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  bgColor?: string;
  'data-cy'?: string;
}

export interface ButtonProps extends ButtonPropsShared {
  type: buttonType.clean;
}

export interface IconButtonProps extends ButtonPropsShared {
  type: buttonType.icon | buttonType.left | buttonType.right;
  iconName: (...args) => JSX.Element;
}

export function Button(props: ButtonProps | IconButtonProps) {
  const styleWhileHover = {
    backgroundColor: props.type !== 'left' ? '#8DD174' : '#72c355',
    opacity: props.type === 'left' || props.bgColor === '#000' ? 0.75 : 1,
  };

  return (
    <motion.button
      data-cy={props['data-cy']}
      className={`way-btn way-btn--${props.type}`}
      onClick={(e) =>
        props.disabled ? null : props.onClick ? props.onClick(e) : null
      }
      disabled={props.disabled}
      whileHover={props.disabled ? null : styleWhileHover}
      animate={true}
      transition={{ duration: 0.5 }}
      style={{ backgroundColor: props.bgColor }}
      type={props.isSubmit ? 'submit' : 'button'}
    >
      {props.type === 'icon' || props.type === 'left' ? (
        <Icon iconName={props.iconName} />
      ) : null}
      {props.label}
      {props.type === 'right' ? <Icon iconName={props.iconName} /> : null}
    </motion.button>
  );
}

export default Button;
