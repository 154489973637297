import { gql, useQuery } from '@apollo/client';
import { PaginateResultInterface } from '@eypconfig/shared/types';

export const PLASTIC_CAP_COLOR_SEARCH_BACKOFFICE = gql`
  query($cap_color_lable: String, $page: Int, $offset: Int) {
    result: searchPlasticCapsColor(
      cap_color_lable: $cap_color_lable
      page: $page
      offset: $offset
    ) {
      count
      page
      offset
      data {
        plastic_cap_id
        objectTypeId: plastic_cap_id
        cap_color_code
        cap_color_lable
        motor_size
        motor_head
      }
    }
  }
`;

export interface PlasticCapColorSearchItem {
  plastic_cap_id: string;
  objectTypeId: string;
  cap_color_code?: string;
  cap_color_lable?: string;
  motor_size?: Array<string>;
  motor_head?: Array<string>;
}

export interface SearchPlasticCapColorResult {
  result: PaginateResultInterface<PlasticCapColorSearchItem>;
}

export const useSearchPlasticCapColor = (search: {
  page?: string;
  offset?: string;
  textFilter?: string;
}) => {
  const result = useQuery<SearchPlasticCapColorResult>(
    PLASTIC_CAP_COLOR_SEARCH_BACKOFFICE,
    {
      variables: {
        page: Number(search.page),
        offset: Number(search.offset),
        cap_color_lable: search.textFilter,
      },
    }
  );
  return result;
};
