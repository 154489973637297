import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  AdaptersSearchFilter,
  PaginateResultInterface,
  PaginationArgs,
} from '@eypconfig/shared/types';

export const ADAPTERS_SEARCH_CATALOGUE_QUERY = gql`
  query(
    $page: Int
    $offset: Int
    $reference: [String!]
    $brand: [String!]
    $brand_classification: [String!]
    $customer: [String!]
    $somfy_match: [String!]
    $motor_type: [String!]
    $rolling_tube: [String!]
    $motor_size: [String!]
    $package_type: [String!]
    $range_for_configuration: Boolean
  ) {
    searchAdapters(
      page: $page
      offset: $offset
      reference: $reference
      brand: $brand
      brand_classification: $brand_classification
      customer: $customer
      somfy_match: $somfy_match
      package_type: $package_type
      motor_type: $motor_type
      rolling_tube: $rolling_tube
      motor_size: $motor_size
      range_for_configuration: $range_for_configuration
    ) {
      count
      offset
      data {
        __typename
        adapter_reference_id
        reference
        designation
        adapter_image
        rolling_tube
        somfy_match
        applications
        motor_type
        package_type
        motor_size
        three_d_video
        catalogue
        product_technical_sheet
        ce_declaration
        vde_certification
        nf_certification
        compare_declaration
        photo_high_resolution
        photo_low_resolution
        dimensional_drawings
        mini_programming_guide
        video_installation
        nominal_torque
        made_in
        package_brand
        nr_of_adapters
      }
    }
  }
`;

export const ADAPTERS_COUNT_QUERY = gql`
  query {
    searchAdapters {
      count
    }
  }
`;

export interface AdapterCatalogueInterface {
  __typename: 'AdapterReference';
  adapter_reference_id: string;
  reference: string;
  designation: string;
  adapter_image?: string;
  rolling_tube?: string;
  somfy_match?: string;
  applications?: string;
  motor_type?: Array<string>;
  package_type?: string;
  motor_size?: string;
  three_d_video?: string;
  catalogue?: string;
  product_technical_sheet?: string;
  ce_declaration?: string;
  vde_certification?: string;
  nf_certification?: string;
  compare_declaration?: string;
  photo_high_resolution?: string;
  photo_low_resolution?: string;
  dimensional_drawings?: string;
  mini_programming_guide?: string;
  video_installation?: string;
  nominal_torque?: string;
  made_in?: string;
  package_brand?: string;
  nr_of_adapters?: string;
}

export interface SearchAdaptersCatalogQueryResultInterface {
  searchAdapters: PaginateResultInterface<AdapterCatalogueInterface>;
}

export const useSearchAdaptersCatalogue = (
  filter?: AdaptersSearchFilter & PaginationArgs
) => {
  const data = useQuery<SearchAdaptersCatalogQueryResultInterface | null>(
    ADAPTERS_SEARCH_CATALOGUE_QUERY,
    {
      variables: filter,
    }
  );

  return data;
};

export const useLazySearchAdaptersCatalogue = (
  filter?: AdaptersSearchFilter & PaginationArgs
) => {
  const data = useLazyQuery<SearchAdaptersCatalogQueryResultInterface | null>(
    ADAPTERS_SEARCH_CATALOGUE_QUERY,
    {
      variables: filter,
    }
  );

  return data;
};

export interface CountAdaptersCatalogQueryResultInterface {
  searchAdapters: {
    count: number;
  };
}

export const useLazyCountAdapters = () => {
  const data = useLazyQuery<CountAdaptersCatalogQueryResultInterface | null>(
    ADAPTERS_COUNT_QUERY
  );

  return data;
};
