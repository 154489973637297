import { gql, useMutation } from '@apollo/client';
import { MotorReferenceDto } from '@eypconfig/shared/import-validations';

export const CREATE_MOTOR_REFERENCE_MUTATION = gql`
  mutation ($motorsReference: CreateMotorsReferenceSingleInput!) {
    createMotorReference(motorsReference: $motorsReference) {
      reference_id: motor_reference_id
    }
  }
`;

export function useCreateMotorReference() {
  const [createMotorReference, result] = useMutation(
    CREATE_MOTOR_REFERENCE_MUTATION
  );
  return {
    createMotorReference: (motorsReference: MotorReferenceDto) =>
      createMotorReference({ variables: { motorsReference } }),
    ...result,
  };
}
