import { gql, useMutation } from '@apollo/client';

export const ENABLE_CONTROL_MUTATION = gql`
  mutation($control_reference_id: String!) {
    enableControlReference(
      controlReference: { control_reference_id: $control_reference_id }
    ) {
      control_reference_id
    }
  }
`;

export const useEnableControlReference = (control_reference_id: string) => {
  const mutation = useMutation(ENABLE_CONTROL_MUTATION, {
    variables: { control_reference_id },
  });
  return mutation;
};
