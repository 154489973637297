import { gql, useQuery } from '@apollo/client';
import { PaginateResultInterface } from '@eypconfig/shared/types';

export const PACKAGE_SEARCH_BACKOFFICE = gql`
  query($packaging_print_finiture: String, $page: Int, $offset: Int) {
    result: searchPackage(
      packaging_print_finiture: $packaging_print_finiture
      page: $page
      offset: $offset
    ) {
      count
      page
      offset
      data {
        package_id
        objectTypeId: package_id
        packaging_print_finiture
        motor_size
        designation_1
        packaging_dimension_l
        packaging_dimension_w
        packaging_dimension_h
        product_classification
        packaging_type
      }
    }
  }
`;

export interface PackageColorSearchItem {
  package_id: string;
  objectTypeId: string;
  packaging_print_finiture?: string;
  designation_1?: string;
  packaging_dimension_l?: string;
  packaging_dimension_w?: string;
  packaging_dimension_h?: string;
  motor_size?: string;
  product_classification?: string;
  packaging_type?: Array<string>;
}

export interface SearchPackageColorResult {
  result: PaginateResultInterface<PackageColorSearchItem>;
}

export const useSearchPackage = (search: {
  page?: string;
  offset?: string;
  textFilter?: string;
}) => {
  const result = useQuery<SearchPackageColorResult>(PACKAGE_SEARCH_BACKOFFICE, {
    variables: {
      page: Number(search.page),
      offset: Number(search.offset),
      packaging_print_finiture: search.textFilter,
    },
  });
  return result;
};
