import { gql, useMutation } from '@apollo/client';
import { CableConnectorItem, CableLengthItem, CableTypeItem } from '../../get';

// ** SECTION CABLE CONNECTOR ***

export const CABLE_CONNECTOR_UPDATE_QUERY = gql`
  mutation($cableConnectorItem: UpdateCableConnectorDto!) {
    updateCableConnector(cableConnectorItem: $cableConnectorItem) {
      cable_connector_id
    }
  }
`;

export interface UpdateCableConnectorResponse {
  updateCableConnector: {
    cable_connector_id: string;
  };
}

export type UpdateCableConnectorItem = Partial<
  Omit<CableConnectorItem, 'cable_connector_id'>
> &
  Pick<CableConnectorItem, 'cable_connector_id'>;

export const useUpdateCableConnector = () => {
  const [
    mutate,
    { loading, error },
  ] = useMutation<UpdateCableConnectorResponse>(CABLE_CONNECTOR_UPDATE_QUERY);
  return {
    update: ({
      objectTypeId,
      created_at,
      updated_at,
      ...items
    }: UpdateCableConnectorItem) =>
      mutate({
        variables: {
          cableConnectorItem: {
            cable_connector_id: objectTypeId,
            ...items,
          },
        },
      }).then((result) => !result.errors),
    loading,
    error: error?.message,
  };
};

// ** SECTION CABLE LENGTH ***

export const CABLE_LENGTH_UPDATE_QUERY = gql`
  mutation($cableLengthItem: UpdateCableLengthDto!) {
    updateCableLength(cableLengthItem: $cableLengthItem) {
      cable_length_id
    }
  }
`;

export interface UpdateCableLengthResponse {
  updateCableLength: {
    cable_length_id: string;
  };
}

export type UpdateCableLength = Partial<
  Omit<CableLengthItem, 'cable_length_id'>
> &
  Pick<CableLengthItem, 'cable_length_id'>;

export const useUpdateCableLength = () => {
  const [mutate, { loading, error }] = useMutation<UpdateCableLengthResponse>(
    CABLE_LENGTH_UPDATE_QUERY
  );
  return {
    update: ({
      objectTypeId,
      created_at,
      updated_at,
      ...items
    }: UpdateCableLength) =>
      mutate({
        variables: {
          cableLengthItem: {
            cable_length_id: objectTypeId,
            ...items,
          },
        },
      }).then((result) => !result.errors),
    loading,
    error: error?.message,
  };
};

// ** SECTION CABLE TYPE ***

export const CABLE_TYPE_UPDATE_QUERY = gql`
  mutation($cableTypeItem: UpdateCableTypeDto!) {
    updateCableType(cableTypeItem: $cableTypeItem) {
      cable_type_id
    }
  }
`;

export interface UpdateCableTypeResponse {
  updateCableType: {
    cable_type_id: string;
  };
}

export type UpdateCableType = Partial<Omit<CableTypeItem, 'cable_type_id'>> &
  Pick<CableTypeItem, 'cable_type_id'>;

export const useUpdateCableType = () => {
  const [mutate, { loading, error }] = useMutation<UpdateCableTypeResponse>(
    CABLE_TYPE_UPDATE_QUERY
  );
  return {
    update: ({
      objectTypeId,
      created_at,
      updated_at,
      ...items
    }: UpdateCableType) =>
      mutate({
        variables: {
          cableTypeItem: {
            cable_type_id: objectTypeId,
            ...items,
          },
        },
      }).then((result) => !result.errors),
    loading,
    error: error?.message,
  };
};
