import { useQuery, gql } from '@apollo/client';
import { SelectType } from '@eypconfig/shared/types';

export const BRACKETS_MOTOR_HEAD_QUERY = gql`
  query($brand_classification: String, $size_motor: String) {
    getMotorsSelectOptions(
      propertyToUnique: "motor_head"
      brand_classification: $brand_classification
      lableTemplate: "{{ motor_head }}---{{ motor_head_image }}"
      size_motor: $size_motor
    ) {
      data {
        label
        value
      }
    }
  }
`;

export interface BracketsMotorHeadOptions {
  getMotorsSelectOptions: {
    data: Array<SelectType>;
  };
}

export const useBracketsHeadsMotor = (
  brand_classification: string,
  size_motor?: string
) => {
  const status = useQuery<BracketsMotorHeadOptions>(BRACKETS_MOTOR_HEAD_QUERY, {
    variables: {
      brand_classification: brand_classification
        .split('_')
        .join(' ')
        .toUpperCase(),
      size_motor: size_motor,
    },
  });

  return status;
};
