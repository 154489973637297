import { gql, useMutation } from '@apollo/client';

export const CREATE_CUSTOMER_QUERY = gql`
  mutation($name: String!) {
    createCustomer(name: $name) {
      customer_id
    }
  }
`;

export interface CreateCustomerResult {
  createCustomer: {
    customer_id: string;
  };
}

export const useCreateCustomer = () => {
  const [create, { loading, error }] = useMutation<CreateCustomerResult>(
    CREATE_CUSTOMER_QUERY
  );
  return {
    create: (name: string) =>
      create({ variables: { name } }).then((result) => {
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
        return result.data?.createCustomer.customer_id;
      }),
    error: error?.message,
    loading,
  };
};
